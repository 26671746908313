import React from 'react';
import {Link} from "react-router-dom";

function Menu() {
    return <>
        <ul className="navbar-nav navbar-nav-scroll me-auto"
            style={{'--ar-scroll-height': '520px'}}
            data-bs-dismiss="offcanvas" data-bs-target="#sidebarLeft"
        >
            <li className="nav-item">
                <Link to="/" className="nav-link">Главная</Link>
            </li>
            <li className="nav-item">
                <Link to="/news" className="nav-link">Новости</Link>
            </li>
            <li className="nav-item">
                <Link to="/ports" className="nav-link">Порты</Link>
            </li>
            <li className="nav-item">
                <Link to="/contacts" className="nav-link">Контакты</Link>
            </li>
            <li className="nav-item">
                <Link to="/statistics" className="nav-link">Маркетинг</Link>
            </li>
        </ul>
    </>
}

export default React.memo(Menu);