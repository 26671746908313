import React, {useEffect} from 'react';
import {Pagination, Swiper} from "swiper";
import {useGraphQL} from "../../../hook/useGraphQL";
import {useQuery} from "@tanstack/react-query";
import {GET_INVESTMENTS} from "../../../graphql/Investment/GET_INVESTMENTS";
import {GET_MY_INVESTMENTS} from "../../../graphql/MyInvestment/GET_MY_INVESTMENTS";
import useUserId from "../../../hook/useUserId";
import Investment from "./Investment";
import InvestmentCalc from "./InvestmentCalc";


const SELECTOR = 'containers-swiper'


const Investments = () => {

    const graphQLClient = useGraphQL()
    const userId = useUserId(true)

    const {data: investments} = useQuery({
        queryKey: ['investments'],
        queryFn: () => graphQLClient.request(GET_INVESTMENTS).then(res => res?.investments)
    })


    const {data: myInvestments} = useQuery({
        queryKey: ['myInvestments', {user: userId}],
        queryFn: () => graphQLClient.request(GET_MY_INVESTMENTS, {
            user: userId
        }).then(res => res?.myInvestments)
    })


    useEffect(() => {

        Swiper.use([Pagination])

        new Swiper(`#${SELECTOR}`, {
            "spaceBetween": 24,
            "pagination": {
                "el": ".swiper-pagination",
                "clickable": true
            },
            "breakpoints": {
                "576": {"slidesPerView": 2},
                "992": {"slidesPerView": 3}
            }
        })

    }, [])


    return <>

        <h2 className="h1 pt-xxl-1 pb-4 mb-2 mb-lg-3">Инвестиции</h2>

        <div id={SELECTOR} className="swiper">
            <div className="swiper-wrapper">
                {
                    investments?.data?.map(investment => <Investment
                        key={investment?.id}
                        investment={investment}
                        myInvestments={myInvestments}
                    />)
                }
            </div>

            <div className="swiper-pagination position-relative bottom-0 mt-2 pt-4"/>

        </div>


        <div className="mt-5">
            <InvestmentCalc/>
        </div>

    </>
}

export default React.memo(Investments);