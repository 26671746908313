import React from 'react';
import '../../../style/PageLoader.css'

const PageLoader = () => {
    return (
        <div className="page-loading active">
            <div className="page-loading-inner">
                <div className="page-spinner"></div>
                <span>Загрузка...</span>
            </div>
        </div>
    )
}

export default React.memo(PageLoader);