import React from 'react';
import SidebarRightBody from "./SidebarRightBody";

const SidebarRightOffcanvas = () => {


    return <>
        <div className="offcanvas offcanvas-end" id="sidebarRight">

            <button className="btn-close position-absolute top-0 end-0 mt-3 me-3" type="button"
                    data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight"/>

            <div className="offcanvas-body">

                <SidebarRightBody/>

            </div>

        </div>
    </>
}

export default React.memo(SidebarRightOffcanvas);