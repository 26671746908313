import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import AuthProvider from "./provider/AuthProvider";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import GraphQLProvider from "./provider/GraphQLProvider";
import App from './App';
import AxiosDefaults from "./hoc/AxiosDefaults";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'offlineFirst',
            refetchOnWindowFocus: false,
            // refetchOnReconnect: false,
            // refetchOnMount: false,
            retry: 1,
            staleTime: 10000
        },
        mutations: {
            networkMode: 'offlineFirst',
        },
    },
})


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <AxiosDefaults/>
                <GraphQLProvider>
                    <App/>
                </GraphQLProvider>
            </AuthProvider>
        </QueryClientProvider>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
