import React from 'react';

const Vacancies = () => {
    return <>
        <section className="container pt-5 mt-5 mb-md-2 mb-lg-3 mb-xl-4">
            <h2 className="text-center pb-2 pb-lg-3">
                Открыт набор менеджеров по продажам
            </h2>
        </section>

        <section className="container pt-2 mt-md-2 mt-lg-3 mt-xl-4">
            <div className="row justify-content-center pt-xxl-2">
                <div className="col-lg-9 col-xl-8">

                    <h4>Обязанности:</h4>

                    <ul>
                        <li>Поиск потенциальных клиентов в сети интернет</li>
                        <li>Активное взаимодействие с клиентами до и во время работы (более 70% клиентов делают повторные покупки)</li>
                        <li>Работа с онлайн-заявками и обратной связью от клиентов</li>
                        <li>Предоставление консультаций и расчетов по продуктам компании</li>
                    </ul>


                    <h4>Условия:</h4>

                    <ul>
                        <li>Фиксированная заработная плата: 40 000 руб. + 15% от
                            всех продаж
                        </li>
                        <li>Возможность перехода на процентную ставку в 25%
                            спустя 2 месяца работы
                        </li>
                        <li>График с 9:00 до 17:00, Пн - Пт</li>
                        <li>Трудоустройство по официальному договору, можно на 1
                            месяц
                        </li>
                        <li>Отправляйте ваши заявки и резюме на почту
                            coscolinecorporation@gmail.com
                        </li>
                    </ul>

                </div>

            </div>
        </section>
    </>
}

export default React.memo(Vacancies);