
export const transactionStatusBadge = status => {

    const {id, attributes} = status?.data || {}
    const {name} = attributes || {}

    const statusBadges = {
        1: <span className="badge bg-faded-success text-success fs-xs">{name}</span>,
        2: <span className="badge bg-faded-danger text-danger fs-xs">{name}</span>,
        3: <span className="badge bg-faded-warning text-warning fs-xs">{name}</span>,
    }

    return statusBadges[id]

}

