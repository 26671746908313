import React, {useEffect} from 'react';
import {jarallax} from "jarallax"


const Hero = () => {

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'), {})
    }, [])


    return (

        <section
            className="jarallax dark-mode bg-dark py-5"
            data-jarallax=""
            data-speed="0.6"
        >
            <div className="position-absolute top-0 start-0 w-100 h-100 bg-black opacity-60"/>
            <div
                className="jarallax-img"
                style={{backgroundImage: "url(/img/main.jpg)"}}
            />
            <div className="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-3 pb-xl-4 pb-xxl-5">
                {/* Page title*/}
                <div className="d-none d-xxl-block" style={{height: 170}}/>
                <div className="d-none d-lg-block d-xxl-none" style={{height: 120}}/>
                <div className="d-none d-md-block d-lg-none" style={{height: 80}}/>
                <div className="d-md-none" style={{height: 40}}/>
                <h1 className="display-2 mb-4">COSCOLINE</h1>
                <p className="h3" style={{maxWidth: 480}}>
                    China Ocean Shipping (Group) Company
                </p>
                <p className="h6 mb-0" style={{maxWidth: 480}}>
                    Headquarters: Beijing, China
                </p>
                {/* Features*/}
                <div className="d-none d-xxl-block" style={{height: 220}}/>
                <div className="d-none d-lg-block d-xxl-none" style={{height: 160}}/>
                <div className="d-none d-md-block d-lg-none" style={{height: 100}}/>
                <div className="d-md-none" style={{height: 50}}/>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 pb-1 pb-md-3">
                    <div className="col">
                        <div className="d-flex align-items-center">
                            <div className="position-rlative flex-shrink-0 rounded-1 overflow-hidden p-2">
                                <div className="bg-white opacity-40 position-absolute top-0 start-0 w-100 h-100"/>
                                <img
                                    className="d-block m-1"
                                    src="/img/services/Hero/time-white.svg"
                                    width={32}
                                    alt="Icon"
                                />
                            </div>
                            <h3 className="h5 ps-3 mb-0">Индивидуальный подход</h3>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex align-items-center">
                            <div className="position-rlative flex-shrink-0 rounded-1 overflow-hidden p-2">
                                <div className="bg-white opacity-40 position-absolute top-0 start-0 w-100 h-100"/>
                                <img
                                    className="d-block m-1"
                                    src="/img/services/Hero/cog-white.svg"
                                    width={32}
                                    alt="Icon"
                                />
                            </div>
                            <h3 className="h5 ps-3 mb-0">Интегрированная аналитика</h3>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex align-items-center">
                            <div className="position-rlative flex-shrink-0 rounded-1 overflow-hidden p-2">
                                <div className="bg-white opacity-40 position-absolute top-0 start-0 w-100 h-100"/>
                                <img
                                    className="d-block m-1"
                                    src="/img/services/Hero/monitor-white.svg"
                                    width={32}
                                    alt="Icon"
                                />
                            </div>
                            <h3 className="h5 ps-3 mb-0">Поэтапная работа</h3>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex align-items-center">
                            <div className="position-rlative flex-shrink-0 rounded-1 overflow-hidden p-2">
                                <div className="bg-white opacity-40 position-absolute top-0 start-0 w-100 h-100"/>
                                <img
                                    className="d-block m-1"
                                    src="/img/services/Hero/size-white.svg"
                                    width={32}
                                    alt="Icon"
                                />
                            </div>
                            <h3 className="h5 ps-3 mb-0">Полный спектр услуг</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        // <section className="overflow-hidden">
        //     <div className="container pt-2 pt-sm-4 pb-sm-2 pb-md-4 py-xl-5 mt-5">
        //         <div className="row py-5 mt-md-2 my-lg-3 my-xl-4 my-xxl-5">
        //             <div
        //                 className="col-lg-7 order-lg-2 d-flex justify-content-center justify-content-lg-end mb-4 mb-md-5 mb-lg-0 pb-3 pb-md-0">
        //                 <div className="parallax me-lg-n4 me-xl-n5" style={{maxWidth: 667}}>
        //                     <div className="parallax-layer" data-depth="0.1">
        //                         <img src={img_hero1} alt="Layer"/>
        //                     </div>
        //                     {/*<div className="parallax-layer" data-depth="-0.2">*/}
        //                     {/*    <img src={img_hero2} alt="Layer"/>*/}
        //                     {/*</div>*/}
        //                     {/*<div className="parallax-layer" data-depth="0.25">*/}
        //                     {/*    <img src={img_hero3} alt="Layer"/>*/}
        //                     {/*</div>*/}
        //                 </div>
        //             </div>
        //             <div className="col-lg-5 order-lg-1">
        //                 <h3 className="display-3 text-center text-lg-start pb-sm-2 pb-md-3">
        //                     COSCOLINE
        //                     <div className="h1">China Ocean Shipping (Group) Company</div>
        //                 </h3>
        //                 <p className="fs-lg text-center text-lg-start pb-xl-2 mx-auto mx-lg-0 mb-5"
        //                    style={{maxWidth: 420}}>
        //                     Headquarters: Beijing, China
        //                 </p>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}

export default React.memo(Hero);