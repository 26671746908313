import React, {useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {useGraphQL} from "../../hook/useGraphQL";
import {GET_NEWS} from "../../graphql/New/GET_NEWS";
import New from "./New";
import Pagination from "../Elements/Pagination";
import DataLoader from "../Elements/Loaders/DataLoader";



const News = () => {

    const graphQLClient = useGraphQL()

    const [page, setPage] = useState(1)


    const {data: news, isLoading} = useQuery({
        queryKey: ['news', page],
        queryFn: () => graphQLClient.request(GET_NEWS, {page}).then(res => res?.news)
    })





    return <>
        <div className="container pt-5 pb-lg-5 pb-md-4 pb-2 my-5">
            <div className="row align-items-center gy-2 mb-4 pb-1 pb-sm-2 pb-lg-3">
                <div className="col-lg-5">
                    <h1 className="mb-lg-0">Актуальные новости</h1>
                </div>
            </div>

            {
                isLoading &&
                <DataLoader/>
            }

            {
                news?.data?.map(item => <New item={item} key={item?.id}/>)
            }

            <Pagination
                page={page}
                setPage={setPage}
                pageCount={news?.meta?.pagination?.pageCount}
            />

        </div>
    </>
}

export default React.memo(News);