import React from 'react';
import '../../../style/PageLoader.css'
import Spinner from "./Spinner";

const DataLoader = () => {
    return (
        <div className="d-flex align-items-center justify-content-center p-5">
            <Spinner size="4rem"/>
        </div>
    )
}

export default React.memo(DataLoader);