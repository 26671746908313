import React from 'react';
import ModalBuyInvestment from "./ModalBuyInvestment";
import {BACKEND_URL} from "../../../config";


const Investment = ({investment, myInvestments, hideBuyBtn}) => {

    const {
        name,
        price,
        dailyProfit,
        fee,
        discount,
        containerPurchase,
        unavailable
    } = investment?.attributes || {}

    const imgUrl = BACKEND_URL + investment?.attributes?.img?.data?.attributes?.url


    const myInvestment = myInvestments?.data?.find(myInvestment => myInvestment?.attributes?.investment?.data?.id === investment?.id)




    return (
        <div className="swiper-slide h-auto" key={investment?.id}>
            <div className="card h-100 border-0 rounded-4 pb-3">
                <div className="position-relative ignore-dark-mode">
                    <div className="item-responsive item-4by3">
                        <img
                            className="rounded-4 rounded-bottom-0 content w-100 h-100"
                            src={imgUrl} alt=""
                        />
                    </div>

                    <div className="card-body position-absolute start-0 bottom-0 py-4">
                        <div className="d-table badge bg-light text-nav rounded-pill fs-sm">
                            Ежедневный доход <b>{dailyProfit}%</b>
                        </div>
                    </div>

                </div>

                <div className="card-body pb-4">
                    <div className="text-primary fw-bold pb-2 mb-1 fs-5">${price}</div>
                    <h3 className="h4">{name}</h3>
                    <ul className="fs-sm ps-4 mb-0">
                        <li className="mb-2">
                            Комиссия при выводе: <b>{fee}%</b>
                        </li>
                        <li className="mb-2">
                            Возможность купить контейнер: <b>{containerPurchase ? 'Да' : 'Нет'}</b>
                        </li>
                        <li className="mb-2">
                            Скидка при покупке контейнера: <b>{discount}%</b>
                        </li>
                    </ul>
                </div>
                {
                    !hideBuyBtn &&
                    <div className="card-footer border-0 pt-0">
                        <ModalBuyInvestment
                            button={
                                !!myInvestment ?
                                    <button className="btn btn-outline-success w-100" type="button"
                                            disabled>
                                        Куплено <i className="ai-check-alt ms-1"/>
                                    </button> : (
                                        unavailable ?
                                            <button className="btn btn-success w-100 disabled" type="button">
                                                Временно недоступно
                                            </button> :
                                            <button className="btn btn-success w-100" type="button">
                                                Купить
                                            </button>
                                    )
                            }
                            investment={investment}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default React.memo(Investment);