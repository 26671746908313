import React from 'react';
import {Link} from "react-router-dom";
import Logo from "../Elements/Logo";
import {useQuery} from "@tanstack/react-query";
import {GET_DEPOSIT_TYPES} from "../../graphql/DepositType/GET_DEPOSIT_TYPES";
import {useGraphQL} from "../../hook/useGraphQL";
import {BACKEND_URL} from "../../config";

const Footer = () => {

    const graphQLClient = useGraphQL()

    const {data} = useQuery({
        queryKey: ['depositTypes'],
        queryFn: () => graphQLClient.request(GET_DEPOSIT_TYPES)
    })


    return (
        <footer className="footer py-5">
            <div className="container pt-md-2 pt-lg-3 pt-xl-4">
                <div className="row pb-5 pt-sm-2 mb-lg-2">
                    <div className="col-md-12 col-lg-3 pb-2 pb-lg-0 mb-4 mb-lg-0">
                        <span className="py-0 mb-3 mb-lg-4">
                            <Logo/>
                        </span>
                    </div>
                    <div className="col-sm-3 col-lg-2 offset-xl-1 mb-4 mb-sm-0">
                        <ul className="nav flex-column">
                            <li>
                                <Link
                                    to="/about-company"
                                    className="nav-link py-1 px-0"
                                >
                                    О компании
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/vacancies"
                                    className="nav-link py-1 px-0"
                                >
                                    Вакансии
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-4 col-lg-2 mb-4 mb-sm-0">
                        <ul className="nav flex-column">
                            <li>
                                <Link
                                    to="/privacy-policy"
                                    className="nav-link py-1 px-0"
                                >
                                    Пользовательское соглашение
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-5 col-lg-4 col-xl-3 offset-lg-1">
                        <h3 className="h6 mb-2">coscolinecorporation@gmail.com</h3>

                    </div>
                </div>
                <div className="d-flex justify-content-between flex-wrap gap-3">
                    <p className="fs-sm mb-0">
                        <span className="opacity-70">© Все права защищены, 2023</span>
                    </p>
                    <div className="d-flex align-items-center gap-3">
                        {
                            data?.depositTypes?.data?.map(item => {
                                return <React.Fragment key={item?.id}>
                                    <img
                                        src={BACKEND_URL + item?.attributes?.logoLight?.data?.attributes?.url}
                                        style={{maxHeight: "30px", maxWidth: "60px", objectFit: "contain"}}
                                        alt={item?.attributes?.name}
                                        className="d-dark-mode-none"
                                    />
                                    <img
                                        src={BACKEND_URL + item?.attributes?.logoDark?.data?.attributes?.url}
                                        style={{maxHeight: "30px", maxWidth: "60px", objectFit: "contain"}}
                                        alt={item?.attributes?.name}
                                        className="d-none d-dark-mode-inline"
                                    />
                                </React.Fragment>
                            })
                        }
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default React.memo(Footer);