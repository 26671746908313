import React, {Suspense} from 'react';
import {Outlet} from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import BackToTop from "./Elements/BackToTop";
import ScrollToTop from "../utilities/ScrollToTop";
import SidebarLeft from "./Elements/SidebarLeft";
import SidebarRightOffcanvas from "./Cabinet/SidebarRight/SidebarRightOffcanvas";
import {useAuth} from "../hook/useAuth";
import ContentLoader from "./Elements/Loaders/ContentLoader";

const Wrapper = () => {

    const {user} = useAuth()

    return <>
        <main className="page-wrapper min-vh-100">

            <Header/>

            {
                user &&
                // <div className="d-lg-none">
                    <SidebarRightOffcanvas/>
                // </div>
            }

            <SidebarLeft/>

            <Suspense fallback={<ContentLoader/>}>
                <Outlet/>
            </Suspense>


        </main>

        {/* Divider for dark mode only*/}
        <hr className="d-none d-dark-mode-block"/>

        <Footer/>

        <BackToTop/>

        <ScrollToTop/>

    </>
}

export default React.memo(Wrapper);