import React from 'react';
import Spinner from "../../Elements/Loaders/Spinner";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {math} from "../../../utilities/math";
import useUserId from "../../../hook/useUserId";

const StatIncome = () => {

    const userId = useUserId(true)


    const {data: income, isLoading} = useQuery({
        queryKey: ['transactionSum', 'income', userId],
        queryFn: () =>
            axios.post('/transaction/sum', {
                user: userId,
                type: 1,
                status: 1
            }).then(res => math.round(res?.data, 2))
    })



    return (
        <div className="d-flex flex-wrap">
            <span className="me-2">Пополнения</span>
            {
                isLoading ?
                    <Spinner size="1rem"/> :
                    <span className="h5">{income} $</span>
            }
        </div>
    )
}

export default React.memo(StatIncome);