import React, {useState} from 'react';
import {BACKEND_URL} from "../../config";
import moment from "moment/moment";
import "moment/locale/ru"

const New = ({item}) => {

    const SYMBOLS = 300

    const [showFull, setShowFull] = useState(false)

    const {name, text, img, datetime, tag} = item?.attributes || {}
    const imgUrl = BACKEND_URL + img?.data?.attributes?.formats?.small?.url

    let sliced = text.slice(0, SYMBOLS)
    if (sliced.length < text.length) {
        sliced += '...'
    }

    return (
        <article className="row g-0 border-0 mb-5">
            <span
                className="col-sm-5 col-lg-4 bg-repeat-0 bg-size-cover bg-position-center rounded-5"
                style={{
                    backgroundImage: `url(${imgUrl})`,
                    minHeight: "16rem",
                    maxHeight: "256px"
                }}
            />
            <div className="col-sm-7 col-lg-8">
                <div className="pt-4 pt-sm-0 pb-sm-4 ps-sm-4 pe-lg-4">
                    <h3>
                        {name}
                    </h3>
                    <p className="d-md-block text-justify">
                        {showFull ? text : sliced}
                        {
                            sliced.length < text.length && (
                                showFull ?
                                    <span
                                        className="a ms-2 text-decoration-underline"
                                        onClick={() => setShowFull(false)}
                                    >
                                    Свернуть
                                </span> :
                                    <span
                                        className="a ms-2 text-decoration-underline"
                                        onClick={() => setShowFull(true)}
                                    >
                                    Развернуть
                                </span>
                            )

                        }
                    </p>
                    <div className="d-flex flex-wrap align-items-center mt-n2">
                        <span className="fs-sm text-muted mt-2">
                            {moment(datetime).format('LLL')}
                        </span>
                        <span className="fs-xs opacity-20 mt-2 mx-3">|</span>
                        <span className="badge text-nav fs-xs border mt-2">
                            {tag}
                        </span>
                    </div>
                </div>
            </div>
        </article>
    )
}

export default React.memo(New);