import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import Spinner from "../../Elements/Loaders/Spinner";
import useUserId from "../../../hook/useUserId";
import SelectMyContainerStatus from "../../Input/Select/SelectMyContainerStatus";


const ModalEditContainer = ({myContainer}) => {

    const queryClient = useQueryClient()
    const userId = useUserId(true)

    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState(false)

    const [myContainerStatus, setMyContainerStatus] = useState(myContainer?.attributes?.status?.data?.id)



    const {mutate, isLoading, isSuccess, reset, isError, error: serverError} = useMutation({
        mutationFn: variables => axios.put(`my-containers/${myContainer?.id}`, variables),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['myContainers', {user: userId}]})
        }
    })


    const updateMyContainer = () => {

        mutate({
            data: {
                status: myContainerStatus?.value
            }
        })

    }


    const handleClose = () => {
        reset()
        setError('')
        setShowModal(false)
    }

    const handleShow = () => setShowModal(true)




    return (

        <>

            <i className="ai-edit ms-2 a" onClick={handleShow}/>


            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Редактирование контейнера</Modal.Title>
                </Modal.Header>


                <Modal.Body>

                    {
                        isSuccess &&
                        <>
                            <h3 className="text-center">
                                Сохранено!
                            </h3>
                        </>
                    }

                    {
                        !isSuccess && <>

                            <SelectMyContainerStatus
                                value={myContainerStatus}
                                onChange={setMyContainerStatus}
                            />


                            {
                                error &&
                                <div className="alert d-flex alert-warning mt-3 mb-0" role="alert">
                                    <i className="ai-triangle-alert fs-xl pe-1 me-2"/>
                                    <div>{error}</div>
                                </div>
                            }


                            {
                                isError && serverError?.response?.data?.error?.message &&
                                <div className="alert d-flex alert-danger mt-3 mb-0" role="alert">
                                    <i className="ai-triangle-alert fs-xl pe-1 me-2"/>
                                    <div>
                                        {serverError?.response?.data?.error?.message}
                                    </div>
                                </div>
                            }

                        </>

                    }


                </Modal.Body>


                <Modal.Footer>
                    {
                        !isSuccess ?
                            <Button variant="primary" className="w-100" onClick={updateMyContainer}>
                                {isLoading && <Spinner className="me-2"/>}
                                Сохранить
                            </Button> :
                            <Button variant="primary" className="w-100" onClick={handleClose}>
                                Закрыть
                            </Button>
                    }
                </Modal.Footer>

            </Modal>
        </>


    )
}


export default React.memo(ModalEditContainer)
