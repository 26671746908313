import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import Spinner from "../../../Elements/Loaders/Spinner";
import SelectDepositType from "../../../Input/Select/SelectDepositType";
import SelectTransactionType from "../../../Input/Select/SelectTransactionType";
import SelectTransactionStatus from "../../../Input/Select/SelectTransactionStatus";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru"
import {math} from "../../../../utilities/math";
import moment from "moment";
import useUserId from "../../../../hook/useUserId";


const ModalAddTransaction = () => {

    const queryClient = useQueryClient()

    const userId = useUserId(true)

    const [showModal, setShowModal] = useState(false)
    const [error, setError] = useState('')

    const [type, setType] = useState(null)
    const [depositType, setDepositType] = useState(null)
    // const [withdrawalType, setWithdrawalType] = useState(null)
    const [status, setStatus] = useState(null)
    const [amount, setAmount] = useState()
    const [datetime, setDatetime] = useState()
    const [comment, setComment] = useState()

    useEffect(() => {
        if (!type) return
        if (type?.value === '1') {
            setComment('Пополнение баланса')
        }
        else if (type?.value === '2') {
            setComment('Вывод на реквизиты клиента')
        }
        else if (type?.value === '4') {
            setComment('Начисление прибыли за контейнер')
        }
        else if (type?.value === '5') {
            setComment('Начисление прибыли за инвестиции')
        }
        else if (type?.value === '6') {
            setComment('Реферальные начисления')
        }
        else if (type?.value === '8') {
            setComment('Покупка контейнера')
        }
        else if (type?.value === '9') {
            setComment('Покупка пакета инвестиций')
        }
        else {
            setComment('')
        }
    }, [type])


    useEffect(() => {
        setError('')
    }, [type, depositType/*, withdrawalType*/, status, amount, datetime, comment])


    useEffect(() => {
        setDepositType(null)
        // setWithdrawalType(null)
    }, [type])






    const {mutate, isLoading, reset} = useMutation({
        mutationFn: variables => axios.post('transactions', variables),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['transactions']})
            queryClient.invalidateQueries({queryKey: ['transactionSum']})
        }
    })


    const addTransaction = () => {

        if (!userId) {
            setError('Ошибка! Неизвестый ID клиента')
            return
        }

        if (!type) {
            setError('Выберите тип транзакции')
            return
        }

        if (['1'].includes(type?.value) && !depositType) {
            setError('Выберите платежный метод')
            return
        }

        // if (['2'].includes(type?.value) && !withdrawalType) {
        //     setError('Выберите платежный метод')
        //     return
        // }

        if (!status) {
            setError('Выберите статус транзакции')
            return
        }

        if (!amount) {
            setError('Укажите сумму')
            return
        }

        const parsedAmount = parseFloat(amount)


        if (isNaN(parsedAmount)) {
            setError('Укажите корректную сумму')
            return
        }

        if (parsedAmount <= 0) {
            setError('Сумма должна быть больше нуля')
            return
        }


        const amountSign = type?.sign || 1

        const data = {
            type: type?.value,
            status: status?.value,
            // withdrawalType: withdrawalType?.value,
            user: userId,
            comment,
            amount: math.floor(parsedAmount * amountSign, 2),
            datetime,
            depositType: depositType?.value,
            depositName: depositType?.label,
            depositCurrency: depositType?.currency,
            // withdrawalName: withdrawalType?.label,
            // withdrawalCurrency: withdrawalType?.currency
        }


        mutate({data}, {
            onSuccess: (data) => {
                handleClose()
            }
        })

    }


    const handleClose = () => {
        setType(null)
        setDepositType(null)
        setStatus(null)
        setDatetime()
        setComment()
        setAmount('')
        reset()
        setError('')
        setShowModal(false)
    }

    const handleShow = () => setShowModal(true)


    return (

        <>
            <button className="btn btn-sm btn-outline-success" onClick={handleShow}>Добавить транзакцию</button>


            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Добавить транзакцию</Modal.Title>
                </Modal.Header>

                <Modal.Body>


                    <SelectTransactionType
                        className="mb-3"
                        value={type}
                        onChange={setType}
                    />

                    {
                        ['1'].includes(type?.value) &&
                        <SelectDepositType
                            className="mb-3"
                            value={depositType}
                            onChange={setDepositType}
                        />
                    }


                    {/*{*/}
                    {/*    ['2'].includes(type?.value) &&*/}
                    {/*    <SelectWithdrawalType*/}
                    {/*        className="mb-3"*/}
                    {/*        value={withdrawalType}*/}
                    {/*        onChange={setWithdrawalType}*/}
                    {/*    />*/}
                    {/*}*/}

                    <SelectTransactionStatus
                        className="mb-3"
                        value={status}
                        onChange={setStatus}
                    />


                    <input
                        type="tel"
                        className="form-control mb-3"
                        placeholder="Сумма, $"
                        value={amount || ''}
                        onChange={e => setAmount(e.target.value)}
                    />


                    <Flatpickr
                        options={{
                            locale: Russian,
                            disableMobile: true,
                            enableTime: true,
                        }}
                        placeholder={moment().format("DD.MM.YYYY HH:mm")}
                        className="form-control date-picker mb-3"
                        value={datetime}
                        onChange={(dObj) => {
                            setDatetime(dObj[0])
                        }}
                    />




                    <textarea
                        className="form-control"
                        placeholder="Комментарий"
                        rows={3}
                        value={comment || ''}
                        onChange={e => setComment(e.target.value)}
                    />



                    {
                        error &&
                        <div className="alert d-flex alert-warning mt-3 mb-0" role="alert">
                            <i className="ai-triangle-alert fs-xl pe-1 me-2"/>
                            <div>{error}</div>
                        </div>
                    }

                    {
                        !userId &&
                        <div className="alert d-flex alert-danger mt-3 mb-0" role="alert">
                            <i className="ai-circle-slash fs-xl pe-1 me-2"/>
                            <div>Неизвестный пользователь</div>
                        </div>
                    }


                </Modal.Body>


                <Modal.Footer>
                    <Button variant="primary" onClick={addTransaction}>
                        {isLoading && <Spinner/>}
                        Добавить
                    </Button>
                </Modal.Footer>

            </Modal>
        </>


    )
}


export default React.memo(ModalAddTransaction)
