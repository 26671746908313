import {gql} from "graphql-request";

export const GET_MY_CONTAINER_STATUSES = gql`

    query GetMyContainerStatuses(

        $sort: [String] = "name:asc"
        $page: Int = 1
        $pageSize: Int = 100

    ) {
        myContainerStatuses(
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`