import {gql} from "graphql-request";

export const GET_COINS = gql`

    query GetCoins(
        
        $user: ID
        $type: ID
        $sort: [String] = "id:desc"
        $page: Int = 1
        $pageSize: Int = 10
        
    ) {
        coinTransactions(
            
            filters: {
                user: {id: {eq: $user}}
                type: {id: {eq: $type}}
            }
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    createdAt
                }
            }
        }
    }
`