import React from 'react';
import useBalance from '../../../../hook/useBalance';
import Spinner from '../../../Elements/Loaders/Spinner';

function UserBalance({userId}) {

    const {balance, isLoading} = useBalance(userId)

    if (isLoading) return <Spinner/>

    return balance
}

export default UserBalance;