import React, {useState} from 'react';
import {useAuth} from "../../../hook/useAuth";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {useQuery, useInfiniteQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {GET_USER_REFERRALS_LEVEL_1} from "../../../graphql/User/GET_USER_REFERRALS_LEVEL_1";
import {GET_USER_REFERRALS_LEVEL_2} from "../../../graphql/User/GET_USER_REFERRALS_LEVEL_2";
import {GET_USER_REFERRALS_LEVEL_3} from "../../../graphql/User/GET_USER_REFERRALS_LEVEL_3";
import {useGraphQL} from "../../../hook/useGraphQL";
import useVirtualUser from "../../../hook/useVirtualUser";
import axios from "axios";
import Spinner from "../../Elements/Loaders/Spinner";
import Table from "react-bootstrap/Table";
import moment from "moment/moment";
import {math} from "../../../utilities/math";


const PERCENT_LEVEL_1 = 7
const PERCENT_LEVEL_2 = 5
const PERCENT_LEVEL_3 = 3



const Partners = () => {

    const graphQLClient = useGraphQL()

    const {user: userAuth} = useAuth()
    const {user: userVirtual, isLoading: isLoadingVirtualUser} = useVirtualUser()
    const {cabinetId} = useParams()

    const [copied, setCopied] = useState()


    const PAGE_SIZE = 10


    const userId = cabinetId || userAuth?.id
    const user = cabinetId ? userVirtual : userAuth


    const {
        data: data1,
        fetchNextPage: fetchNextPage1,
        hasNextPage: hasNextPage1,
        isLoading: isLoading1,
        isFetchingNextPage: isFetchingNextPage1
    } = useInfiniteQuery({
        queryKey: ['referralsLevel1', userId],
        queryFn: ({pageParam = 1}) => (
            graphQLClient.request(GET_USER_REFERRALS_LEVEL_1, {
                user: userId,
                page: pageParam,
                pageSize: PAGE_SIZE
            }).then(res => res?.referrals)
        ),
        getNextPageParam: (lastPage) => {
            const {page, pageCount} = lastPage?.meta?.pagination || {}
            if (page < pageCount) return page + 1
        }
    })


    const {
        data: data2,
        fetchNextPage: fetchNextPage2,
        hasNextPage: hasNextPage2,
        isLoading: isLoading2,
        isFetchingNextPage: isFetchingNextPage2
    } = useInfiniteQuery({
        queryKey: ['referralsLevel2', userId],
        queryFn: ({pageParam = 1}) => (
            graphQLClient.request(GET_USER_REFERRALS_LEVEL_2, {
                user: userId,
                page: pageParam,
                pageSize: PAGE_SIZE
            }).then(res => res?.referrals)
        ),
        getNextPageParam: (lastPage) => {
            const {page, pageCount} = lastPage?.meta?.pagination || {}
            if (page < pageCount) return page + 1
        }
    })


    const {
        data: data3,
        fetchNextPage: fetchNextPage3,
        hasNextPage: hasNextPage3,
        isLoading: isLoading3,
        isFetchingNextPage: isFetchingNextPage3
    } = useInfiniteQuery({
        queryKey: ['referralsLevel3', userId],
        queryFn: ({pageParam = 1}) => (
            graphQLClient.request(GET_USER_REFERRALS_LEVEL_3, {
                user: userId,
                page: pageParam,
                pageSize: PAGE_SIZE
            }).then(res => res?.referrals)
        ),
        getNextPageParam: (lastPage) => {
            const {page, pageCount} = lastPage?.meta?.pagination || {}
            if (page < pageCount) return page + 1
        }
    })


    const {data: referralProfit, isLoading: isLoadingReferralProfit} = useQuery({
        queryKey: ['transactionSum', 'referralProfit', userId],
        queryFn: () =>
            axios.post('/transaction/sum', {
                user: userId,
                type: 6,
                status: 1
            }).then(res => math.round(res?.data, 2))
    })


    const {data: referralProfitToday, isLoading: isLoadingRefSumToday} = useQuery({
        queryKey: ['transactionSum', 'referralProfitToday', userId],
        queryFn: () =>
            axios.post('/transaction/sum', {
                user: userId,
                type: 6,
                status: 1,
                period: 'today'
            }).then(res => math.round(res?.data, 2))
    })


    const total1 = data1?.pages?.[0]?.meta?.pagination?.total
    const total2 = data2?.pages?.[0]?.meta?.pagination?.total
    const total3 = data3?.pages?.[0]?.meta?.pagination?.total


    const refLink = user?.refLink
    const newUrl = new URL('sign-up/ref/' + refLink, window.location.origin)
    const myRef = newUrl.toString()


    return <>
        {/*<section className="card border-0 py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">*/}
        {/*    <div className="card-body">*/}

        <div className="row g-3 g-xl-4 mb-4">
            <div className="col-md-4 col-sm-6">
                <div className="h-100 bg-secondary rounded-3 text-center p-4">
                    <h2 className="h6 pb-2 mb-1">Всего рефералов</h2>
                    <div className="h2 text-primary mb-2 d-flex gap-4 justify-content-center align-items-center">
                        {
                            (isLoading1 || isLoading2 || isLoading3) ?
                                <Spinner size="2rem"/> :
                                <span>{total1 + total2 + total3}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-6">
                <div className="h-100 bg-secondary rounded-3 text-center p-4">
                    <h2 className="h6 pb-2 mb-1">Реферальных сегодня</h2>
                    <div className="h2 text-primary mb-2">
                        {
                            isLoadingRefSumToday ?
                                <Spinner size="2rem"/> :
                                <span>{referralProfitToday} $</span>
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-12">
                <div className="h-100 bg-secondary rounded-3 text-center p-4">
                    <h2 className="h6 pb-2 mb-1">Всего реферальных</h2>
                    <div className="h2 text-primary mb-2">
                        {
                            isLoadingReferralProfit ?
                                <Spinner size="2rem"/> :
                                <span>{referralProfit} $</span>
                        }
                    </div>
                    {/*<p className="fs-sm text-muted mb-0">*/}
                    {/*    <button className="btn btn-sm btn-outline-primary">Привлечь реферала</button>*/}
                    {/*</p>*/}
                </div>
            </div>
        </div>

        {
            refLink &&
            <div>
                <h5>Моя реф. ссылка</h5>
                <span className="me-2">{myRef}</span>
                <CopyToClipboard text={myRef} onCopy={() => setCopied(true)}>
                    <button className="btn btn-sm btn-outline-primary">
                        {
                            copied ?
                                <><i className="ai-checks me-2"/>Скопировано</> :
                                <><i className="ai-copy me-2"/>Копировать</>
                        }
                    </button>
                </CopyToClipboard>
            </div>
        }


        {
            user?.referrer &&
            <div>
                <h5>Мой наставник</h5>
                {
                    isLoadingVirtualUser ?
                        <Spinner size="2rem"/> :
                        <p>{user?.referrer?.name} {user?.referrer?.surname}</p>
                }
            </div>
        }

        <div>

            <h5>Мои рефералы</h5>


            <div className="table-responsive mb-4">
                <Table striped bordered hover style={{verticalAlign: 'middle'}}>
                    <thead>
                    <tr align="center" valign="middle">
                        <th colSpan={3}>Уровень 1 ({PERCENT_LEVEL_1}%) - {total1} рефералов</th>
                    </tr>
                    <tr align="center" valign="middle">
                        <th>Дата регистрации</th>
                        <th>Имя</th>
                        <th>Страна, город</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        isLoading1 && <tr align="center">
                            <td colSpan={3}>
                                <Spinner/>
                            </td>
                        </tr>
                    }

                    {
                        data1?.pages?.map((group, i) =>
                            <React.Fragment key={i}>
                                {
                                    group?.data?.map(referral => {
                                        const {name, surname, country, city, createdAt} = referral?.attributes || {}
                                        const createdAtPretty = createdAt ? moment(createdAt).format("DD.MM.YYYY") : null
                                        const location = [country, city].filter(item => item).join(', ')
                                        return (
                                            <tr key={referral?.id}>
                                                <td>{createdAtPretty}</td>
                                                <td>{name} {surname}</td>
                                                <td>{location}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    }


                    {
                        data1?.pages?.[0]?.data?.length === 0 &&
                        <tr>
                            <td colSpan={3} align="center">Нет рефералов первого уровня</td>
                        </tr>
                    }


                    {
                        hasNextPage1 &&
                        <tr>
                            <td colSpan={3} align="center" className="a" onClick={() => fetchNextPage1()}>
                                ...еще
                                {
                                    isFetchingNextPage1 &&
                                    <Spinner className="ms-2"/>
                                }
                            </td>
                        </tr>
                    }

                    </tbody>
                </Table>
            </div>


            <div className="table-responsive mb-4">
                <Table striped bordered hover style={{verticalAlign: 'middle'}}>
                    <thead>
                    <tr align="center" valign="middle">
                        <th colSpan={3}>Уровень 2 ({PERCENT_LEVEL_2}%) - {total2} рефералов</th>
                    </tr>
                    <tr align="center" valign="middle">
                        <th>Дата регистрации</th>
                        <th>Имя</th>
                        <th>Страна, город</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        isLoading2 && <tr>
                            <td colSpan={3} align="center">
                                <Spinner/>
                            </td>
                        </tr>
                    }

                    {
                        data2?.pages?.map((group, i) =>
                            <React.Fragment key={i}>
                                {
                                    group?.data?.map(referral => {
                                        const {name, surname, country, city, createdAt} = referral?.attributes || {}
                                        const createdAtPretty = createdAt ? moment(createdAt).format("DD.MM.YYYY") : null
                                        const location = [country, city].filter(item => item).join(', ')
                                        return (
                                            <tr key={referral?.id}>
                                                <td>{createdAtPretty}</td>
                                                <td>{name} {surname}</td>
                                                <td>{location}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    }


                    {
                        data2?.pages?.[0]?.data?.length === 0 &&
                        <tr>
                            <td colSpan={3} align="center">Нет рефералов второго уровня</td>
                        </tr>
                    }


                    {
                        hasNextPage2 &&
                        <tr>
                            <td colSpan={3} align="center" className="a" onClick={() => fetchNextPage2()}>
                                ...еще
                                {
                                    isFetchingNextPage2 &&
                                    <Spinner className="ms-2"/>
                                }
                            </td>
                        </tr>
                    }

                    </tbody>
                </Table>
            </div>

            <div className="table-responsive">
                <Table striped bordered hover style={{verticalAlign: 'middle'}}>
                    <thead>
                    <tr align="center" valign="middle">
                        <th colSpan={3}>Уровень 3 ({PERCENT_LEVEL_3}%) - {total3} рефералов</th>
                    </tr>
                    <tr align="center" valign="middle">
                        <th>Дата регистрации</th>
                        <th>Имя</th>
                        <th>Страна, город</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        isLoading3 && <tr>
                            <td colSpan={3} align="center">
                                <Spinner/>
                            </td>
                        </tr>
                    }

                    {
                        data3?.pages?.map((group, i) =>
                            <React.Fragment key={i}>
                                {
                                    group?.data?.map(referral => {
                                        const {name, surname, country, city, createdAt} = referral?.attributes || {}
                                        const createdAtPretty = createdAt ? moment(createdAt).format("DD.MM.YYYY") : null
                                        const location = [country, city].filter(item => item).join(', ')
                                        return (
                                            <tr key={referral?.id}>
                                                <td>{createdAtPretty}</td>
                                                <td>{name} {surname}</td>
                                                <td>{location}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    }


                    {
                        data3?.pages?.[0]?.data?.length === 0 &&
                        <tr>
                            <td colSpan={3} align="center">Нет рефералов третьего уровня</td>
                        </tr>
                    }


                    {
                        hasNextPage3 &&
                        <tr>
                            <td colSpan={3} align="center" className="a" onClick={() => fetchNextPage3()}>
                                ...еще
                                {
                                    isFetchingNextPage3 &&
                                    <Spinner className="ms-2"/>
                                }
                            </td>
                        </tr>
                    }

                    </tbody>
                </Table>
            </div>


        </div>

        

        {/*    </div>*/}
        {/*</section>*/}
    </>
}

export default React.memo(Partners);