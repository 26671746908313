import React from 'react';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import Spinner from "../../Elements/Loaders/Spinner";
import ModalDeposit from "./ModalDeposit";
import ModalWithdrawal from "./ModalWithdrawal";
import useUserId from "../../../hook/useUserId";
import {Link, useParams} from "react-router-dom";
import {math} from "../../../utilities/math";
import useBalance from "../../../hook/useBalance";

const Stat = () => {

    const userId = useUserId(true)
    const {balance, isLoading: isLoadingBalance, isSuccess: isSuccessBalance} = useBalance()



    const {data: todayProfit, isLoading: isLoadingTodayProfit} = useQuery({
        queryKey: ['transactionSum', 'todayProfit', userId],
        queryFn: () =>
            axios.post('/transaction/sum', {
                user: userId,
                type: [3, 4, 5, 6],
                status: 1,
                period: 'today'
            }).then(res => math.round(res?.data, 2))
    })



    const {data: monthProfit, isLoading: isLoadingMonthProfit} = useQuery({
        queryKey: ['transactionSum', 'monthProfit', userId],
        queryFn: () =>
            axios.post('/transaction/sum', {
                user: userId,
                type: [3, 4, 5, 6],
                status: 1,
                period: 'month'
            }).then(res => math.round(res?.data, 2))
    })



    const {data: referralProfit, isLoading: isLoadingReferralProfit} = useQuery({
        queryKey: ['transactionSum', 'referralProfit', userId],
        queryFn: () =>
            axios.post('/transaction/sum', {
                user: userId,
                type: 6,
                status: 1
            }).then(res => math.round(res?.data, 2))
    })




    const {cabinetId} = useParams()

    const cabinetUrl = cabinetId ? ('/' + cabinetId) : ''


    return (
        <>
            <div className="row g-3 g-xl-4 mb-4">
                <div className="col-md-4 col-sm-6">
                    <div className="h-100 bg-secondary rounded-3 text-center p-4">
                        <h2 className="h6 pb-2 mb-1">Ваш баланс</h2>
                        <div className="h2 text-primary mb-2">
                            {
                                isLoadingBalance &&
                                <Spinner size="2rem"/>
                            }
                            {
                                isSuccessBalance &&
                                <span>{balance} $</span>
                            }
                        </div>
                        <p className="fs-sm text-muted mb-0 d-flex flex-wrap justify-content-center gap-2">

                            <ModalDeposit/>

                            <ModalWithdrawal/>

                        </p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="h-100 bg-secondary rounded-3 text-center p-4">
                        <h2 className="h6 pb-2 mb-1">Заработано сегодня</h2>
                        <div className="h2 text-primary mb-2">
                            {
                                isLoadingTodayProfit ?
                                    <Spinner size="2rem"/> :
                                    <span>{todayProfit} $</span>
                            }
                        </div>
                        <h2 className="h6 pb-2 mb-1">Заработано за месяц</h2>
                        <div className="h2 text-primary mb-2">
                            {
                                isLoadingMonthProfit ?
                                    <Spinner size="2rem"/> :
                                    <span>{monthProfit} $</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="h-100 bg-secondary rounded-3 text-center p-4">
                        <h2 className="h6 pb-2 mb-1">Реферальные</h2>
                        <div className="h2 text-primary mb-2">
                            {
                                isLoadingReferralProfit ?
                                    <Spinner size="2rem"/> :
                                    <span>{referralProfit} $</span>
                            }
                        </div>
                        <p className="fs-sm text-muted mb-0">
                            <Link
                                className="btn btn-sm btn-outline-secondary flex-fill fs-6"
                                to={`/cabinet${cabinetUrl}/partners`}
                            >
                                Привлечь реферала
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(Stat);