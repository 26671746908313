import React from "react";
import {Route, Routes} from "react-router-dom";
import SignIn from "./components/Sign/SignIn";
import SignUp from "./components/Sign/SignUp";
import Wrapper from "./components/Wrapper";
import Cabinet from "./components/Cabinet/Cabinet";
import NotFound from "./components/Static/NotFound";
import {useAuth} from "./hook/useAuth";
import PageLoader from "./components/Elements/Loaders/PageLoader";
import MyContainers from "./components/Cabinet/MyContainers/MyContainers";
import Partners from "./components/Cabinet/Partners/Partners";
import Profile from "./components/Cabinet/Profile/Profile";
import Balance from "./components/Cabinet/Balance/Balance";
import Main from "./components/Main/Main";
import Transactions from "./components/Cabinet/Admin/Transactions/Transactions";
import useIsAdmin from "./hook/useIsAdmin";
import Investments from "./components/Cabinet/Investments/Investments";
import Contacts from "./components/Static/Contacts";
import News from "./components/News/News";
import "swiper/swiper-bundle.min.css";
import "flatpickr/dist/flatpickr.min.css";
import './templates/Around/src/scss/theme.scss'
import './style/App.scss'
import NetworkError from "./components/Static/NetworkError";
import Maintenance from "./components/Static/Maintenance";
import Users from "./components/Cabinet/Admin/Users/Users";
import Ports from "./components/Static/Ports";
import Containers from "./components/Cabinet/Containers/Containers";
import Statistics from "./components/Statistics/Statistics";
import Coins from './components/Cabinet/Coins/Coins';
import Vacancies from './components/Static/Vacancies';
import useShowCoin from './hook/useShowCoin';

// import PrivacyPolicy from "./components/Static/PrivacyPolicy";
// import AboutCompany from "./components/Static/AboutCompany";


const PrivacyPolicy = React.lazy(() => import("./components/Static/PrivacyPolicy"))
const AboutCompany = React.lazy(() => import("./components/Static/AboutCompany"))


function App() {

    const {user, isLoading, isError} = useAuth()

    const {showToAdmins, showToUsers} = useShowCoin()
    const isAdmin = useIsAdmin()

    if (isLoading) return <PageLoader/>


    if (!window.navigator.onLine) {
        return <NetworkError/>
    }

    if (isError) {
        return <Maintenance/>
        // console.log(error)
        // if (error?.code === "ERR_NETWORK") {
        // }
    }


    // if (!user) return <SignIn/>
    // todo Неавторизованным не видно сайт


    const cabinetPath = isAdmin ? "cabinet/:cabinetId?" : "cabinet"


    return (
        <>
            <Routes>
                <Route path="/" element={<Wrapper/>}>

                    <Route index element={<Main/>}/>
                    <Route path="sign-in" element={<SignIn/>}/>
                    <Route path="sign-up/ref/:refLink" element={<SignUp/>}/>

                    <Route path={cabinetPath} element={<Cabinet/>} >
                        <Route path="profile" element={<Profile/>}/>
                        <Route path="investments" element={<Investments/>}/>
                        <Route path="containers" element={<Containers/>}/>
                        <Route path="my-containers" element={<MyContainers/>}/>
                        <Route path="balance" element={<Balance/>}/>
                        <Route path="partners" element={<Partners/>}/>
                        {
                            (isAdmin ? showToAdmins : showToUsers) &&
                            <Route path="coins" element={<Coins/>}/>
                        }
                        {
                            isAdmin && <>
                                <Route path="transactions" element={<Transactions/>}/>
                                <Route path="users" element={<Users/>}/>
                                {/*<Route path="interest-payment" element={<InterestPayment/>}/>*/}
                            </>
                        }
                    </Route>



                    <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="about-company" element={<AboutCompany/>}/>
                    <Route path="contacts" element={<Contacts/>}/>
                    <Route path="ports" element={<Ports/>}/>
                    <Route path="news" element={<News/>}/>
                    <Route path="statistics" element={<Statistics/>}/>
                    <Route path="vacancies" element={<Vacancies/>}/>
                    <Route path="*" element={<NotFound/>}/>

                </Route>

            </Routes>
        </>
    )
}

export default App;
