export const BACKEND_URL = window.location.hostname === 'localhost' ?
    "http://localhost:1337" :
    "https://backend." + window.location.host

export const API = `${BACKEND_URL}/api`
export const GRAPHQL_ENDPOINT = `${BACKEND_URL}/graphql`
export const AUTH_TOKEN = "jwt"
export const BEARER = "Bearer"

export const TRANSACTION_ID_OFFSET = 120000
export const USER_ID_OFFSET = 11000

export const TIMEZONE = 3 // hours