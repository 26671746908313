import React, {useState} from 'react';
import Spinner from '../../Elements/Loaders/Spinner';
import GetButton from './GetButton';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {math} from '../../../utilities/math';
import useUserId from '../../../hook/useUserId';
import CountUp from 'react-countup';
import Timer from './Timer';
import Referrals from './Referrals';
import Level from './Level';
import Faq from './Faq';
import ChangeButton from './ChangeButton';



function Coins() {

    const isSuccessBalance = true

    const [countReferrals, setCountReferrals] = useState()

    const userId = useUserId(true)

    const [isDeadline, setIsDeadline] = useState(false)



    const balanceFilter = {user: userId}
    const {data: balance, isLoading: isLoadingBalance} = useQuery({
        queryKey: ['coinTransactionSum', balanceFilter],
        queryFn: () => axios
            .post('/coin-transaction/sum', balanceFilter)
            .then(res => math.round(res?.data, 2))
    })



    const refProfitFilter = {user: userId, type: 2}
    const {data: refProfit, isLoading: isLoadingRefProfit} = useQuery({
        queryKey: ['coinTransactionSum', refProfitFilter],
        queryFn: () => axios
            .post('/coin-transaction/sum', refProfitFilter)
            .then(res => math.round(res?.data, 2))
    })


    const levelClass = 'border-success bg-faded-success'

    const level_1_class = countReferrals < 10 ? levelClass : ''
    const level_2_class = countReferrals >= 10 ? levelClass : ''
    const level_3_class = countReferrals >= 30 ? levelClass : ''



    return (
        <>
            <div className="row g-3 g-xl-4 mb-4">
                <div className="col-md-4 col-sm-6">
                    <div
                        className="h-100 bg-secondary rounded-3 text-center p-4">
                        <h2 className="h6 pb-2 mb-1">Баланс CoscoCoin</h2>
                        <div className="h2 text-primary mb-2">
                            {
                                isLoadingBalance &&
                                <Spinner size="2rem"/>
                            }
                            {
                                isSuccessBalance &&
                                <span>
                                    <CountUp
                                        end={balance}
                                        preserveValue={true}
                                        suffix=" CC"
                                        decimals={2}
                                    />
                                </span>
                            }
                        </div>
                        <p className="fs-sm text-muted mb-0 d-flex flex-wrap justify-content-center gap-2">


                            <GetButton
                                isDeadline={isDeadline}
                            />

                        </p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="h-100 bg-secondary rounded-3 text-center p-4">
                        <h2 className="h6 pb-2 mb-1">Реферальные</h2>
                        <div className="h2 text-primary mb-2">
                            {
                                isLoadingRefProfit ?
                                    <Spinner size="2rem"/> :
                                    <span>{refProfit} CC</span>
                            }
                        </div>
                        <h2 className="h6 pb-2 mb-1">
                            Верифицированных рефералов 1-го уровня
                        </h2>
                        <div className="h2 text-primary mb-2">
                            {countReferrals}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="h-100 bg-secondary rounded-3 justify-content-center p-4 d-flex flex-column text-center gap-2">

                        <h5>До запуска <i className="ai-rocket text-primary"/></h5>

                        <Timer
                            setIsDeadline={setIsDeadline}
                        />

                        <ChangeButton
                            sum={balance}
                            isDeadline={isDeadline}
                        />

                    </div>
                </div>
            </div>


            <div className="row gap-3 mb-3 justify-content-center">
                <Level level={1} partners="до 10" coins="1.0"
                       className={level_1_class}/>
                <Level level={2} partners="от 10" coins="1.2"
                       className={level_2_class}/>
                <Level level={3} partners="от 30" coins="1.5" className={level_3_class}/>
            </div>


            <Referrals
                setCountReferrals={setCountReferrals}
            />


            <Faq/>

        </>
    )
}

export default React.memo(Coins)