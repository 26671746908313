import React from 'react';

const RadioInput = ({value, setValue, options}) => {

    return <>
        <div className="col-12 d-sm-flex align-items-center pt-sm-2">
            {
                options.map((option, i) => {
                    return (
                        <div className="form-check form-check-inline mb-0" key={i}>
                            <label className="form-check-label">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    // name="gender"
                                    checked={option === value}
                                    onChange={() => setValue(option)}
                                />
                                {option}
                            </label>
                        </div>
                    )
                })
            }
        </div>

    </>
}

export default React.memo(RadioInput);