import {gql} from "graphql-request";

export const GET_STATISTIC = gql`

    query GetStatistic(
        
        $sort: [String] = "datetime:asc"
        $page: Int = 1
        $pageSize: Int = 1000
        
    ) {
        statistics(
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    date
                    investments
                    containers
                }
            }
        }
    }
`