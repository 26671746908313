import React from 'react';
import GraphLine from "./GraphLine";
import Containers from "../Main/Containers";
import Investments from "../Main/Investments";

function Statistics() {
    return (
        <div className="container pt-5 mt-5">

            <div className="mb-5 mt-3">
                <h3 className="text-center mb-4">Статистика покупок</h3>
                <GraphLine/>
            </div>

            <div className="my-5">
                <h3 className="text-center mb-4">Контейнеры</h3>
                <Containers/>
            </div>

            <div className="my-5">
                <h3 className="text-center mb-4">Инвестиции</h3>
                <Investments/>
            </div>

        </div>
    )
}

export default React.memo(Statistics);