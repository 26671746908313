import React, {useEffect} from 'react';
import {Pagination, Swiper} from "swiper";
import {useGraphQL} from "../../../hook/useGraphQL";
import {useQuery} from "@tanstack/react-query";
import {GET_CONTAINERS} from "../../../graphql/Container/GET_CONTAINERS";
import {BACKEND_URL} from "../../../config";
import ModalBuyContainer from "./ModalBuyContainer";
import {math} from "../../../utilities/math";
import {GET_MY_INVESTMENTS} from "../../../graphql/MyInvestment/GET_MY_INVESTMENTS";
import useUserId from "../../../hook/useUserId";



const SELECTOR = 'containers-swiper'


const Containers = () => {

    const graphQLClient = useGraphQL()
    const userId = useUserId(true)

    const {data} = useQuery({
        queryKey: ['containers'],
        queryFn: () => graphQLClient.request(GET_CONTAINERS)
    })


    const {data: myInvestments} = useQuery({
        queryKey: ['myInvestments', {user: userId}],
        queryFn: () => graphQLClient.request(GET_MY_INVESTMENTS, {
            user: userId
        }).then(res => res?.myInvestments)
    })



    const discountArr = myInvestments?.data?.map(myInvestment => myInvestment?.attributes?.investment?.data?.attributes?.discount)
    const maxDiscount = Math.max.apply(null, discountArr)
    const discount = isFinite(maxDiscount) ? maxDiscount : 0






    useEffect(() => {

        Swiper.use([Pagination])

        new Swiper(`#${SELECTOR}`, {
            "spaceBetween": 24,
            "pagination": {
                "el": ".swiper-pagination",
                "clickable": true
            },
            "breakpoints": {
                "576": {"slidesPerView": 2},
                "992": {"slidesPerView": 3}
            }
        })

    }, [])



    return <>

        <h2 className="h1 pt-xxl-1 pb-4 mb-2 mb-lg-3">Контейнеры</h2>

        <div id={SELECTOR} className="swiper">
            <div className="swiper-wrapper">
                {
                    data?.containers?.data?.map(container => {

                        const {name, price, params} = container?.attributes || {}
                        const imgUrl = BACKEND_URL + container?.attributes?.img?.data?.attributes?.url


                        const discountPrice = math.ceil(price * (1 - discount / 100), 2)


                        return (
                            <div className="swiper-slide h-auto" key={container?.id}>
                                <div className="card h-100 border-0 rounded-4 pb-3">
                                    <div className="position-relative ignore-dark-mode">
                                        <div className="item-responsive item-4by3">
                                            <img
                                                className="rounded-4 rounded-bottom-0 content w-100 h-100"
                                                src={imgUrl} alt=""
                                            />
                                        </div>

                                        <div className="card-body position-absolute start-0 bottom-0 py-4">
                                            <div className="d-table badge bg-danger rounded-pill mb-2">
                                                Доходность
                                            </div>
                                            <div className="d-table badge bg-light text-nav rounded-pill fs-sm">
                                                {params?.profitability} в месяц
                                            </div>
                                        </div>

                                    </div>

                                    <div className="card-body pb-4">
                                        <div className="text-primary fw-bold pb-2 mb-1 fs-5">

                                            {
                                                discount ?
                                                    <>
                                                        <span className="text-decoration-line-through">${price}</span>
                                                        <span className="text-danger ms-3">${discountPrice}</span>
                                                    </> :
                                                    <span>${price}</span>
                                            }

                                        </div>
                                        <h3 className="h4">{name}</h3>
                                        <ul className="fs-sm ps-4 mb-0">
                                            <li className="mb-2">
                                                Длина: {params?.length}
                                            </li>
                                            <li className="mb-2">
                                                Ширина: {params?.width}
                                            </li>
                                            <li className="mb-2">
                                                Высота: {params?.height}
                                            </li>
                                            <li className="mb-2">
                                                Вместимость: {params?.capacity}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-footer border-0 pt-0">
                                        <ModalBuyContainer
                                            container={container}
                                            price={discountPrice}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="swiper-pagination position-relative bottom-0 mt-2 pt-4"/>

        </div>

    </>
}

export default React.memo(Containers);