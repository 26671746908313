import React, {useEffect} from 'react';
import {Pagination, Swiper} from "swiper";
import {useGraphQL} from "../../hook/useGraphQL";
import {useQuery} from "@tanstack/react-query";
import {GET_INVESTMENTS} from "../../graphql/Investment/GET_INVESTMENTS";
import Investment from "../Cabinet/Investments/Investment";


const SELECTOR = 'investments-swiper'


const Investments = () => {

    const graphQLClient = useGraphQL()


    const {data: investments} = useQuery({
        queryKey: ['investments'],
        queryFn: () => graphQLClient.request(GET_INVESTMENTS).then(res => res?.investments)
    })





    useEffect(() => {

        Swiper.use([Pagination])

        new Swiper(`#${SELECTOR}`, {
            "spaceBetween": 24,
            "pagination": {
                "el": ".swiper-pagination",
                "clickable": true
            },
            "breakpoints": {
                "400": {"slidesPerView": 2},
                "650": {"slidesPerView": 3},
                "992": {"slidesPerView": 4}
            }
        })

    }, [])


    return <>

        <div id={SELECTOR} className="swiper">
            <div className="swiper-wrapper">
                {
                    investments?.data?.map(investment => <Investment
                        key={investment?.id}
                        investment={investment}
                        hideBuyBtn={true}
                    />)
                }
            </div>

            <div className="swiper-pagination position-relative bottom-0 mt-2 pt-4"/>
        </div>

    </>
}

export default React.memo(Investments);