import React, {useState} from 'react';
import CoverImage from "./CoverImage";
import {Link, Navigate, useLocation} from "react-router-dom";
import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import HomeButton from "../Elements/HomeButton";
import {useAuth} from "../../hook/useAuth";


const SignIn = () => {

    const {user, setUser, setToken} = useAuth()
    const location = useLocation()


    const [passwordToggleInput, setPasswordToggleInput] = useState(true)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')



    const queryFn = () => axios.post('/auth/local', {
        identifier: email,
        password
    }, {
        headers: {
            Authorization: null
        }
    })
    // @todo: Здесь решить нужно ли header

    // Login это мутация. Graphql об этом говорит
    const {isFetching, error, refetch} = useQuery({
        queryKey: ['signin'],
        queryFn,
        enabled: false,
        retry: false
    })


    if (user) return <Navigate
        to={location?.state?.fromPage || "/cabinet/profile"}
        replace={true}
    />


    const handleSubmit = e => {

        e.preventDefault()
        const form = e.target

        form.classList.add('was-validated')

        const isFormValid = form.checkValidity()
        if (!isFormValid) return

        refetch().then(response => {
            const {jwt, user} = response?.data?.data || {}
            if (!jwt) return
            if (!user) return
            setToken(jwt)
            setUser(user)
        })
    }

    const strapiError = error?.response?.data?.error?.message === 'Invalid identifier or password' ?
        'Неправильный логин или пароль' :
        error?.response?.data?.error?.message
    const errorMessage = strapiError || error?.message


    return (
        // Page wrapper
        <div className="container py-5 mt-4 mt-lg-5 mb-lg-4 my-xl-5">
            <div className="d-lg-flex position-relative h-100">
                {/*Sign in form*/}
                <HomeButton/>
                <div className="d-flex flex-column align-items-center w-lg-50 h-100 px-3 px-lg-5 pt-5">
                    <div className="w-100 mt-auto" style={{maxWidth: '526px'}}>
                        <h1>Войти в аккаунт</h1>
                        {/*<p className="pb-3 mb-3 mb-lg-4">*/}
                        {/*    Нет аккаунта?&nbsp;&nbsp;*/}
                        {/*    <Link to="/sign-up">Зарегистрируйтесь здесь!</Link>*/}
                        {/*</p>*/}
                        <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                            <div className="pb-3 mb-3">
                                <div className="position-relative">
                                    <i className="ai-mail fs-lg position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                                    <input className="form-control form-control-lg ps-5"
                                           type="email"
                                           placeholder="Email адрес"
                                           required
                                           autoFocus
                                           value={email}
                                           onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="position-relative"><i
                                    className="ai-lock-closed fs-lg position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                                    <div className="password-toggle">
                                        <input className="form-control form-control-lg ps-5"
                                               placeholder="Пароль"
                                               type={passwordToggleInput ? 'password' : 'text'}
                                               minLength="6"
                                               required
                                               value={password}
                                               onChange={e => setPassword(e.target.value)}
                                        />
                                        <label className="password-toggle-btn" aria-label="Show/hide password">
                                            <input className="password-toggle-check"
                                                   type="checkbox"
                                                   tabIndex="-1"
                                                   checked={passwordToggleInput}
                                                   onChange={e => setPasswordToggleInput(e.target.checked)}
                                            />
                                            <span className="password-toggle-indicator"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-danger fs-sm mb-2">{errorMessage}</div>
                            <button className="btn btn-lg btn-primary w-100 mb-4" type="submit">
                                {
                                    isFetching &&
                                    <span className="spinner-border spinner-border-sm me-2" role="status"
                                          aria-hidden="true"/>
                                }
                                Войти
                            </button>
                        </form>
                    </div>
                </div>
                <CoverImage/>
            </div>
        </div>

    )
}

export default React.memo(SignIn);