import React from 'react';
import Menu from "./Menu";

const SidebarLeft = () => {

    return <>
        {/* Sidebar (offcanvas on sreens < 992px)*/}
        <aside className="col-lg-3 pe-lg-4 pe-xl-5 mt-n3 d-lg-none">
            <div className="position-lg-sticky top-0">
                <div className="d-none d-lg-block" style={{paddingTop: '45px'}}/>

                <div className="offcanvas-lg offcanvas-start" id="sidebarLeft">

                    <button className="btn-close position-absolute top-0 end-0 mt-3 me-3 d-lg-none" type="button"
                            data-bs-dismiss="offcanvas" data-bs-target="#sidebarLeft"/>

                    <div className="offcanvas-body">

                        <nav className="nav flex-column pb-2 pb-lg-4 mb-1">
                            <h4 className="fs-xs fw-medium text-muted text-uppercase pb-1 mb-2">Разделы</h4>
                            <Menu/>
                        </nav>

                    </div>
                </div>

            </div>
        </aside>
    </>
}

export default React.memo(SidebarLeft);