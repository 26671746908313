import React, {useEffect, useState} from 'react';
import CoverImage from "./CoverImage";
import {Link, Navigate, useParams} from "react-router-dom";
import {useRef} from 'react';
import axios from "axios";
import {useMutation, useQuery} from '@tanstack/react-query';
import HomeButton from "../Elements/HomeButton";
import {useAuth} from "../../hook/useAuth";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import randomstring from 'randomstring';
import {GET_USERS} from "../../graphql/User/GET_USERS";
import {useGraphQL} from "../../hook/useGraphQL";


const SignUp = () => {

    const {user, setUser, setToken} = useAuth()
    const {refLink} = useParams()
    const graphQLClient = useGraphQL()

    // const [err, setErr] = useState('')

    const query = {refLink}
    const {data: referrers} = useQuery({
        queryKey: ['users', GET_USERS, query],
        queryFn: () =>
            graphQLClient.request(GET_USERS, query)
                .then(res => res?.usersPermissionsUsers?.data),
        enabled: !!refLink
    })

    const referrer = referrers?.[0]
    const refId = referrer?.id

    const confirmPasswordRef = useRef(null)


    const [passwordToggleInput1, setPasswordToggleInput1] = useState(true)
    const [passwordToggleInput2, setPasswordToggleInput2] = useState(true)

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [skype, setSkype] = useState('')
    const [telegram, setTelegram] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')


    useEffect(() => {
        confirmPasswordRef.current?.setAttribute("pattern", password)
    }, [password])



    const {mutateAsync, isLoading, error} = useMutation({
        mutationFn: variables => axios.post('/auth/local/register', variables),
        retry: false
    })



    const ref = useRef(null)

    useEffect(() => {
        ref?.current?.classList.remove('PhoneInputInput')
        ref?.current?.classList.add('form-control')
        ref?.current?.classList.add('form-control-lg')
    }, [ref])


    if (user) return <Navigate
        to="/cabinet/profile"
        replace={true}
    />


    const handleSubmit = e => {

        e.preventDefault()

        const form = e.target

        form.classList.add('was-validated')

        const isFormValid = form.checkValidity()
        if (!isFormValid) return

        if (!refId) {
            // setErr('Неверная реферальная ссылка')
            return
        }

        mutateAsync({
            username: email,
            email,
            password,
            pass: password,
            name,
            surname,
            skype,
            telegram,
            phone,
            referrer: refId,
            refLink: randomstring.generate(10)
        }).then(response => {
            const {jwt, user} = response?.data || {}
            if (!jwt) return
            if (!user) return
            setToken(jwt)
            setUser(user)
        })
    }


    const err2 = referrers && !refId ? 'Неверная реферальная ссылка' : ''
    const strapiError = error?.response?.data?.error?.message
    const errorMessage = strapiError || error?.message


    return <>
        <div className="container py-5 mt-4 mt-lg-5 mb-lg-4 my-xl-5">
            <div className="d-lg-flex position-relative h-100">
                {/*Sign up form*/}
                <HomeButton/>
                <div className="d-flex flex-column align-items-center w-lg-50 h-100 px-3 px-lg-5 pt-5">
                    <div className="w-100 mt-auto" style={{maxWidth: '526px'}}>
                        <h1>Регистрация аккаунта</h1>
                        <div className="text-danger fs-sm mb-2">{err2}</div>
                        <p className="pb-3 mb-3 mb-lg-4">
                            Уже есть аккаунт?&nbsp;&nbsp;
                            <Link to="/sign-in">Войдите здесь!</Link>
                        </p>
                        <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                            <div className="fs-xs mb-3">Поля, отмеченные *, обязательны для заполнения</div>
                            <div className="row row-cols-1 row-cols-sm-2">
                                <div className="col mb-4">
                                    <input className="form-control form-control-lg"
                                           type="text"
                                           placeholder="Имя *"
                                           required
                                           autoFocus
                                           value={name}
                                           onChange={e => setName(e.target.value)}
                                    />
                                </div>
                                <div className="col mb-4">
                                    <input className="form-control form-control-lg"
                                           type="text"
                                           placeholder="Фамилия *"
                                           required
                                           value={surname}
                                           onChange={e => setSurname(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col mb-4">
                                <input className="form-control form-control-lg"
                                       type="email"
                                       placeholder="Email адрес *"
                                       required
                                       value={email}
                                       onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="col mb-4">
                                <input className="form-control form-control-lg"
                                       type="text"
                                       placeholder="Skype"
                                       value={skype}
                                       onChange={e => setSkype(e.target.value)}
                                />
                            </div>
                            <div className="col mb-4">
                                <input className="form-control form-control-lg"
                                       type="text"
                                       placeholder="Telegram *"
                                       required
                                       value={telegram}
                                       onChange={e => setTelegram(e.target.value)}
                                />
                            </div>
                            <div className="col mb-4">

                                <PhoneInput
                                    ref={ref}
                                    placeholder="Номер телефона"
                                    value={phone}
                                    onChange={setPhone}
                                />

                                {/*<input className="form-control form-control-lg"*/}
                                {/*       type="text" placeholder="Номер телефона"*/}
                                {/*       value={phone}*/}
                                {/*       onChange={e => setPhone(e.target.value)}*/}
                                {/*/>*/}

                            </div>
                            <div className="password-toggle mb-4">
                                <input className="form-control form-control-lg"
                                       type={passwordToggleInput1 ? 'password' : 'text'}
                                       placeholder="Пароль *"
                                       autoComplete="new-password"
                                       minLength="6"
                                       required
                                       value={password}
                                       onChange={e => setPassword(e.target.value)}
                                />
                                <label className="password-toggle-btn" aria-label="Show/hide password">
                                    <input className="password-toggle-check"
                                           type="checkbox"
                                           tabIndex="-1"
                                           checked={passwordToggleInput1}
                                           onChange={e => setPasswordToggleInput1(e.target.checked)}
                                    />
                                    <span className="password-toggle-indicator"></span>
                                </label>
                            </div>
                            <div className="password-toggle mb-4">
                                <input className="form-control form-control-lg"
                                       type={passwordToggleInput2 ? 'password' : 'text'}
                                       placeholder="Подтверждение пароля *"
                                       minLength="6"
                                       required
                                       value={confirmPassword}
                                       onChange={e => setConfirmPassword(e.target.value)}
                                       ref={confirmPasswordRef}
                                />
                                <label className="password-toggle-btn" aria-label="Show/hide password">
                                    <input className="password-toggle-check"
                                           type="checkbox"
                                           tabIndex="-1"
                                           checked={passwordToggleInput2}
                                           onChange={e => setPasswordToggleInput2(e.target.checked)}
                                    />
                                    <span className="password-toggle-indicator"></span>
                                </label>
                            </div>
                            <div className="pb-2">
                                <div className="form-check my-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="terms"
                                        defaultChecked
                                    />
                                    <label className="form-check-label ms-1" htmlFor="terms">
                                        Соглашаюсь с <Link to="/privacy-policy" target="_blank">Политикой
                                        конфиденциальности</Link>
                                    </label>
                                </div>
                            </div>
                            <div className="text-danger fs-sm mb-2">{errorMessage}</div>
                            <button className="btn btn-lg btn-primary w-100 mb-4" type="submit">
                                {
                                    isLoading &&
                                    <span className="spinner-border spinner-border-sm me-2" role="status"
                                          aria-hidden="true"/>
                                }
                                Зарегистрироваться
                            </button>
                        </form>
                    </div>
                </div>
                <CoverImage/>
            </div>
        </div>
    </>
}

export default React.memo(SignUp);