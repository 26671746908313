import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {stickyNavbar} from "../../utilities/AroundComponents";
import Logo from "../Elements/Logo";
import {useAuth} from "../../hook/useAuth";
import Menu from "../Elements/Menu";


const Header = () => {

    const {user} = useAuth()


    useEffect(() => {
        stickyNavbar()
    }, [])


    return (
        // Navbar. Remove 'fixed-top' class to make the navigation bar scrollable with the page
        <header className="navbar navbar-expand-lg bg-light fixed-top">
            <div className="container">


                <button
                    className="navbar-toggler d-lg-none"
                    type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#sidebarLeft"
                >
                    <span className="navbar-toggler-icon"/>
                </button>





                <span className="pe-sm-3">
                    <Logo/>
                </span>


                {/*<AccountDropdownSm/>*/}


                <nav className="collapse navbar-collapse" id="navbarNav">
                    <Menu/>
                </nav>


                {/*<button className="navbar-toggler ms-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">*/}
                {/*    <span className="navbar-toggler-icon"/>*/}
                {/*</button>*/}


                {
                    user ?
                        <>
                            <i className="ai-grid fs-3 a"
                               data-bs-toggle="offcanvas" data-bs-target="#sidebarRight"
                            />
                            {/*<span className="d-none d-lg-inline">{user?.name}</span>*/}
                        </> :
                    <Link to="/sign-in" className="btn btn-sm btn-outline-info">Войти</Link>
                }






            </div>

        </header>
    )
}

export default React.memo(Header);