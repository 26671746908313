import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import Spinner from "../../Elements/Loaders/Spinner";
import {math} from "../../../utilities/math";
import useUserId from "../../../hook/useUserId";
import useBalance from "../../../hook/useBalance";


const ModalWithdrawal = () => {

    const queryClient = useQueryClient()
    const userId = useUserId(true)
    const {balance} = useBalance()

    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [amount, setAmount] = useState('')
    const [withdrawalRequisite, setWithdrawalRequisite] = useState('')



    useEffect(() => {
        setError('')
    }, [amount, withdrawalRequisite])



    const {mutate, isLoading, isSuccess, reset} = useMutation({
        mutationFn: variables => axios.post('transactions', variables),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['transactions']})
            queryClient.invalidateQueries({queryKey: ['transactionSum', 'outgoingInProgress']})
        }
    })


    const addTransaction = () => {

        if (!amount) {
            setError('Укажите сумму вывода')
            return
        }

        const newAmount = parseFloat(amount)

        if (isNaN(newAmount)) {
            setError('Укажите корректную сумму')
            return
        }

        if (newAmount <= 0) {
            setError('Сумма должна быть больше нуля')
            return
        }

        if (balance && newAmount > balance) {
            setError('Сумма превышает баланс. Максимум ' + balance + '$')
            return
        }

        mutate({
            data: {
                amount: -math.floor(newAmount, 2),
                type: 2, // ID
                status: 3, // ID
                user: userId, // ID
                withdrawalRequisite,
                comment: "Вывод на реквизиты клиента"
            }
        })
    }


    const handleClose = () => {
        reset()
        setAmount('')
        setError('')
        setShowModal(false)
        setWithdrawalRequisite('')
    }

    const handleShow = () => setShowModal(true)



    return (

        <>

            <button
                className="btn btn-sm btn-outline-secondary flex-fill fs-6"
                onClick={handleShow}
            >
                Вывести
            </button>


            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Вывод</Modal.Title>
                </Modal.Header>


                <Modal.Body>

                    {
                        isSuccess &&
                        <>
                            <h3 className="text-center">
                                Заявка принята!
                            </h3>
                            <h5 className="text-center mb-0">
                                Транзакция будет проведена сразу после проверки бухгалтерией
                            </h5>
                        </>
                    }

                    {
                        !isSuccess && <>

                            <input
                                type="tel"
                                className="form-control"
                                placeholder="Сумма, $"
                                value={amount}
                                onChange={e => setAmount(e.target.value)}
                            />

                            <textarea
                                className="form-control mt-3"
                                rows={4}
                                placeholder="Укажите реквизиты для вывода"
                                value={withdrawalRequisite}
                                onChange={e => setWithdrawalRequisite(e.target.value)}
                            />

                            {
                                error &&
                                <div className="alert d-flex alert-warning mt-3 mb-0" role="alert">
                                    <i className="ai-triangle-alert fs-xl pe-1 me-2"/>
                                    <div>{error}</div>
                                </div>
                            }
                        </>

                    }


                </Modal.Body>


                <Modal.Footer>
                    {
                        !isSuccess ?
                            <Button variant="primary" className="w-100" onClick={addTransaction}>
                                {isLoading && <Spinner/>}
                                Вывести
                            </Button> :
                            <Button variant="primary" className="w-100" onClick={handleClose}>
                                {isLoading && <Spinner/>}
                                Закрыть
                            </Button>
                    }
                </Modal.Footer>

            </Modal>
        </>


    )
}


export default React.memo(ModalWithdrawal)
