import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";

import axios from "axios";
import qs from "qs";

const useVirtualUser = () => {

    const {cabinetId} = useParams()

    const query = qs.stringify({
        populate: {
            role: true,
            referrer: true,
            avatar: true,
            document: true
        }
    })

    const {data, isInitialLoading} = useQuery({
        queryKey: ['user', {userId: +cabinetId, query}],
        queryFn: () => axios.get(`/users/${cabinetId}?${query}`),
        enabled: !!cabinetId
    })

    const user = data?.data
    const isLoading = isInitialLoading && !user


    return {user, isLoading}

}

export default useVirtualUser;