import {useQuery} from '@tanstack/react-query';
import {useGraphQL} from './useGraphQL';
import {
    GET_MY_INVESTMENTS_COUNT
} from '../graphql/MyInvestment/GET_MY_INVESTMENTS_COUNT';

const useMyInvestmentsCount = userId => {

    const graphQLClient = useGraphQL()

    const {data} = useQuery({
        queryKey: ['myInvestmentsCount', {user: userId}],
        queryFn: () => graphQLClient.request(GET_MY_INVESTMENTS_COUNT, {
            user: userId
        }).then(res => res?.myInvestments)
    })

    return data?.meta?.pagination?.total

}

export default useMyInvestmentsCount