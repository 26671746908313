import React, {useState} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import Spinner from '../../Elements/Loaders/Spinner';
import useUserId from '../../../hook/useUserId';
import './btn.css';
import {Button, Modal} from 'react-bootstrap';
import useMyInvestmentsCount from '../../../hook/useMyInvestmentsCount';

function ChangeButton({sum, isDeadline}) {

    const RATE = 1

    const queryClient = useQueryClient()
    const userId = useUserId(true)

    const myInvestmentsCount = useMyInvestmentsCount(userId)

    const [showModal, setShowModal] = useState(false)

    const {mutate, isLoading, isError, isSuccess, error, reset} = useMutation({
        mutationFn: () => axios.post('/coin-transaction/change').then(res => res?.data),
        onSuccess: data => {
            reset()
            handleClose()
            queryClient.invalidateQueries({queryKey: ['coinTransactionSum', {user: userId}]})
            queryClient.invalidateQueries({queryKey: ['transactionSum', 'success', userId]})
        }
    })

    const change = e => {
        if (isLoading) return
        mutate()
    }

    const errorMessage = error?.response?.data?.error?.message


    const handleClose = () => {
        setShowModal(false)
        reset()
    }
    const handleShow = () => setShowModal(true)


    return (
        <>
            <button
                className="btn btn-sm btn-primary fs-6"
                onClick={handleShow}
                disabled={!isDeadline}
            >
                Обменять
            </button>

            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Обмен CoscoCoin</Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex flex-column gap-3">

                    <h3>Отдаете: {sum} СС</h3>
                    <h3>Курс CC/USD: {RATE}</h3>
                    <h3>Получаете: {Math.round(sum * RATE * 100) / 100} USD</h3>

                    {
                        isError &&
                        <div className="text-danger">{errorMessage}</div>
                    }

                    {
                        myInvestmentsCount === 0 &&
                        <div className="text-danger">Вам необходимо стать инвестором компании</div>
                    }

                </Modal.Body>

                <Modal.Footer>

                <Button variant="primary"
                            className="me-2"
                            onClick={change}
                            disabled={isLoading || !myInvestmentsCount}
                    >
                        {isLoading && <Spinner className="me-1"/>}
                        {isSuccess && <i className="ai-check-alt me-1"/>}
                        Обменять
                    </Button>

                    <Button variant="outline-dark" onClick={handleClose}>
                        Отмена
                    </Button>

                </Modal.Footer>

            </Modal>


        </>
    );
}

export default React.memo(ChangeButton)
