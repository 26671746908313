import React from 'react';
import {Outlet, Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../../hook/useAuth";
import SidebarRightBody from "./SidebarRight/SidebarRightBody";


const Cabinet = () => {

    const location = useLocation()
    const {user} = useAuth()


    if (!user) return <Navigate
        to="/sign-in"
        state={{fromPage: location?.pathname + location?.search}}
        replace={true}
    />
    // todo: Может тут не нужна навигация


    return <>
        <div className="container py-5 mt-4 mt-lg-5 mb-lg-4 my-xl-5">

            <div className="row pt-sm-2 pt-lg-0">


                <aside className="col-lg-3 pe-lg-4 pe-xl-5 mt-n3">
                    <div className="position-lg-sticky top-0">

                        <div className="d-none d-lg-block" style={{paddingTop: '45px'}}/>

                        <div className="d-none d-lg-block">
                            <SidebarRightBody/>
                        </div>

                    </div>
                </aside>


                <div className="col-lg-9 pt-4 pb-2 pb-sm-4">

                    <section className="card border-0 py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">
                        <div className="card-body">


                            <Outlet/>

                        </div>
                    </section>


                </div>

            </div>


            {/* Sidebar toggle button*/}
            {/*<button className="d-lg-none btn btn-sm fs-sm btn-primary w-100 rounded-0 fixed-bottom"*/}
            {/*        data-bs-toggle="offcanvas" data-bs-target="#sidebarRight">*/}
            {/*    <i className="ai-menu me-2"/>Account menu*/}
            {/*</button>*/}

        </div>
    </>

}

export default React.memo(Cabinet);