import React, {useEffect} from 'react';
import Select from "react-select";
import {useQuery} from "@tanstack/react-query";
import {useGraphQL} from "../../../hook/useGraphQL";
import {GET_MY_CONTAINER_STATUSES} from "../../../graphql/MyContainerStatus/GET_MY_CONTAINER_STATUSES";

const SelectMyContainerStatus = ({value, onChange, ...config}) => {

    console.log('myContainerStatus', value)

    const graphQLClient = useGraphQL()

    const {data: myContainerStatuses} = useQuery({
        queryKey: ['myContainerStatuses'],
        queryFn: () => graphQLClient.request(GET_MY_CONTAINER_STATUSES).then(res => res?.myContainerStatuses)
    })


    const options = myContainerStatuses?.data?.map(item => {
        return {
            value: item?.id,
            label: item?.attributes?.name
        }
    })


    useEffect(() => {
        if (value && typeof value !== 'object') {
            const selectedOption = options?.find(option => option?.value === value)
            if (selectedOption) onChange(selectedOption)
        }
    }, [options, value])


    if (!myContainerStatuses) return null


    return (
        <Select
            isClearable
            isSearchable={false}
            classNamePrefix="react-select"
            options={options}
            placeholder="Статус контейнера"
            value={value}
            onChange={onChange}
            {...config}
        />
    )
}

export default React.memo(SelectMyContainerStatus);