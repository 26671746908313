import React from 'react';

const CoverImage = () => {
    return (
        <div className="w-50 bg-size-cover bg-repeat-0 bg-position-center"
             style={{backgroundImage: 'url(/img/cover.jpg)'}}>
        </div>
    )
}

export default React.memo(CoverImage);