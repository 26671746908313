export const math = {
    round: function (arg, r) {
        const pow = Math.pow(10, r || 0);
        return Math.round(arg * pow) / pow;
    },
    floor: function (arg, r) {
        const pow = Math.pow(10, r || 0);
        return Math.floor(arg * pow) / pow;
    },
    ceil: function (arg, r) {
        const pow = Math.pow(10, r || 0);
        return Math.ceil(arg * pow) / pow;
    }
}