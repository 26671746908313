import React, {useRef, useState, useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import {useQuery} from "@tanstack/react-query";
import {useGraphQL} from "../../../../hook/useGraphQL";
import Pagination from "../../../Elements/Pagination";
import Spinner from "../../../Elements/Loaders/Spinner";
import {GET_USERS} from "../../../../graphql/User/GET_USERS";
import moment from "moment";
import {Link} from "react-router-dom";
import Select from 'react-select';
import UserBalance from './UserBalance';
import UserCoins from './UserCoins';



const TYPING_DELAY = 300
const MIN_CHARACTERS = 2
const PAGE_SIZE = 10


const Users = () => {

    const graphQLClient = useGraphQL()
    const timer = useRef(null)

    const [page, setPage] = useState(1)
    const [findString, setFindString] = useState('')
    const [find, setFind] = useState()
    const [verified, setVerified] = useState()

    const query = {
        findUpper: find ? firstLetterUpperCase(find) : undefined,
        findLower: find ? find.toLowerCase() : undefined,
        verified: verified?.value,
        page,
        pageSize: PAGE_SIZE,
    }


    const {data, isLoading} = useQuery({
        queryKey: ['users', GET_USERS, query],
        queryFn: () => graphQLClient.request(GET_USERS, query),
    })


    useEffect(() => {

        clearTimeout(timer.current)

        timer.current = setTimeout(() => {

            if (findString && findString?.length < MIN_CHARACTERS) return

            setFind(findString)

        }, TYPING_DELAY)

        return () => clearTimeout(timer)

    }, [findString])



    const users = data?.usersPermissionsUsers?.data
    const pagination = data?.usersPermissionsUsers?.meta?.pagination


    return <>

        <div className="d-flex mb-3 gap-3">

            <span className="fs-4">Юзеры ({pagination?.total})</span>

            <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Поиск по ФИО"
                style={{width: "150px"}}
                value={findString}
                onChange={e => setFindString(e.target.value)}
            />
            <button
                className="btn btn-outline-dark btn-sm"
                onClick={e => setFindString('')}
            >
                <i className="ai-cross-alt"></i>
            </button>


            <Select
                options={[
                    {value: true, label: 'Верифицированные'},
                    {value: false, label: 'Неверифицированные'},
                ]}
                onChange={setVerified}
                value={verified}
                isClearable={true}
                isSearchable={false}
            />





            {isLoading && <Spinner size="2rem" className="ms-auto"/>}

        </div>

        <div className="table-responsive">
            <Table striped bordered hover className="align-middle">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>ФИО</th>
                    <th>Баланс</th>
                    <th>Coins</th>
                    <th>Дата рег.</th>
                </tr>
                </thead>
                <tbody>
                {
                    users?.map(user => {

                        const {name, surname, email, createdAt, verified, document} = user?.attributes || {}

                        return <tr key={user?.id}>
                            <td>{user?.id}</td>
                            <td>
                                <Link to={`/cabinet/${user?.id}/profile`}>
                                    {name} {surname}
                                    {
                                        verified &&
                                        <i className="ai-circle-check-filled text-success ms-2"
                                           title="Верифицирован"/>
                                    }
                                    {
                                        document?.data?.id &&
                                        <i className="ai-card text-success ms-2"
                                           title="Документ загружен"/>
                                    }
                                </Link>
                            </td>
                            <td>
                                <UserBalance userId={user?.id}/>
                            </td>
                            <td>
                                <UserCoins userId={user?.id}/>
                            </td>
                            <td>{createdAt ? moment(createdAt).format("DD.MM.YYYY") : null}</td>
                        </tr>
                    })
                }

                </tbody>
            </Table>
        </div>

        <Pagination
            page={page}
            setPage={setPage}
            pageCount={pagination?.pageCount}
        />

    </>
}



const firstLetterUpperCase = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()





export default React.memo(Users);