import React from 'react';
import axios from "axios";
import useUser from "../../../hook/useUser";
import useIsMyCabinet from "../../../hook/useIsMyCabinet";
import {useQueryClient} from "@tanstack/react-query";
import useUserAvatarUrl from "../../../hook/useUserAvatarUrl";


const ProfileAvatar = () => {

    const user = useUser()
    const isMyCabinet = useIsMyCabinet()
    const queryClient = useQueryClient()
    const avatarUrl = useUserAvatarUrl()


    const delAvatarHandler = () => {
        if (!window.confirm('Удалить аватар')) return
        if (!user?.avatar?.id) return
        delAvatar(user?.avatar?.id).then(function () {
            if (isMyCabinet) {
                queryClient.invalidateQueries({queryKey: ['me']})
            } else {
                queryClient.invalidateQueries({queryKey: ['user']})
            }
        })
    }


    const delAvatar = fileId => axios.delete(`/upload/files/${fileId}`)


    const onLoadAvatar = e => {

        const fileList = e.target.files
        const file = fileList[0]

        const formData = new FormData()
        formData.append('files', file, file?.name)
        formData.append('ref', 'plugin::users-permissions.user')
        formData.append('refId', user?.id)
        formData.append('field', 'avatar')

        axios.post(`/upload`, formData).then(function () {

            if (user?.avatar?.id) {
                delAvatar(user?.avatar?.id)
            }

            if (isMyCabinet) {
                queryClient.invalidateQueries({queryKey: ['me']})
            } else {
                queryClient.invalidateQueries({queryKey: ['user']})
            }

        })

    }


    return (
        <div className="d-flex align-items-center">
            <div className="dropdown">
                <a
                    className="d-flex flex-column justify-content-end position-relative overflow-hidden rounded-circle bg-size-cover bg-position-center flex-shrink-0"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                        width: 80,
                        height: 80,
                        backgroundImage: `url(${avatarUrl})`
                    }}
                >
                    <span
                        className="d-block text-light text-center lh-1 pb-1"
                        style={{backgroundColor: "rgba(0,0,0,.5)"}}
                    >
                      <i className="ai-camera"/>
                    </span>
                </a>
                <div className="dropdown-menu my-1">
                    <span className="dropdown-item fw-normal a">
                        <label className="a">
                            <input type="file" accept="image/*" className="d-none" onChange={onLoadAvatar}/>
                            <i className="ai-camera fs-base opacity-70 me-2"/>
                            Загрузить новое фото
                        </label>
                    </span>
                    {
                        user?.avatar?.id &&
                        <span className="dropdown-item text-danger fw-normal a" onClick={delAvatarHandler}>
                            <i className="ai-trash fs-base me-2"/>
                            Удалить фото
                        </span>
                    }
                </div>
            </div>
            <div className="ps-3">
                <h3 className="h6 mb-1">Картинка профиля</h3>
                <p className="fs-sm text-muted mb-0">
                    Загрузите изображение размером не более 10Мб
                </p>
            </div>
        </div>
    )
}

export default React.memo(ProfileAvatar);