import {useAuth} from "./useAuth";
import {useParams} from "react-router-dom";
import useIsAdmin from "./useIsAdmin";

const useUserId = (currentUserByDefault: boolean) => {

    const {cabinetId} = useParams()
    const {user} = useAuth()
    const isAdmin = useIsAdmin()


    return isAdmin ?
        (cabinetId || (currentUserByDefault ? user?.id : undefined)) :
        user?.id

}

export default useUserId;