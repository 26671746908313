import {gql} from "graphql-request";

export const GET_MY_INVESTMENTS_COUNT = gql`

    query GetMyInvestmentsCount(
        $user: ID
    ) {
        myInvestments(
            filters: {user: {id: {eq: $user}}}
            pagination: {
                page: 1
                pageSize: 1
            }
        ) {
            meta {
                pagination {
                    total
                }
            }
        }
    }
`