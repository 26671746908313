import {gql} from "graphql-request";

export const GET_USER_REFERRALS_LEVEL_1 = gql`
    
    query GetUserReferrals(
        
        $user: ID!
        $verified: Boolean
        
        $sort: [String] = "id:desc"
        $page: Int = 1
        $pageSize: Int = 10
#        $limit: Int = 3
#        $start: Int = 0
        
    ) {
        referrals: usersPermissionsUsers(
            
            filters: {
                referrer: {id: {eq: $user}}
                verified: {eq: $verified}
            }
            
            sort: $sort

            pagination: {
#                limit: $limit
#                start: $start
                page: $page
                pageSize: $pageSize
            }
            
        ) {
            data {
                id
                attributes {
                    name
                    surname
                    country
                    city
                    createdAt
                    verified
                }
            }
            meta {
                pagination {
                    page
                    pageSize
                    pageCount
                    total
#                    start
#                    limit
                }
            }
        }
    }
`