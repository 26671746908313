import React, {useEffect} from 'react';
import Select from "react-select";
import {useQuery} from "@tanstack/react-query";
import {useGraphQL} from "../../../hook/useGraphQL";
import {GET_TRANSACTION_STATUSES} from "../../../graphql/TransactionStatus/GET_TRANSACTION_STATUSES";

const SelectTransactionStatus = ({value, onChange, ...config}) => {

    const graphQLClient = useGraphQL()

    const {data} = useQuery({
        queryKey: ['transactionStatuses'],
        queryFn: () => graphQLClient.request(GET_TRANSACTION_STATUSES)
    })


    const options = data?.transactionStatuses?.data?.map(item => {
        return {
            value: item?.id,
            label: item?.attributes?.name
        }
    })


    useEffect(() => {
        if (value && typeof value !== 'object') {
            const selectedOption = options?.find(option => option?.value === value)
            if (selectedOption) onChange(selectedOption)
        }
    }, [options, value])


    if (!data) return null


    return (
        <Select
            isClearable
            isSearchable={false}
            classNamePrefix="react-select"
            options={options}
            placeholder="Статус транзакции"
            value={value}
            onChange={onChange}
            {...config}
        />
    )
}

export default React.memo(SelectTransactionStatus);