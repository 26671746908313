import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {useQuery} from "@tanstack/react-query";
import DataLoader from "../Elements/Loaders/DataLoader";
import {useGraphQL} from "../../hook/useGraphQL";
import {GET_STATISTIC} from "../../graphql/Statistic/GET_STATISTIC";
import moment from "moment";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


function GraphLine() {

    const graphQLClient = useGraphQL()

    const {data: statistics, isLoading} = useQuery({
        queryKey: ['statistic'],
        queryFn: () => graphQLClient.request(GET_STATISTIC, {}).then(res => res?.statistics?.data)
    })



    if (isLoading) return <DataLoader/>

    const xArr = statistics?.map(statistic => moment(statistic?.attributes?.date).format('MMMM YYYY'))
    const yArrInvestments = statistics?.map(statistic => statistic?.attributes?.investments)
    const yArrContainers = statistics?.map(statistic => statistic?.attributes?.containers)



    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top'
            },
            // title: {
            //     display: true,
            //     text: 'Статистика покупок'
            // }
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                suggestedMin: 0
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                suggestedMin: 0,
                suggestedMax: 100
            }
        },
        tension: 0.4
    }

    const data = {
        // labels: labels,
        labels: xArr,
        // datasets: newSets,
        datasets: [
            {
                label: 'Инвестиционные пакеты',
                data: yArrInvestments,
                borderColor: 'rgb(99,154,255)',
                backgroundColor: 'rgba(99,161,255,0.5)',
                yAxisID: 'y'
            },
            {
                label: 'Контейнеры',
                data: yArrContainers,
                borderColor: 'rgb(17,154,114)',
                backgroundColor: 'rgba(51,114,91,0.49)',
                yAxisID: 'y'
            },
        ]
    }

    return (
        <div className="d-flex justify-content-center">
            <Line options={options} data={data} style={{width: '100%', height: '400px'}}/>
        </div>
    )
}

export default React.memo(GraphLine);