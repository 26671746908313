import {gql} from "graphql-request";

export const GET_COORDINATES = gql`

    query GetCoordinates(
        
        $type: String
        
        $sort: [String] = "id:asc"
        $page: Int = 1
        $pageSize: Int = 100
        
    ) {
        coordinates(
            
            filters: {type: {eq: $type}}
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    name
                    latitude
                    longitude
#                    type
                }
            }
        }
    }
`