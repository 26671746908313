import React from 'react';
import axios from "axios";
import {API, BEARER} from "../config";
import {useAuth} from "../hook/useAuth";

const AxiosDefaults = () => {

    const {token} = useAuth()


    axios.defaults.baseURL = API

    if (token) {
        axios.defaults.headers.common['Authorization'] = `${BEARER} ${token}`
    } else {
        delete axios.defaults.headers.common['Authorization']
    }

    return null
}

export default React.memo(AxiosDefaults);