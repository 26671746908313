import React from 'react';
import axios from "axios";
import useUser from "../../../hook/useUser";
import useIsMyCabinet from "../../../hook/useIsMyCabinet";
import {useQueryClient} from "@tanstack/react-query";
import {BACKEND_URL} from "../../../config";
import IsAdmin from "../../../hoc/IsAdmin";

const ProfileDocument = () => {

    const user = useUser()
    const isMyCabinet = useIsMyCabinet()
    const queryClient = useQueryClient()


    const userDocumentUrl = user?.document?.formats?.thumbnail?.url
    const userDocumentFull = user?.document?.url
    const documentUrl = userDocumentUrl ?
        `${BACKEND_URL}${userDocumentUrl}` :
        '/img/passport.png'
    const documentFull = userDocumentFull ?
        `${BACKEND_URL}${userDocumentFull}` :
        '/img/passport.png'


    const delDocumentHandler = () => {
        if (!window.confirm('Удалить документ')) return
        if (!user?.document?.id) return
        delDocument(user?.document?.id).then(function () {
            if (isMyCabinet) {
                queryClient.invalidateQueries({queryKey: ['me']})
            } else {
                queryClient.invalidateQueries({queryKey: ['user']})
            }
        })
    }


    const delDocument = fileId => axios.delete(`/upload/files/${fileId}`)


    const onLoadDocument = e => {

        const fileList = e.target.files
        const file = fileList[0]

        const formData = new FormData()
        formData.append('files', file, file?.name)
        formData.append('ref', 'plugin::users-permissions.user')
        formData.append('refId', user?.id)
        formData.append('field', 'document')

        axios.post(`/upload`, formData).then(function () {

            if (user?.document?.id) {
                delDocument(user?.document?.id)
            }

            if (isMyCabinet) {
                queryClient.invalidateQueries({queryKey: ['me']})
            } else {
                queryClient.invalidateQueries({queryKey: ['user']})
            }
        })

    }


    return (
        <div style={{maxWidth: 212}}>
            <label className="w-100 border rounded-3 p-4 a d-block">
                <h4 className="h5 lh-base text-center">Прикрепить фото документа</h4>
                <p className="fs-sm text-muted">1-я страница паспорта</p>
                <img
                    className="d-block mb-2 mx-auto rounded-1"
                    src={documentUrl}
                    width={64}
                    alt="Passport"
                />
                <input type="file" accept="image/*" className="d-none" onChange={onLoadDocument}/>
            </label>
            <IsAdmin>
                {
                    user?.document?.id &&
                    <>
                        <a className="btn btn-outline-info btn-sm w-100 mt-2"
                           href={documentFull}
                           target="_blank"
                           rel="noreferrer"
                        >
                            Открыть
                        </a>
                        <button className="btn btn-outline-danger btn-sm w-100 mt-2" onClick={delDocumentHandler}>
                            Удалить
                        </button>
                    </>
                }
            </IsAdmin>
        </div>
    )
}

export default React.memo(ProfileDocument);