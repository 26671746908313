import {gql} from 'graphql-request';

export const GET_SHOW_COIN = gql`
query {
  showCoin {
    data {
      attributes {
        showToAdmins
        showToUsers
      }
    }
  }
}
`