import {useAuth} from "./useAuth";
import {useParams} from "react-router-dom";
import useVirtualUser from "./useVirtualUser";

const useUser = () => {
    const {user: userAuth} = useAuth()
    const {user: userVirtual} = useVirtualUser()
    const {cabinetId} = useParams()
    return cabinetId ? userVirtual : userAuth
}

export default useUser;