import {useQuery} from "@tanstack/react-query";
import {useGraphQL} from "./useGraphQL";
import {GET_COORDINATES} from "../graphql/Coordinate/GET_COORDINATES";


type MyType = 'port' | 'waypoint';

const useCoordinates = (type: MyType) => {

    const graphQLClient = useGraphQL()

    const {data: portCoordinates, isLoading} = useQuery({
        queryKey: ['portCoordinates'],
        queryFn: () => graphQLClient.request(GET_COORDINATES, {type}).then(res => res?.coordinates)
    })


    return {
        portCoordinates,
        isLoading
    }
}


export default useCoordinates;