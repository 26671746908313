import React, {useEffect} from 'react';
import Select from "react-select";
import {useQuery} from "@tanstack/react-query";
import {useGraphQL} from "../../../hook/useGraphQL";
import {GET_DEPOSIT_TYPES} from "../../../graphql/DepositType/GET_DEPOSIT_TYPES";

const SelectDepositType = ({value, onChange, ...config}) => {

    const graphQLClient = useGraphQL()

    const {data} = useQuery({
        queryKey: ['depositTypes'],
        queryFn: () => graphQLClient.request(GET_DEPOSIT_TYPES, {draft: false})
    })


    const options = data?.depositTypes?.data?.map(item => {

        return {
            value: item?.id,
            label: item?.attributes?.name,
            rate: item?.attributes?.rate,
            precision: item?.attributes?.precision,
            currency: item?.attributes?.currency,
            requisite: item?.attributes?.requisite,
            qrcode: item?.attributes?.qrcode,
            logoLight: item?.attributes?.logoLight?.data?.attributes?.url,
            logoDark: item?.attributes?.logoDark?.data?.attributes?.url,
        }
    })


    useEffect(() => {
        if (value && typeof value !== 'object') {
            const selectedOption = options?.find(option => option?.value === value)
            if (selectedOption) onChange(selectedOption)
        }
    }, [options, value])


    if (!data) return null


    return (
        <Select
            isClearable
            isSearchable={false}
            classNamePrefix="react-select"
            options={options}
            placeholder="Платежный метод"
            value={value}
            onChange={onChange}
            {...config}
        />
    )
}

export default React.memo(SelectDepositType);