import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import Spinner from "../../Elements/Loaders/Spinner";
import useUserId from "../../../hook/useUserId";
import {BACKEND_URL} from "../../../config";
import useBalance from "../../../hook/useBalance";


const ModalBuyInvestment = ({button, investment}) => {

    const imgUrl = BACKEND_URL + investment?.attributes?.img?.data?.attributes?.formats?.thumbnail?.url
    const {name, price} = investment?.attributes || {}

    const queryClient = useQueryClient()
    const userId = useUserId(true)
    const {balance} = useBalance()

    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState(false)


    const {mutate, isLoading, isSuccess, reset, isError, error: serverError} = useMutation({
        mutationFn: variables => axios.post('my-investments', variables),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['transactionSum', 'success', userId]})
            queryClient.invalidateQueries({queryKey: ['myInvestments', {user: userId}]})
        }
    })


    const buyInvestment = () => {

        if (!Number.isFinite(balance)) {
            setError('Ошибка баланса')
            return
        }

        if (price > balance) {
            setError('Недостаточно денег для совершения покупки. Ваш баланс ' + balance + '$')
            return
        }


        mutate({
            data: {
                investment: investment?.id,
                user: userId,
                status: 1
            }
        })

    }


    const handleClose = () => {
        reset()
        setError('')
        setShowModal(false)
    }

    const handleShow = () => setShowModal(true)




    return (

        <>

            {React.cloneElement(button, {onClick: handleShow})}


            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Инвестирование</Modal.Title>
                </Modal.Header>


                <Modal.Body>

                    {
                        isSuccess &&
                        <>
                            <h3 className="text-center">
                                Вы успешно инвестировали!
                            </h3>
                            <h5 className="text-center mb-0">
                                Вам будут начисляться проценты согласно вашего пакета инвестиций
                            </h5>
                        </>
                    }

                    {
                        !isSuccess && <>

                            <div className="d-flex gap-4 justify-content-between">

                                <img className="w-25 rounded-1" src={imgUrl} alt="" style={{objectFit: 'contain'}}/>

                                <div>
                                    <span className="fs-sm">Название</span>
                                    <h5 className="m-0">{name}</h5>
                                </div>

                                <div>
                                    <span className="fs-sm">Цена</span>
                                    <h4 className="m-0">${price}</h4>
                                </div>

                            </div>


                            {
                                error &&
                                <div className="alert d-flex alert-warning mt-3 mb-0" role="alert">
                                    <i className="ai-triangle-alert fs-xl pe-1 me-2"/>
                                    <div>{error}</div>
                                </div>
                            }


                            {
                                isError && serverError?.response?.data?.error?.message &&
                                <div className="alert d-flex alert-danger mt-3 mb-0" role="alert">
                                    <i className="ai-triangle-alert fs-xl pe-1 me-2"/>
                                    <div>
                                        {serverError?.response?.data?.error?.message}
                                    </div>
                                </div>
                            }

                        </>

                    }


                </Modal.Body>


                <Modal.Footer>
                    {
                        !isSuccess ?
                            <Button variant="primary" className="w-100" onClick={buyInvestment}>
                                {isLoading && <Spinner/>}
                                Подтвердить
                            </Button> :
                            <Button variant="primary" className="w-100" onClick={handleClose}>
                                Закрыть
                            </Button>
                    }
                </Modal.Footer>

            </Modal>
        </>


    )
}


export default React.memo(ModalBuyInvestment)
