import {gql} from "graphql-request";

export const GET_TRANSACTIONS = gql`
    
    query GetTransactions(
        
        $user: ID
        $type: ID
        $status: ID
        $fromDate: DateTime
        $toDate: DateTime
        
        $sort: [String] = "datetime:desc"
        $page: Int = 1
        $pageSize: Int = 15
        
    ) {
        transactions(

            filters: {
                user: {id: {eq: $user}}
                type: {id: {eq: $type}}
                status: {id: {eq: $status}}
                datetime: {gte: $fromDate, lt: $toDate}
            }
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    datetime
                    amount
                    comment
                    depositAmount
                    depositRate
                    depositName
                    depositCurrency
                    withdrawalAmount
                    withdrawalRate
                    withdrawalName
                    withdrawalCurrency
                    withdrawalRequisite
                    type {
                        data {
                            id
                            attributes {
                                name
                                clarification
                            }
                        }
                    }
                    status {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    depositType {
                        data {
                            id
                            attributes {
                                name
                                currency
                                rate
                                precision
                                requisite
                                qrcode
                            }
                        }
                    }
                    withdrawalType {
                        data {
                            id
                            attributes {
                                name
                                currency
                                rate
                                precision
                            }
                        }
                    }
                    refTransactions {
                        data {
                            id
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`