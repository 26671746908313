import {gql} from "graphql-request";

export const GET_USER_REFERRALS_LEVEL_2 = gql`

    query GetUserReferrals(

        $user: ID!

        $sort: [String] = "id:desc"
        $page: Int = 1
        $pageSize: Int = 10

    ) {
        referrals: usersPermissionsUsers(

            filters: {
                referrer: {referrer: {id: {eq: $user}}}
            }

            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    name
                    surname
                    createdAt
                }
            }
            meta {
                pagination {
                    page
                    pageSize
                    pageCount
                    total
                }
            }
        }
    }
`