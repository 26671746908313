import React, {useState} from 'react';
import Table from 'react-bootstrap/Table';
import {useQuery} from "@tanstack/react-query";
import {useGraphQL} from "../../../../hook/useGraphQL";
import {GET_TRANSACTIONS_ADMIN} from "../../../../graphql/Transaction/GET_TRANSACTIONS_ADMIN";
import {TRANSACTION_ID_OFFSET, USER_ID_OFFSET} from "../../../../config";
import moment from "moment";
import Pagination from "../../../Elements/Pagination";
import {transactionStatusBadge} from "../../../../utilities/functions";
import SelectTransactionType from "../../../Input/Select/SelectTransactionType";
import Spinner from "../../../Elements/Loaders/Spinner";
import SelectTransactionStatus from "../../../Input/Select/SelectTransactionStatus";
import ModalEditTransaction from "./ModalEditTransaction";
import useUserId from "../../../../hook/useUserId";

const Transactions = () => {

    const graphQLClient = useGraphQL()

    const userId = useUserId()

    const [transactionType, setTransactionType] = useState(null)
    const [transactionStatus, setTransactionStatus] = useState(null)
    const [page, setPage] = useState(1)

    // const [transactionForEditing, setTransactionForEditing] = useState()


    const query = {
        type: +transactionType?.value || undefined,
        status: +transactionStatus?.value || undefined,
        user: userId,
        page,
    }


    const {data, isLoading} = useQuery({
        queryKey: ['transactions', GET_TRANSACTIONS_ADMIN, query],
        queryFn: () => graphQLClient.request(GET_TRANSACTIONS_ADMIN, query)
    })


    return <>
        {/*<div className="card border-0 py-1 p-md-2 p-xl-3 p-xxl-4">*/}
        {/*    <div className="card-body pb-4 p-3">*/}


        <div className="mb-3 d-flex align-items-center gap-2">

            <SelectTransactionType
                value={transactionType}
                onChange={setTransactionType}
            />

            <SelectTransactionStatus
                value={transactionStatus}
                onChange={setTransactionStatus}
            />

            {isLoading && <span className="ms-auto"><Spinner size="2rem"/></span>}

        </div>


        <Table striped bordered hover responsive size="sm" className="align-middle">
            <thead>
            <tr>
                <th>ID</th>
                <th>Клиент</th>
                <th>Сумма</th>
                <th>Дата</th>
                <th>Комментарий</th>
                <th>Тип</th>
                <th>Статус</th>
                <th><i className="ai-edit fs-4"/></th>
            </tr>
            </thead>
            <tbody>

            {
                data?.transactions?.data?.map(transaction => {

                    const transactionId = TRANSACTION_ID_OFFSET + +transaction?.id
                    const clientId = USER_ID_OFFSET + +transaction?.attributes?.user?.data?.id
                    const clientName = transaction?.attributes?.user?.data?.attributes?.name
                    const amount = transaction?.attributes?.amount
                    const datetime = transaction?.attributes?.datetime
                    const datetimePretty = datetime ? moment(datetime).format("DD.MM.YYYY HH:mm:ss") : null
                    const comment = transaction?.attributes?.comment
                    const withdrawalRequisite = transaction?.attributes?.withdrawalRequisite
                    const type = transaction?.attributes?.type?.data?.attributes?.name
                    const typeClarification = transaction?.attributes?.type?.data?.attributes?.clarification

                    const status = transactionStatusBadge(transaction?.attributes?.status)


                    return <tr key={transaction?.id}>
                        <td>{transactionId}</td>
                        <td>{clientId}, {clientName}</td>
                        <td>{amount}</td>
                        <td>{datetimePretty}</td>
                        <td>
                            {comment}
                            <div>{withdrawalRequisite}</div>
                        </td>
                        <td>{type} {typeClarification}</td>
                        <td>{status}</td>
                        <td>
                            <ModalEditTransaction
                                transaction={transaction}
                            />
                        </td>
                    </tr>
                })
            }

            </tbody>
        </Table>


        <Pagination
            page={page}
            setPage={setPage}
            pageCount={data?.transactions?.meta?.pagination?.pageCount}
        />


        {/*</div>*/}
        {/*</div>*/}
    </>
}

export default React.memo(Transactions);