import React from 'react';
import Transactions from "./Transactions";
import Stat from "./Stat";

const Balance = () => {
    return <>
        {/*<div className="card border-0 py-1 p-md-2 p-xl-3 p-xxl-4">*/}
        {/*    <div className="card-body pb-4 p-3">*/}
                <Stat/>
                <Transactions/>
        {/*    </div>*/}
        {/*</div>*/}
    </>
}

export default React.memo(Balance);