import React from 'react';
import {Link} from "react-router-dom";

const Logo = () => {
    return (
        <Link className="navbar-brand" to="/">
            <span className="text-primary flex-shrink-0 me-2">
                <img src="/logo-ship-dark.png" className="d-dark-mode-none" alt="logo" width="50px"/>
                <img src="/logo-ship-white.png" className="d-none d-dark-mode-block" alt="logo" width="50px"/>
            </span>
            <span className="logo-name mb-n2" style={{textDecorationThickness: "2.5px"}}>COSCOLINE</span>
        </Link>
    )
}

export default React.memo(Logo);