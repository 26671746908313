import React, {useState} from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard/src";

function CopyBtn({text, className}) {

    const [copied, setCopied] = useState()

    return (
        <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
            <span className={className}>
                {
                    copied ?
                        <i className="ai-checks"/> :
                        <i className="ai-copy"/>
                }
            </span>
        </CopyToClipboard>
    )
}

export default React.memo(CopyBtn);