import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {math} from "../utilities/math";
import useUserId from "./useUserId";


const useBalance = (user) => {

    const userId = useUserId(true)

    const newUserId = user || userId

    const {
        data: success,
        isLoading: isLoadingSuccess,
        isSuccess: isSuccessSuccess
    } = useQuery({
        queryKey: ['transactionSum', 'success', newUserId],
        queryFn: () =>
            axios.post('/transaction/sum', {
                user: newUserId,
                status: 1
            }).then(res => math.round(res?.data, 2))
    })


    const {
        data: outgoingInProgress,
        isLoading: isLoadingOutgoingInProgress,
        isSuccess: isSuccessOutgoingInProgress
    } = useQuery({
        queryKey: ['transactionSum', 'outgoingInProgress', newUserId],
        queryFn: () =>
            axios.post('/transaction/sum', {
                user: newUserId,
                type: [2, 7, 8, 9],
                status: 3
            }).then(res => math.round(res?.data, 2))
    })



    return {
        balance: math.round(success + outgoingInProgress, 2),
        isLoading: isLoadingSuccess || isLoadingOutgoingInProgress,
        isSuccess: isSuccessSuccess && isSuccessOutgoingInProgress
    }
}


export default useBalance;