import React from 'react';

function Level({level, partners, coins, className}) {
    return (
        <div className={`col-auto border rounded-3 d-flex p-3 gap-3 fw-bold ${className}`}>
            <div className="text-center align-self-center">
                <div className="fs-3 text-nav">{level}-й</div>
                <div className="text-muted">уровень</div>
            </div>
            <div className="text-nowrap align-self-center">
                <div className="mb-1 text-muted">
                    {partners} рефералов
                </div>
                <div className="text-nav">
                    {coins} монеты за клик
                </div>
            </div>
        </div>
    )
}

export default Level;