import {gql} from "graphql-request";

export const GET_DEPOSIT_TYPES = gql`

    query GetDepositTypes(
        
        $draft: Boolean
        
        $sort: [String] = "name:asc"
        $page: Int = 1
        $pageSize: Int = 100
        
    ) {
        depositTypes(

            filters: {
                draft: {eq: $draft}
            }
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    name
                    currency
                    rate
                    precision
                    requisite
                    qrcode
                    logoLight {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    logoDark {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`