import React, {useEffect, useState} from 'react';
import moment from 'moment/moment';


function Timer({setIsDeadline}) {


    const DEADLINE = "2024-05-31T12:00:00+03:00"
    // const DEADLINE = "2024-05-28T12:17:00+02:00"

    const targetDate = new Date(DEADLINE)
    const now = new Date()
    const differenceMs = targetDate - now
    const differenceSec = Math.floor(differenceMs / 1000)


    const [count, setCount] = useState(differenceSec)


    useEffect(() => {

        if (count <= 0) {
            setIsDeadline(true)
            return
        }

        const timerId = setTimeout(() => {

            const now = new Date()
            const differenceMs = targetDate - now
            const differenceSec = Math.floor(differenceMs / 1000)
            
            setCount(differenceSec)

        }, 1000)

        return () => clearTimeout(timerId)

    }, [count])


    const time = formatTime(count)
    const date = moment(targetDate).format('DD.MM.YYYY HH:mm')

    return <div>
        <h4 className="mb-0">{time}</h4>
        <h6 className="text-muted">{date}</h6>
    </div>

}




function formatTime(seconds) {
    const duration = moment.duration(seconds, 'seconds')

    if (seconds <= 0) {
        return "0ч 0м 0с"
    }

    let hours = Math.floor(duration.asHours())
    let minutes = duration.minutes()
    let secs = duration.seconds()

    return `${hours}ч ${minutes}м ${secs}с`
}



export default React.memo(Timer)