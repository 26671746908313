import {gql} from "graphql-request";

export const GET_NEWS = gql`

    query GetNews(
        
        $sort: [String] = "datetime:desc"
        $page: Int = 1
        $pageSize: Int = 10
        
    ) {
        news(
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    name
                    text
                    datetime
                    tag
                    img {
                        data {
                            attributes {
                                url
                                formats
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageSize
                    pageCount
                    total
                }
            }
        }
    }
`