import SmoothScroll from "smooth-scroll";
import Parallax from "parallax-js"
import AOS from "aos"


export const animateOnscroll = () => {

    let animationToggle = document.querySelector('[data-aos]');

    if (animationToggle === null) return;

    AOS.init();

}



export const parallax = () => {

    let element = document.querySelectorAll('.parallax');

    for (let i = 0; i < element.length; i++) {
        let parallaxInstance = new Parallax(element[i]);
    }

}



export const stickyNavbar = () => {

    let navbar = document.querySelector('.navbar.fixed-top');

    if (navbar == null) return;

    let navbarClass = navbar.classList,
        scrollOffset = 20;

    const navbarStuck = (e) => {
        if (e.currentTarget.pageYOffset > scrollOffset) {
            navbar.classList.add('navbar-stuck');
            if (navbar.classList.contains('navbar-ignore-dark-mode')) {
                navbar.classList.remove('ignore-dark-mode');
            }
        } else {
            navbar.classList.remove('navbar-stuck');
            if (navbar.classList.contains('navbar-ignore-dark-mode')) {
                navbar.classList.add('ignore-dark-mode');
            }
        }
    }

    // On load
    window.addEventListener('load', (e) => {
        navbarStuck(e);
    });

    // On scroll
    window.addEventListener('scroll', (e) => {
        navbarStuck(e);
    });

}


export const smoothScroll = () => {
    let selector = '[data-scroll]',
        fixedHeader = '[data-scroll-header]',
        scroll = new SmoothScroll(selector, {
            speed: 800,
            speedAsDuration: true,
            offset: (anchor, toggle) => {
                return toggle.dataset.scrollOffset || 20;
            },
            header: fixedHeader,
            updateURL: false
        })
}

export const scrollTopButton = () => {
    let button = document.querySelector('.btn-scroll-top'),
        scrollOffset = 450;

    if (button == null) return;

    let offsetFromTop = parseInt(scrollOffset, 10),
        progress = button.querySelector('svg circle'),
        length = progress.getTotalLength();

    progress.style.strokeDasharray = length;
    progress.style.strokeDashoffset = length;

    const showProgress = () => {
        let scrollPercent = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight),
            draw = length * scrollPercent;
        progress.style.strokeDashoffset = length - draw;
    }

    window.addEventListener('scroll', (e) => {
        if (e.currentTarget.pageYOffset > offsetFromTop) {
            button.classList.add('show');
        } else {
            button.classList.remove('show');
        }

        showProgress();
    });
}


export const themeModeSwitch = (element) => {

    let mode = window.localStorage.getItem('mode');
    let root = document.getElementsByTagName('html')[0];

    let modeSwitch = element || document.querySelector('[data-bs-toggle="mode"]');

    if (!modeSwitch) return;

    let checkbox = modeSwitch.querySelector('.form-check-input');

    if (mode === 'dark') {
        root.classList.add('dark-mode');
        checkbox.checked = true;
    } else {
        root.classList.remove('dark-mode');
        checkbox.checked = false;
    }

    modeSwitch.addEventListener('click', (e) => {
        if (checkbox.checked) {
            root.classList.add('dark-mode');
            window.localStorage.setItem('mode', 'dark');
        } else {
            root.classList.remove('dark-mode');
            window.localStorage.setItem('mode', 'light');
        }
    });

}