import React from 'react';
import {GraphQLClient} from 'graphql-request';
import {BEARER, GRAPHQL_ENDPOINT} from "../config";
import {GraphQLContext} from "../context/GraphQLContext";
import {useAuth} from "../hook/useAuth";



const GraphQLProvider = ({children}) => {

    const {token} = useAuth()

    const graphQLClient = new GraphQLClient(GRAPHQL_ENDPOINT, {
        headers: token ? {
            Authorization: `${BEARER} ${token}`
        } : {},
        errorPolicy: 'ignore'
    })

    return (
        <GraphQLContext.Provider value={graphQLClient}>
            {children}
        </GraphQLContext.Provider>
    )
}

export default React.memo(GraphQLProvider)
