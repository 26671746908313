import React from 'react';
import IsAuthorized from "../../hoc/IsAuthorized";

const Contacts = () => {
    return <>
        <section className="bg-secondary py-5">
            <div className="container pt-5 pb-lg-2 pb-xl-4 py-xxl-5">
                {/* Page title*/}
                <h1 className="display-2">Контакты</h1>
                <p className="fs-lg pb-4 mb-2 mb-sm-3">
                    Свяжитесь с нами, используя контакты ниже
                </p>
                {/* Details cards*/}
                <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-4 g-4 pb-2 pb-sm-4 pb-lg-5">
                    {/* Address*/}
                    <div className="col">
                        <div className="card border-0 h-100">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Адрес</h4>
                                <p className="fs-lg fw-medium pb-3 mb-3">
                                    78 Daxing District, Beijing, China, 102605
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Phone*/}
                    <IsAuthorized>
                        <div className="col">
                            <div className="card border-0 h-100">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Тех. поддержка</h4>
                                    <ul className="list-unstyled mb-0">
                                        <li className="pb-1 mb-2">
                                        <span className="d-block fs-sm text-muted mb-1">
                                            Skype
                                        </span>
                                            <a className="nav-link fs-lg p-0" href="skype:Coscoline_support">
                                                Coscoline_support
                                            </a>
                                        </li>
                                        <li>
                                        <span className="d-block fs-sm text-muted mb-1">
                                            Телеграм
                                        </span>
                                            <a className="nav-link fs-lg p-0" href="https://t.me/coscoline_support" target="_blank" rel="noreferrer">
                                                @coscoline_support
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </IsAuthorized>
                    {/* Schedule*/}
                    <div className="col">
                        <div className="card border-0 h-100">
                            <div className="card-body">
                                <h4 className="card-title mb-4">График работы</h4>
                                <ul className="list-unstyled mb-0">
                                    <li className="pb-1 mb-2">
                                        <span className="d-block fs-sm text-muted mb-1">
                                            Пн - Пт
                                        </span>
                                        <div className="d-flex align-items-center">
                                            <span className="text-nav fs-lg fw-medium">09:00</span>
                                            <span
                                                className="border-top mx-2"
                                                style={{width: 24, height: 1}}
                                            />
                                            <span className="text-nav fs-lg fw-medium">17:00</span>
                                            <span className="ms-1 fs-5">мск</span>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="d-block fs-sm text-muted mb-1">
                                            Сб
                                        </span>
                                        <div className="d-flex align-items-center">
                                            <span className="text-nav fs-lg fw-medium">10:00</span>
                                            <span
                                                className="border-top mx-2"
                                                style={{width: 24, height: 1}}
                                            />
                                            <span className="text-nav fs-lg fw-medium">15:00</span>
                                            <span className="ms-1 fs-5">мск</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Email*/}
                    <div className="col">
                        <div className="card border-0 h-100">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Email</h4>
                                <ul className="list-unstyled mb-0">
                                    <li className="pb-1 mb-2">
                                        <span className="d-block fs-sm text-muted mb-1">
                                            Главный офис
                                        </span>
                                        <a
                                            className="nav-link fs-lg p-0"
                                            href="mailto:coscolinecorporation@gmail.com"
                                            style={{wordBreak: 'break-all'}}
                                        >
                                            coscolinecorporation@gmail.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default React.memo(Contacts);