import React from 'react';
import {icon} from "leaflet"
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import useCoordinates from "../../../hook/useCoordinates";
import "leaflet/dist/leaflet.css"


function Map({latitude, longitude, name, zoom = 10, maxZoom}) {

    const {portCoordinates} = useCoordinates('port')


    if (!isFinite(latitude)) return null
    if (!isFinite(longitude)) return null


    const position = [latitude, longitude]

    const shipIcon = icon({
        iconUrl: '/logo-ship-dark.png',
        iconSize: [32.5, 20],
        // iconAnchor: [0, 0],
        popupAnchor: [0, -10]
    })

    const portIcon = icon({
        iconUrl: '/img/icon-port.png',
        iconSize: [35, 35],
        iconAnchor: [17.5, 29],
        popupAnchor: [0, -22]
    })



    return <>

        <MapContainer center={position} zoom={zoom} scrollWheelZoom={true} maxZoom={maxZoom} style={{height: '100%'}}>

            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <Marker position={position} icon={shipIcon}>
                <Popup>
                    <div className="p-3">
                        {name}
                    </div>
                </Popup>
            </Marker>

            {
                portCoordinates?.data?.map(coordinate => {
                    const {latitude, longitude, name} = coordinate?.attributes || {}
                    const position = [latitude, longitude]
                    // const icon = type === 'port' ? portIcon : shipIcon
                    return <Marker position={position} icon={portIcon} key={coordinate?.id}>
                        <Popup>
                            <div className="p-3">
                                {name}
                            </div>
                        </Popup>
                    </Marker>
                })
            }

        </MapContainer>
    </>
}

export default React.memo(Map);