import {gql} from "graphql-request";

export const GET_USERS = gql`

    query GetUsers(
        
        $refLink: String
        $findUpper: String
        $findLower: String
        
        $verified: Boolean
        #$document: Boolean
        
        $sort: [String] = "id:desc"
        $page: Int = 1
        $pageSize: Int = 10
        
    ) {
        usersPermissionsUsers(
            
            filters: {
                refLink: {eq: $refLink}
                or: [
                    {name: {containsi: $findUpper}}
                    {surname: {containsi: $findUpper}}
                    {name: {containsi: $findLower}}
                    {surname: {containsi: $findLower}}
                ]
                verified: {eq: $verified}
                #document: {id: {eq: 178}} # поле document не определено в фильтре!!!
                
            }
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    name
                    surname
                    email
                    createdAt
                    verified
                    document {
                        data {
                            id
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`