import React from 'react';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import useCoordinates from "../../hook/useCoordinates";
import {icon} from "leaflet";
import "leaflet/dist/leaflet.css"


const Ports = () => {

    const {portCoordinates} = useCoordinates('port')

    const portIcon = icon({
        iconUrl: '/img/icon-port.png',
        iconSize: [35, 35],
        iconAnchor: [17.5, 29],
        popupAnchor: [0, -22]
    })


    return <>
        <section className="bg-secondary py-5">
            <div className="container pt-5 pb-lg-2 pb-xl-4 py-xxl-5">

                <h1 className="display-6">Карта портов</h1>

                <MapContainer
                    center={[54.074565, 9.690835]}
                    zoom={4}
                    maxZoom={7}
                    scrollWheelZoom={true}
                    style={{height: "450px"}}
                    className="rounded-4"
                >

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />


                    {
                        portCoordinates?.data?.map(coordinate => {
                            const {latitude, longitude, name} = coordinate?.attributes || {}
                            const position = [latitude, longitude]
                            return <Marker position={position} icon={portIcon} key={coordinate?.id}>
                                <Popup>
                                    <div className="p-3">
                                        {name}
                                    </div>
                                </Popup>
                            </Marker>
                        })
                    }

                </MapContainer>

            </div>
        </section>
    </>
}

export default React.memo(Ports);