import {gql} from "graphql-request";

export const GET_MY_INVESTMENTS = gql`

    query GetMyInvestments(
        
        $user: ID
        
        $sort: [String] = "id:asc"
        $page: Int = 1
        $pageSize: Int = 100
        
    ) {
        myInvestments(
            
            filters: {user: {id: {eq: $user}}}
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    status {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    investment {
                        data {
                            id
                            attributes {
                                name
                                price
                                params
                                img {
                                    data {
                                        attributes {
                                            url
                                            formats
                                        }
                                    }
                                }
                                dailyProfit
                                fee
                                discount
                                containerPurchase
                            }
                        }
                    }
                }
            }
        }
    }
`