import React from 'react';

function Faq() {
    return (
        <>
            <div className="accordion" id="accordionDefault">
                {/* Item */}
                <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Как я могу заработать больше монет CoscoCoin?
                        </button>
                    </h3>
                    <div
                        className="accordion-collapse collapse show"
                        id="collapseOne"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionDefault"
                    >
                        <div className="accordion-body fs-sm">
                            Система повышения доходности построена на привлечение партнеров в вашу команду. Например, пригласив 10 участников, которые прошли верификацию, вы уже переходите на 2-й уровень и за каждый клик будете получать 1.2 монеты. А для достижения 3-го уровня нужно привлечь 30 верифицированных участников, которые дадут возможность получать уже 1.5 монеты за нажатие. Помимо этого, со всех заработанных монет вашей командой, вы стабильно будете получать по 7% с их заработка.
                        </div>
                    </div>
                </div>
                {/* Item */}
                <div className="accordion-item">
                    <h3 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            Что делать, если начисления не происходят?
                        </button>
                    </h3>
                    <div
                        className="accordion-collapse collapse"
                        id="collapseTwo"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionDefault"
                    >
                        <div className="accordion-body fs-sm">
                            Скорее всего у вас пропал интернет или возникла внутрибраузерная ошибка соединения. Обновите страницу и все заработает.
                        </div>
                    </div>
                </div>
                {/* Item */}
                <div className="accordion-item">
                    <h3 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                        >
                            Когда я смогу вывести заработанные монеты CoscoCoin ?
                        </button>
                    </h3>
                    <div
                        className="accordion-collapse collapse"
                        id="collapseThree"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionDefault"
                    >
                        <div className="accordion-body fs-sm">
                            Программа добычи монет нацелена на ознакомление как можно большего количества аудитории с инвестиционными продуктами компании. После наступления даты запуска в работу монет, вы сможете обменять их на продукты компании или же вывести на основной баланс, а уже с него поставить средства на вывод.
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Faq;