import {gql} from "graphql-request";

export const GET_CONTAINERS = gql`

    query GetContainers(
        
        $sort: [String] = "id:asc"
        $page: Int = 1
        $pageSize: Int = 100
        
    ) {
        containers(
            
            sort: $sort

            pagination: {
                page: $page
                pageSize: $pageSize
            }

        ) {
            data {
                id
                attributes {
                    name
                    params
                    price
                    img {
                        data {
                            attributes {
                                url
                                formats
                            }
                        }
                    }
                }
            }
        }
    }
`