import React from 'react';

const CheckBoxSwitch = ({checked, setChecked}) => {


    return <>
        <span className="form-switch align-self-center d-block">
            <label className="form-check-label">
                <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    checked={checked}
                    onChange={e => setChecked(e.target.checked)}
                />
            </label>
        </span>
    </>
}

export default React.memo(CheckBoxSwitch);