import React from 'react';
import {USER_ID_OFFSET} from "../../../config";
import Spinner from "../../Elements/Loaders/Spinner";
import {math} from "../../../utilities/math";
import ProfileDocument from "./ProfileDocument";
import useUser from "../../../hook/useUser";
import useUserAvatarUrl from "../../../hook/useUserAvatarUrl";
import IsAdmin from "../../../hoc/IsAdmin";


const ProfileView = ({setEditMode}) => {

    const user = useUser()
    const avatarUrl = useUserAvatarUrl()


    if (!user) return <div className="mb-5 d-flex justify-content-center">
        <Spinner size="3rem"/>
    </div>


    const USER_ID = USER_ID_OFFSET + +user?.id


    const fields = [
        user?.email,
        user?.name,
        user?.surname,
        user?.skype,
        user?.telegram,
        user?.phone,
        user?.birthday,
        user?.gender,
        user?.country,
        user?.city,
        user?.avatar,
        user?.document
    ]

    const fieldsTotal = fields.length
    const fieldsFilled = fields.filter(field => field).length


    const occupancy = math.round(fieldsFilled / fieldsTotal * 100)




    const location = [user?.country, user?.city].filter(item => item).join(', ')





    return <>

        <div className="d-flex align-items-center mt-sm-n1 pb-4 mb-0 mb-lg-1 mb-xl-3">
            <i className="ai-user text-primary lead pe-1 me-2"/>
            <h2 className="h4 mb-0">Профиль, ID: {USER_ID}</h2>
            <span className="btn btn-sm btn-secondary ms-auto a" onClick={() => setEditMode(true)}>
                <i className="ai-edit ms-n1 me-2"/>РЕДАКТИРОВАТЬ
            </span>
        </div>

        <div className="d-md-flex align-items-center">
            <div className="d-sm-flex align-items-center">

                <div className="rounded-circle bg-size-cover bg-position-center flex-shrink-0"
                     style={{
                         width: 80,
                         height: 80,
                         backgroundImage: `url(${avatarUrl})`
                     }}
                />

                <div className="pt-3 pt-sm-0 ps-sm-3">
                    <h3 className="h5 mb-2">
                        {[user?.name, user?.surname].join(' ')}
                        {
                            user?.verified &&
                            <i className="ai-circle-check-filled text-success ms-2" title="Верифицирован"/>
                        }
                        {
                            user?.protection &&
                            <i className="ai-shield text-warning ms-2" title="Застрахован"/>
                        }
                    </h3>
                    <div className="text-muted fw-medium d-flex flex-wrap flex-sm-nowrap align-iteems-center">
                        {/*<div className="d-flex align-items-center me-3">*/}
                        {/*    ID: {USER_ID}*/}
                        {/*</div>*/}

                        {
                            location &&
                            <div className="d-flex align-items-center text-nowrap">
                                <i className="ai-map-pin me-1"/>
                                {location}
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className="w-100 pt-3 pt-md-0 ms-md-auto" style={{maxWidth: 212}}>
                <div className="d-flex justify-content-between fs-sm pb-1 mb-2">
                    Заполненость<strong className="ms-2">{occupancy}%</strong>
                </div>
                <div className="progress" style={{height: 5}}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{width: `${occupancy}%`}}
                        aria-valuenow={occupancy}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    />
                </div>
            </div>
        </div>
        <div className="row py-4 mb-2 mb-sm-3">
            <div className="col-md-6 mb-4 mb-md-0">
                <table className="table mb-0">
                    <tbody>
                    <tr className="align-middle">
                        <td className="border-0 text-muted py-1 px-0">Телефон</td>
                        <td className="border-0 text-dark fw-medium py-1 ps-3">
                            {user?.phone}
                        </td>
                    </tr>
                    <tr className="align-middle">
                        <td className="border-0 text-muted py-1 px-0">Email (почта)</td>
                        <td className="border-0 text-dark fw-medium py-1 ps-3">
                            {user?.email}
                        </td>
                    </tr>
                    <tr className="align-middle">
                        <td className="border-0 text-muted py-1 px-0">Skype</td>
                        <td className="border-0 text-dark fw-medium py-1 ps-3">
                            {user?.skype}
                        </td>
                    </tr>
                    <tr className="align-middle">
                        <td className="border-0 text-muted py-1 px-0">Telegram</td>
                        <td className="border-0 text-dark fw-medium py-1 ps-3">
                            {user?.telegram}
                        </td>
                    </tr>
                    <tr className="align-middle">
                        <td className="border-0 text-muted py-1 px-0">Дата рождения</td>
                        <td className="border-0 text-dark fw-medium py-1 ps-3">
                            {user?.birthday}
                        </td>
                    </tr>
                    <tr className="align-middle">
                        <td className="border-0 text-muted py-1 px-0">Пол</td>
                        <td className="border-0 text-dark fw-medium py-1 ps-3">
                            {user?.gender}
                        </td>
                    </tr>

                    <IsAdmin>
                        <tr className="align-middle">
                            <td className="border-0 text-muted py-1 px-0">Начисление процентов</td>
                            <td className="border-0 text-dark fw-medium py-1 ps-3">
                                {user?.benefit ? "Да" : "Нет"}
                            </td>
                        </tr>
                    </IsAdmin>


                    </tbody>
                </table>
            </div>
            <div className="col-md-6 d-md-flex justify-content-end">

                <ProfileDocument/>

            </div>


        </div>
    </>

}

export default React.memo(ProfileView);