import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import Map from "./Map"


const MapModal = ({latitude, longitude, name, button, zoom, maxZoom}) => {

    const [show, setShow] = useState(false)

    return <>

        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
            <Modal.Header closeButton>

                <Modal.Title>Где контейнер</Modal.Title>

            </Modal.Header>
            <Modal.Body className="p-0">

                <Map
                    zoom={zoom}
                    maxZoom={maxZoom}
                    latitude={latitude}
                    longitude={longitude}
                    name={name}
                />

            </Modal.Body>
        </Modal>


        {
            React.cloneElement(button, {onClick: () => setShow(true)})
        }

    </>
}


export default React.memo(MapModal)
