import React, {useCallback, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {useEffect} from "react";
import {API, AUTH_TOKEN, BEARER} from "../config";
import axios from "axios";
import qs from "qs"
import {useQuery} from "@tanstack/react-query";


const AuthProvider = ({children}) => {

    const [token, setToken] = useState(localStorage.getItem(AUTH_TOKEN))
    const [user, setUser] = useState()


    const query = qs.stringify({
        populate: {
            role: true,
            referrer: true,
            avatar: true,
            document: true
        }
    })


    const {isInitialLoading, isError, error} = useQuery({
        queryKey: ['me', {token, query}],
        queryFn: () => axios.get(`${API}/users/me?${query}`, {
            headers: {
                Authorization: `${BEARER} ${token}`
            }
        }),
        onSuccess: response => {
            setUser(response.data)
        },
        onError: error => {
            if (error.response) {
                // console.log('Strapi выкинул ошибку')
                setToken(undefined)
            } else {
                // console.log('Нет связи')
            }
            // console.log(error)
            // if (error.response?.data?.error?.status === 401) {
            //     setToken(undefined)
            // }
        },
        enabled: !!token
    })


    const isLoading = isInitialLoading && !user



    const logOut = useCallback(() => {
        setToken(null)
        setUser(undefined)
    }, [])



    useEffect(() => {
        if (token) {
            localStorage.setItem(AUTH_TOKEN, token)
        } else {
            localStorage.removeItem(AUTH_TOKEN)
        }
    }, [token])



    return (
        <AuthContext.Provider value={{user, token, isLoading, isError, error, setUser, setToken, logOut}}>
            {children}
        </AuthContext.Provider>
    )
}

export default React.memo(AuthProvider);




