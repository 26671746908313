import React, {useEffect, useRef} from 'react';
import {themeModeSwitch} from "../../utilities/AroundComponents";
import randomstring from 'randomstring';

const ThemeSwitcher = () => {

    const ref = useRef(null)

    useEffect(() => {
        themeModeSwitch(ref.current)
    }, [])

    const id = randomstring.generate(7)

    return (
        <div className="form-check form-switch mode-switch order-lg-2"
             data-bs-toggle="mode"
             ref={ref}
        >
            <input className="form-check-input" type="checkbox" id={id}/>
            <label className="form-check-label" htmlFor={id}>
                <i className="ai-sun fs-lg"/>
            </label>
            <label className="form-check-label" htmlFor={id}>
                <i className="ai-moon fs-lg"/>
            </label>
        </div>
    )
}

export default React.memo(ThemeSwitcher);