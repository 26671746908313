import React, {useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";

const CabinetSwitcher = () => {

    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const [cabinetId, setCabinetId] = useState(params?.cabinetId || '')


    function extractLastSegment(str) {
        if (typeof str !== 'string') return ''
        const match = str
            .replace(/\/+$/, '')
            .match(/\/([^\/]*)$/)
        return match ? match[1] : ''
    }


    const changeCabinet = cabinetId => {
        const section = extractLastSegment(location?.pathname)
        const arr = ['cabinet', cabinetId, section]
        const to = arr.filter(item => item).join('/')
        navigate('/' + to)
    }


    const myCabinet = () => {
        setCabinetId('')
        changeCabinet()
    }

    const onSubmit = e => {
        e.preventDefault()
        changeCabinet(cabinetId)
    }



    return (
        <form onSubmit={onSubmit} className="d-flex gap-1 mb-3">
            <input
                type="tel"
                className="form-control form-control-sm"
                placeholder="Перейти в кабинет"
                value={cabinetId}
                onChange={e => setCabinetId(e.target.value)}
            />
            <button
                type="submit"
                className="btn btn-sm btn-success"
                data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight"
            >
                <i className="ai-check-alt"/>
            </button>
            <button
                className="btn btn-sm btn-outline-primary"
                onClick={myCabinet}
                type="button"
                data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight"
            >
                <i className="ai-cross-alt"/>
            </button>
        </form>
    )
}

export default CabinetSwitcher;