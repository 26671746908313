import React from 'react';
import '../../../style/PageLoader.css'
import Spinner from "./Spinner";

const PageLoader = () => {
    return (
        <div className="d-flex align-items-center justify-content-center min-vh-100">
            <Spinner size="4rem"/>
        </div>
    )
}

export default React.memo(PageLoader);