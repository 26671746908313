import React from 'react';
import '../../../style/PageLoader.css'

const Spinner = ({size = '1rem', className = ''}) => {
    return (
        <span className={"spinner-border spinner-border-sm mx-1 " + className}
              role="status"
              style={{width: size, height: size}}
              aria-hidden="true"
        />
    )
}

export default React.memo(Spinner);