import React, {useState, useEffect} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import Spinner from '../../Elements/Loaders/Spinner';
import useUserId from '../../../hook/useUserId';
import './btn.css'
import moment from 'moment';
import {GET_COINS} from '../../../graphql/Coin/GET_COINS';
import {useGraphQL} from '../../../hook/useGraphQL';


const MIN_INTERVAL = 180


function GetButton({isDeadline}) {

    const queryClient = useQueryClient()
    const graphQLClient = useGraphQL()

    const userId = useUserId(true)

    const [count, setCount] = useState(0)



    const {mutate, isLoading} = useMutation({
        mutationFn: () => axios
            .post('/coin-transaction/createCustom', {user: userId})
            .then(res => res?.data),
        onSuccess: (data) => {
            // setCount(data?.timeLeft)
            queryClient.invalidateQueries({queryKey: ['coinTransactionSum', {user: userId}]})
            queryClient.invalidateQueries({queryKey: ['coinTransactions', {user: userId}]})
        },
        onError: (err, variables, context) => {
            // setCount(err?.response?.data?.error?.details?.timeLeft || 0)
        }
    })



    //////////// НАХОДИМ ПОСЛЕДНЕЕ НАЧИСЛЕНИЕ ////////////
    const query = {user: userId, type: 1, sort: 'id:desc', pageSize: 1}
    const {data: coinTransactionsResponse} = useQuery({
        queryKey: ['coinTransactions', query],
        queryFn: () => graphQLClient.request(GET_COINS, query).then(res => res?.coinTransactions)
    })


    const coinTransactions = coinTransactionsResponse?.data
    const lastCoinTransaction = coinTransactions?.[0]
    const lastCoinTransactionDate = lastCoinTransaction?.attributes?.createdAt




    useEffect(() => {
        if (!coinTransactionsResponse) return
        if (lastCoinTransactionDate) {
            const secondsPassed = moment().diff(moment(lastCoinTransactionDate), 'seconds')
            const timeLeft = MIN_INTERVAL - secondsPassed
            setCount(timeLeft)
        } else {
            setCount(0)
        }
    }, [lastCoinTransactionDate])
    //////////// НАХОДИМ ПОСЛЕДНЕЕ НАЧИСЛЕНИЕ ////////////






    useEffect(() => {

        if (count <= 0) return

        const timerId = setTimeout(() => {
            setCount(count - 1)
        }, 1000)

        return () => clearTimeout(timerId)

    }, [count])




    const isDisabled = count > 0

    const onClick = () => {
        if (isDisabled || isDeadline) return
        mutate()
    }


    const duration = moment.duration(count, 'seconds')
    const time = moment.utc(duration.asMilliseconds()).format('HH:mm:ss')



    return (
        <a
            // disabled={isDisabled}
            onClick={onClick}
            className={`pulseBtn ${(isDisabled || isDeadline) ? 'is-clicked' : ''}`}
        >
            {
                isLoading ? <Spinner className="me-2"/> :
                    (
                        isDisabled ?
                            time :
                            <img src="/img/shipbutton.png" alt=""/>
                    )
            }
        </a>
    )
}

export default React.memo(GetButton);
