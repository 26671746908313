import React, {useEffect, useState} from 'react';
import {useAuth} from "../../../hook/useAuth";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {useInfiniteQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {GET_USER_REFERRALS_LEVEL_1} from "../../../graphql/User/GET_USER_REFERRALS_LEVEL_1";
import {useGraphQL} from "../../../hook/useGraphQL";
import useVirtualUser from "../../../hook/useVirtualUser";
import Spinner from "../../Elements/Loaders/Spinner";
import Table from "react-bootstrap/Table";
import moment from "moment/moment";


const PERCENT_LEVEL_1 = 7
const PAGE_SIZE = 10


const Referrals = ({setCountReferrals}) => {

    const graphQLClient = useGraphQL()

    const {user: userAuth} = useAuth()
    const {user: userVirtual, isLoading: isLoadingVirtualUser} = useVirtualUser()
    const {cabinetId} = useParams()

    const [copied, setCopied] = useState()



    const userId = cabinetId || userAuth?.id
    const user = cabinetId ? userVirtual : userAuth



    const refLink = user?.refLink
    const newUrl = new URL('sign-up/ref/' + refLink, window.location.origin)
    const myRef = newUrl.toString()


    const {
        data: data1,
        fetchNextPage: fetchNextPage1,
        hasNextPage: hasNextPage1,
        isLoading: isLoading1,
        isFetchingNextPage: isFetchingNextPage1
    } = useInfiniteQuery({
        queryKey: ['referralsLevel1', userId, true],
        queryFn: ({pageParam = 1}) => (
            graphQLClient.request(GET_USER_REFERRALS_LEVEL_1, {
                user: userId,
                verified: true,
                page: pageParam,
                pageSize: PAGE_SIZE
            }).then(res => res?.referrals)
        ),
        getNextPageParam: (lastPage) => {
            const {page, pageCount} = lastPage?.meta?.pagination || {}
            if (page < pageCount) return page + 1
        }
    })



    const total1 = data1?.pages?.[0]?.meta?.pagination?.total





    useEffect(() => {
        setCountReferrals(total1)
    }, [total1])


    return <>

        {
            refLink &&
            <div>
                <h5>Моя реф. ссылка</h5>
                <span className="me-2">{myRef}</span>
                <CopyToClipboard text={myRef} onCopy={() => setCopied(true)}>
                    <button className="btn btn-sm btn-outline-primary">
                        {
                            copied
                                ?
                                <><i className="ai-checks me-2"/>Скопировано</>
                                :
                                <><i className="ai-copy me-2"/>Копировать</>
                        }
                    </button>
                </CopyToClipboard>
            </div>
        }


        <div className="table-responsive mb-4 mt-4">
            <Table striped bordered hover style={{verticalAlign: 'middle'}}>
                <thead>
                <tr align="center" valign="middle">
                    <th colSpan={3}>Уровень 1 ({PERCENT_LEVEL_1}%)</th>
                </tr>
                <tr align="center" valign="middle">
                    <th>Дата регистрации</th>
                    <th>Имя</th>
                    <th>Страна, город</th>
                </tr>
                </thead>
                <tbody>

                {
                    isLoading1 && <tr align="center">
                        <td colSpan={3}>
                            <Spinner/>
                        </td>
                    </tr>
                }

                {
                    data1?.pages?.map((group, i) =>
                        <React.Fragment key={i}>
                            {
                                group?.data?.map(referral => {
                                    const {name, surname, country, city, createdAt, verified} = referral?.attributes ||
                                    {}
                                    const createdAtPretty = createdAt ? moment(
                                        createdAt).format("DD.MM.YYYY") : null
                                    const location = [country, city].filter(
                                        item => item).join(', ')
                                    return (
                                        <tr key={referral?.id}>
                                            <td>{createdAtPretty}</td>
                                            <td>{name} {surname} {
                                                verified &&
                                                <i className="ai-circle-check-filled text-success ms-2"
                                                   title="Верифицирован"/>
                                            }</td>
                                            <td>{location}</td>
                                        </tr>
                                    )
                                })
                            }
                        </React.Fragment>
                    )
                }


                {
                    data1?.pages?.[0]?.data?.length === 0 &&
                    <tr>
                        <td colSpan={3} align="center">Нет рефералов первого
                            уровня
                        </td>
                    </tr>
                }


                {
                    hasNextPage1 &&
                    <tr>
                        <td colSpan={3} align="center" className="a"
                            onClick={() => fetchNextPage1()}>
                            ...еще
                            {
                                isFetchingNextPage1 &&
                                <Spinner className="ms-2"/>
                            }
                        </td>
                    </tr>
                }

                </tbody>
            </Table>
        </div>


    </>
}

export default React.memo(Referrals);