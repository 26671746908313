import React, {useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query'
import {GET_TRANSACTIONS} from "../../../graphql/Transaction/GET_TRANSACTIONS";
import {useGraphQL} from "../../../hook/useGraphQL";
import {TRANSACTION_ID_OFFSET} from "../../../config";
import moment from "moment";
import Pagination from "../../Elements/Pagination";
import Spinner from "../../Elements/Loaders/Spinner";
import {transactionStatusBadge} from "../../../utilities/functions";
import ModalEditTransaction from "../Admin/Transactions/ModalEditTransaction";
import ModalAddTransaction from "../Admin/Transactions/ModalAddTransaction";
import IsAdmin from "../../../hoc/IsAdmin";
import useUserId from "../../../hook/useUserId";
import StatIncome from "./StatIncome";
import Select from "react-select";


const Transactions = () => {

    const graphQLClient = useGraphQL()

    const userId = useUserId(true)

    const [page, setPage] = useState(1)
    const [type, setType] = useState(null)


    const query = {
        type: type?.value || undefined,
        page,
        user: userId
    }

    const {data, isLoading} = useQuery({
        queryKey: ['transactions', GET_TRANSACTIONS, query],
        queryFn: () => graphQLClient.request(GET_TRANSACTIONS, query)
    })


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [page])


    const statusIcons = {
        1: <i className="ai-circle-check text-success mb-1" title=""/>,
        2: <i className="ai-circle-x text-danger mb-1" title=""/>,
        3: <i className="ai-time text-warning mb-1" title=""/>,
    }


    const typeBadgeFunc = type => {

        const {id, attributes} = type || {}
        const {name} = attributes || {}

        const typeBadges = {
            1: <span className="badge bg-faded-primary text-primary fs-xs">{name}</span>,
            2: <span className="badge bg-faded-success text-success fs-xs">{name}</span>,
            3: <span className="badge bg-faded-danger text-danger fs-xs">{name}</span>,
            4: <span className="badge bg-faded-warning text-warning fs-xs">{name}</span>,
            5: <span className="badge bg-faded-warning text-warning fs-xs">{name}</span>,
            6: <span className="badge bg-faded-warning text-warning fs-xs">{name}</span>,
            7: <span className="badge bg-faded-info text-info fs-xs">{name}</span>,
        }

        return typeBadges[id]
    }


    if (isLoading) return <div className="text-center">
        <Spinner size="5rem"/>
    </div>


    return (
        <>
            <IsAdmin>
                <div className="d-flex gap-3 mb-3 align-items-center">
                    <ModalAddTransaction/>
                    <StatIncome/>
                </div>
            </IsAdmin>


            <div style={{maxWidth: '300px'}} className="mb-3 w-100">
                <Select
                    isClearable
                    isSearchable={false}
                    classNamePrefix="react-select"
                    options={[
                        {value: 2, label: 'Вывод'},
                        {value: 5, label: 'Начисление (инвестиции)'},
                    ]}
                    placeholder="Фильтровать"
                    className=""
                    value={type}
                    onChange={setType}
                />
            </div>



            {/* Orders accordion*/}
            <div className="accordion accordion-alt accordion-orders" id="orders">

                {
                    data?.transactions?.data?.map(transaction => {

                        const {
                            type,
                            status,
                            datetime,
                            amount,
                            comment,
                            depositType,
                            depositName,
                            withdrawalRequisite,
                            refTransactions
                        } = transaction?.attributes || {}

                        const newId = TRANSACTION_ID_OFFSET + +transaction?.id
                        const accordionId = 'order' + newId

                        const datetimeFormatted = datetime ?
                            moment(datetime).format("DD.MM.YYYY HH:mm") :
                            null

                        return (
                            <div key={transaction?.id} className="accordion-item border-top mb-0">
                                <div className="accordion-header">
                                    <a
                                        className="accordion-button d-flex fs-4 fw-normal text-decoration-none py-3 collapsed"
                                        href={`#${accordionId}`}
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        aria-controls={accordionId}
                                    >
                                        <div className="d-flex justify-content-between w-100" style={{maxWidth: 440}}>
                                            <div className="col me-3 me-sm-4">
                                                <div className="fs-sm text-muted">
                                                    <span className="me-1">#{newId}</span>
                                                </div>

                                                {transactionStatusBadge(status)}

                                                {/*<span className="badge bg-faded-info text-info fs-xs">*/}
                                                {/*    {type?.data?.attributes?.name}*/}
                                                {/*</span>*/}
                                            </div>
                                            <div className="col me-3 me-sm-4">
                                                <div className="d-none d-sm-block fs-sm text-muted mb-2">
                                                    Дата транзакции
                                                </div>
                                                <div className="d-sm-none fs-sm text-muted mb-2">
                                                    Дата
                                                </div>
                                                <div className="fs-sm fw-medium text-dark">
                                                    {datetimeFormatted}
                                                </div>
                                            </div>
                                            <div className="col me-3 me-sm-4 text-center">
                                                <div className="fs-sm text-muted mb-2">
                                                    <span className="badge bg-faded-info text-info fs-xs">
                                                        {type?.data?.attributes?.name}
                                                    </span>
                                                </div>
                                                <div className="fs-sm fw-medium text-dark">
                                                    {amount} $
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-button-img d-none d-sm-flex ms-auto">
                                            <div className="mx-1">
                                                {/*<img*/}
                                                {/*    src="/assets/img/account/orders/01.png"*/}
                                                {/*    width={48}*/}
                                                {/*    alt="Product"*/}
                                                {/*/>*/}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="accordion-collapse collapse" id={accordionId} data-bs-parent="#orders">
                                    <div className="accordion-body">
                                        <div className="bg-secondary rounded-1 p-4 my-2">
                                            <div className="d-flex justify-content-between flex-wrap gap-3">
                                                {/*<div className="col-sm-5 col-md-3 col-lg-4 mb-3 mb-md-0">*/}
                                                {/*    <div className="fs-sm fw-medium text-dark mb-1">*/}
                                                {/*        Статус:*/}
                                                {/*    </div>*/}
                                                {/*    <div className="fs-sm">*/}
                                                {/*        {statusIcons[status?.data?.id]}*/}
                                                {/*        <span className="ms-1">{status?.data?.attributes?.name}</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                {
                                                    transaction?.attributes?.type?.data?.id === '1' &&
                                                    depositName && <>
                                                        <div>
                                                            <div className="fs-sm fw-medium text-dark mb-1">
                                                                Система:
                                                            </div>
                                                            <div className="fs-sm">
                                                                {depositName}
                                                            </div>
                                                        </div>
                                                        {/*{*/}
                                                        {/*    transaction?.attributes?.status?.data?.id === '3' &&*/}
                                                        {/*    depositType?.data?.attributes?.requisite &&*/}
                                                        {/*    <div>*/}
                                                        {/*        <div className="fs-sm fw-medium text-dark mb-1">*/}
                                                        {/*            Реквизиты:*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="fs-sm">*/}
                                                        {/*            {depositType?.data?.attributes?.requisite}*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*}*/}
                                                    </>
                                                }

                                                {
                                                    transaction?.attributes?.type?.data?.id === '2' &&
                                                    withdrawalRequisite &&
                                                    <div>
                                                        <div className="fs-sm fw-medium text-dark mb-1">
                                                            Реквизиты:
                                                        </div>
                                                        <div className="fs-sm">
                                                            {withdrawalRequisite}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    type?.data?.attributes?.clarification &&
                                                    <div className="">
                                                        <div className="fs-sm fw-medium text-dark mb-1">
                                                            Источник:
                                                        </div>
                                                        <div className="fs-sm">
                                                            {type?.data?.attributes?.clarification}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    comment &&
                                                    <div className="">
                                                        <div className="fs-sm fw-medium text-dark mb-1">
                                                            Примечание:
                                                        </div>
                                                        <div className="fs-sm">
                                                            {comment}
                                                        </div>
                                                    </div>
                                                }
                                                <IsAdmin>
                                                    {/*{*/}
                                                    {/*    refTransactions?.data?.length > 0 &&*/}
                                                    {/*    <div>*/}
                                                    {/*        <div className="fs-sm fw-medium text-dark mb-1">*/}
                                                    {/*            Реферальные:*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className="fs-sm">*/}
                                                    {/*            {refTransactions?.data?.map(transaction => transaction?.id).join(', ')}*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*}*/}
                                                    <div>
                                                        <div className="fs-sm fw-medium text-dark mb-1">
                                                            Админ:
                                                        </div>
                                                        <div className="fs-sm">
                                                            <ModalEditTransaction
                                                                transaction={transaction}
                                                            />
                                                        </div>
                                                    </div>
                                                </IsAdmin>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>


            <Pagination
                page={page}
                setPage={setPage}
                pageCount={data?.transactions?.meta?.pagination?.pageCount}
            />

        </>
    )
}

export default React.memo(Transactions);