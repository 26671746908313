import React, {useEffect} from 'react';
import {Autoplay, Swiper} from "swiper";

const SELECTOR = 'client-swiper'
const SELECTOR2 = 'client2-swiper'

const Clients = () => {


    useEffect(() => {

        Swiper.use([Autoplay])

        new Swiper(`#${SELECTOR}`, {
            "loop": true,
            "grabCursor": false,
            "centeredSlides": true,
            "autoplay": {
                "delay": 0,
                "disableOnInteraction": false
            },
            "freeMode": true,
            "speed": 10000,
            "freeModeMomentum": false,
            "breakpoints": {
                "0": {"slidesPerView": 2},
                "600": {"slidesPerView": 3},
                "800": {"slidesPerView": 4},
                "1200": {"slidesPerView": 5},
                "1400": {"slidesPerView": 6},
                "1600": {"slidesPerView": 7}
            }
        })

        new Swiper(`#${SELECTOR2}`, {
            "loop": true,
            "grabCursor": false,
            "centeredSlides": true,
            "autoplay": {
                "delay": 0,
                "disableOnInteraction": false
            },
            "freeMode": true,
            "speed": 8500,
            "freeModeMomentum": false,
            "breakpoints": {
                "0": {"slidesPerView": 2},
                "600": {"slidesPerView": 3},
                "800": {"slidesPerView": 4},
                "1200": {"slidesPerView": 5},
                "1400": {"slidesPerView": 6},
                "1600": {"slidesPerView": 7}
            }
        })

    }, [])


    const slides = <>
        <div className="swiper-slide">
            <img
                className="d-block mx-auto"
                src="/img/partners/suzuki.svg"
                width={196}
                alt="Suzuki"
            />
        </div>
        <div className="swiper-slide">
            <img
                className="d-block mx-auto"
                src="/img/partners/philips.svg"
                width={196}
                alt="Philips"
            />
        </div>
        <div className="swiper-slide">
            <img
                className="d-block d-dark-mode-none mx-auto"
                src="/img/partners/puma-dark.svg"
                width={196}
                alt="Puma"
            />
            <img
                className="d-none d-dark-mode-block mx-auto"
                src="/img/partners/puma-light.svg"
                width={196}
                alt="Puma"
            />
        </div>
        <div className="swiper-slide">
            <img
                className="d-block mx-auto"
                src="/img/partners/foster.svg"
                width={196}
                alt="Foster"
            />
        </div>
        <div className="swiper-slide">
            <img
                className="d-block d-dark-mode-none mx-auto"
                src="/img/partners/amazon-dark.png"
                width={196}
                alt="Amazon"
            />
            <img
                className="d-none d-dark-mode-block mx-auto"
                src="/img/partners/amazon-light.png"
                width={196}
                alt="Amazon"
            />
        </div>
        <div className="swiper-slide">
            <img
                className="d-block d-dark-mode-none mx-auto"
                src="/img/partners/caterpillar-dark.png"
                width={196}
                alt="Caterpillar"
            />
            <img
                className="d-none d-dark-mode-block mx-auto"
                src="/img/partners/caterpillar-light.png"
                width={196}
                alt="Caterpillar"
            />
        </div>
        <div className="swiper-slide">
            <img
                className="d-block d-dark-mode-none mx-auto"
                src="/img/partners/nvidia-dark.png"
                width={196}
                alt="Nvidia"
            />
            <img
                className="d-none d-dark-mode-block mx-auto"
                src="/img/partners/nvidia-light.png"
                width={196}
                alt="Nvidia"
            />
        </div>
        <div className="swiper-slide">
            <img
                className="d-block d-dark-mode-none mx-auto"
                src="/img/partners/samsung-dark.png"
                width={196}
                alt="Samsung"
            />
            <img
                className="d-none d-dark-mode-block mx-auto"
                src="/img/partners/samsung-light.png"
                width={196}
                alt="Samsung"
            />
        </div>
        <div className="swiper-slide">
            <img
                className="d-block d-dark-mode-none mx-auto"
                src="/img/partners/tesla-dark.png"
                width={196}
                alt="Tesla"
            />
            <img
                className="d-none d-dark-mode-block mx-auto"
                src="/img/partners/tesla-light.png"
                width={196}
                alt="Tesla"
            />
        </div>
    </>






    return (
        // Clients (Logos autoplay slider)
        <section className="container pb-5 pt-2 pt-sm-3 pt-md-4 pt-lg-5 my-xl-3 my-xxl-5">
            <div className="container pb-4 mb-2 mb-lg-3">
                <h2 className="h1 text-center">Наши партнеры</h2>
                <p className="text-center mb-0">
                    Более чем 2,000 партнёров сотрудничают с нами
                </p>
            </div>
            <div id={SELECTOR} className="swiper pb-4">
                <div
                    className="swiper-wrapper"
                    style={{transitionTimingFunction: "linear !important"}}
                >
                    {slides}
                </div>
            </div>
            <div id={SELECTOR2} className="swiper" dir="rtl">
                <div
                    className="swiper-wrapper"
                    style={{transitionTimingFunction: "linear !important"}}
                >
                    {slides}
                </div>
            </div>
        </section>
    )
}

export default React.memo(Clients);