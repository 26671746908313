import {useAuth} from "./useAuth";
import {useParams} from "react-router-dom";
import useVirtualUser from "./useVirtualUser";
import {BACKEND_URL} from "../config";
import useUser from "./useUser";

const useUserAvatarUrl = () => {

    const user = useUser()

    const userAvatarUrl = user?.avatar?.formats?.thumbnail?.url
    return userAvatarUrl ?
        `${BACKEND_URL}${userAvatarUrl}` :
        (
            user?.gender === 'Мужской' ?
                '/img/avatars/avatar-man.jpg' :
                (
                    user?.gender === 'Женский' ?
                        '/img/avatars/avatar-woman.jpg' :
                        '/img/avatars/avatar.jpg'
                )
        )
}

export default useUserAvatarUrl;