import React, {useEffect} from "react";

const ButtonGroupInvestments = ({value, setValue, options, className}) => {

    useEffect(() => {
        if (!options) return
        setValue(options[1])
    }, [options])
    
    return (
        <div className={`btn-group ${className ? className : ''}`}>
            {
                options?.filter(item => item).map(item => (
                    <button
                        type="button"
                        key={item?.id}
                        className={`btn ${value === item ? 'btn-primary' : 'btn-outline-secondary'}`}
                        onClick={() => setValue(item)}
                    >
                        {item?.attributes?.name}
                    </button>
                ))
            }
        </div>
    )

}


export default React.memo(ButtonGroupInvestments)
