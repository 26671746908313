import React, {useMemo, useState, useEffect, useRef} from 'react';
import axios from "axios";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru"
import {USER_ID_OFFSET} from "../../../config";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
// import SelectCountry from "../../Input/Select/SelectCountry";
import RadioInput from "../../Input/RadioInput";
import useIsMyCabinet from "../../../hook/useIsMyCabinet";
import IsAdmin from "../../../hoc/IsAdmin";
import CheckBoxSwitch from "../../Input/CheckBoxSwitch";
import useUser from "../../../hook/useUser";
import ProfileAvatar from "./ProfileAvatar";


const ProfileEdit = ({setEditMode}) => {

    const queryClient = useQueryClient()
    const isMyCabinet = useIsMyCabinet()
    const user = useUser()

    const USER_ID = USER_ID_OFFSET + +user?.id

    const [name, setName] = useState(user?.name || '')
    const [surname, setSurname] = useState(user?.surname || '')
    // const [country, setCountry] = useState(
    //     user?.country ?
    //         {value: user?.country, label: user?.country} :
    //         null
    // )
    const [country, setCountry] = useState(user?.country || '')
    const [city, setCity] = useState(user?.city || '')
    const [phone, setPhone] = useState(user?.phone || '')
    const [skype, setSkype] = useState(user?.skype || '')
    const [adminMessage, setAdminMessage] = useState(user?.adminMessage || '')
    const [telegram, setTelegram] = useState(user?.telegram || '')
    const [birthday, setBirthday] = useState(user?.birthday)
    const [gender, setGender] = useState(user?.gender || '')
    const [verified, setVerified] = useState(user?.verified)
    const [blocked, setBlocked] = useState(user?.blocked)
    const [benefit, setBenefit] = useState(user?.benefit)
    const [protection, setProtection] = useState(user?.protection)



    const {mutateAsync, isLoading, isError} = useMutation({
        mutationFn: variables => axios.put(`/users/${user?.id}`, variables),
    })


    const variables = useMemo(() => ({
        name,
        surname,
        // country: country?.value,
        country,
        city,
        phone,
        skype,
        telegram,
        birthday,
        gender,
        verified,
        blocked,
        benefit,
        protection,
        adminMessage
    }), [birthday, city, country, gender, name, phone, skype, surname, telegram, verified, blocked, benefit, protection, adminMessage])


    const saveHandler = () => {
        mutateAsync(variables).then(data => {

            // const updatedUser = data?.data
            // if (updatedUser && updatedUser?.id === userAuth?.id) {
            //     setUser({...userAuth, ...updatedUser})
            // } else {
            //     queryClient.invalidateQueries({queryKey: ['user']})
            // }

            if (isMyCabinet) {
                queryClient.invalidateQueries({queryKey: ['me']})
            } else {
                queryClient.invalidateQueries({queryKey: ['user']})
            }

            setEditMode(false)
        })
    }


    const ref = useRef(null)

    useEffect(() => {
        ref?.current?.classList.remove('PhoneInputInput')
        ref?.current?.classList.add('form-control')
    }, [ref])





    return <>

        <div className="d-flex align-items-center mt-sm-n1 pb-4 mb-0 mb-lg-1 mb-xl-3">
            <i className="ai-user text-primary lead pe-1 me-2"/>
            <h2 className="h4 mb-0">Профиль, ID: {USER_ID}</h2>
            <div className="ms-auto">
                <span className="btn btn-sm btn-secondary me-2 a" onClick={saveHandler}>
                    {
                        !isLoading && !isError &&
                        <i className="ai-save ms-n1 me-2"/>
                    }
                    {
                        isLoading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"
                              aria-hidden="true"/>
                    }
                    {
                        isError &&
                        <i className="ai-emoji-sad ms-n1 me-2"/>
                    }
                    СОХРАНИТЬ
                </span>
                <span className="btn btn-sm btn-secondary a" onClick={() => setEditMode(false)}>
                    <i className="ai-undo ms-n1 me-2"/>ОТМЕНИТЬ
                </span>
            </div>
        </div>


        <ProfileAvatar/>


        {/*Basic info*/}
        <div className="row g-3 g-sm-4 mt-0 mt-lg-2">
            <div className="col-sm-6">
                <label className="form-label" htmlFor="fn">
                    Имя
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="fn"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </div>
            <div className="col-sm-6">
                <label className="form-label" htmlFor="ln">
                    Фамилия
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="ln"
                    value={surname}
                    onChange={e => setSurname(e.target.value)}
                />
            </div>
            <div className="col-sm-6">
                <label className="form-label" htmlFor="email">
                    Email адрес
                </label>
                <input
                    className="form-control"
                    type="email"
                    id="email"
                    defaultValue={user?.email}
                    disabled
                />
            </div>
            <div className="col-sm-6">
                <label className="form-label" htmlFor="phone">
                    Телефон
                </label>
                <PhoneInput
                    ref={ref}
                    placeholder="+7 922 123 45 67"
                    value={phone}
                    onChange={setPhone}
                />
            </div>
            <div className="col-sm-6">
                <label className="form-label" htmlFor="country">
                    Страна
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="country"
                    value={country}
                    onChange={e => setCountry(e.target.value)}
                />
                {/*<SelectCountry*/}
                {/*    value={country}*/}
                {/*    onChange={setCountry}*/}
                {/*/>*/}
            </div>
            <div className="col-sm-6">
                <label className="form-label" htmlFor="city">
                    Населенный пункт
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                />
            </div>
            <div className="col-sm-6">
                <label className="form-label" htmlFor="skype">
                    Skype
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="skype"
                    value={skype}
                    onChange={e => setSkype(e.target.value)}
                />
            </div>
            <div className="col-sm-6">
                <label className="form-label" htmlFor="telegram">
                    Telegram
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="telegram"
                    value={telegram}
                    onChange={e => setTelegram(e.target.value)}
                />
            </div>

            <div className="col-sm-6">
                <label className="form-label" htmlFor="telegram">
                    Дата рождения
                </label>
                <Flatpickr
                    options={{
                        locale: Russian,
                        disableMobile: true,
                    }}
                    className="form-control date-picker"
                    value={birthday}
                    onChange={(dObj, dStr) => {
                        setBirthday(dStr)
                    }}
                />
            </div>

            <div className="col-sm-6">
                <label className="form-label" htmlFor="telegram">
                    Пол
                </label>
                <RadioInput
                    value={gender}
                    setValue={setGender}
                    options={['Мужской', 'Женский']}
                />
            </div>

            <IsAdmin>
                <hr/>
                <div className="row text-center align-items-baseline mt-3">
                    <div className="col-4">
                        <label className="form-label">
                            Верифицирован
                        </label>
                        <CheckBoxSwitch
                            checked={verified}
                            setChecked={setVerified}
                        />
                    </div>
                    <div className="col-4">
                        <label className="form-label">
                            Бан
                        </label>
                        <CheckBoxSwitch
                            checked={blocked}
                            setChecked={setBlocked}
                        />
                    </div>
                    <div className="col-4">
                        <label className="form-label">
                            Начисление процентов
                        </label>
                        <CheckBoxSwitch
                            checked={benefit}
                            setChecked={setBenefit}
                        />
                    </div>
                    <div className="col-4">
                        <label className="form-label">
                            Страховка
                        </label>
                        <CheckBoxSwitch
                            checked={protection}
                            setChecked={setProtection}
                        />
                    </div>
                </div>

                <div>
                    <label className="form-label" htmlFor="adminMessage">
                        Сообщение для клиента
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="adminMessage"
                        value={adminMessage}
                        onChange={e => setAdminMessage(e.target.value)}
                    />
                </div>
            </IsAdmin>


        </div>
        {/*Basic info*/}

    </>

}

export default React.memo(ProfileEdit);