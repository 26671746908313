import React, {useEffect} from 'react';
import {scrollTopButton, smoothScroll} from "../../utilities/AroundComponents";


const BackToTop = () => {

    useEffect(() => {
        scrollTopButton()
        smoothScroll()
    }, [])

    return (
        <a className="btn-scroll-top" href="#" data-scroll={true}>
            <svg viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <circle
                    cx="20" cy="20" r="19" fill="none"
                    stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                />
            </svg>
            <i className="ai-arrow-up"></i>
        </a>
    )
}

export default React.memo(BackToTop);