import React from 'react';

const NetworkError = () => {
    return (
        <main className="page-wrapper">
            {/* Page content*/}
            <div className="d-flex flex-column flex-lg-row justify-content-between min-vh-100 position-relative">
                <div className="position-absolute top-0 start-0 w-100 h-100 d-lg-none">
                    <div
                        className="d-dark-mode-none position-absolute top-0 start-0 w-100 h-100"
                        style={{backgroundColor: "#f2f3f7"}}
                    />
                </div>
                <div
                    className="d-flex flex-column justify-content-center w-lg-50 position-relative zindex-2 mt-auto mt-lg-0">
                    <div
                        className="text-center text-lg-start pt-5 pb-3 py-lg-5 px-3 px-sm-4 px-lg-5 mx-auto"
                        style={{maxWidth: 630}}
                    >
                        <h1 className="display-1">Ошибка сети</h1>
                        <p className="lead pb-2 mb-4 mb-lg-5">
                            Проверьте свое интернет соединение
                        </p>
                        <span className="btn btn-lg btn-primary a" onClick={() => window.location.reload()}>
                            Обновить страницу
                        </span>
                    </div>
                </div>
                <div
                    className="d-flex flex-column position-relative justify-content-end align-items-center w-lg-50 position-relative overflow-hidden">
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-none d-lg-block">
                        <div
                            className="d-dark-mode-none position-absolute top-0 start-0 w-100 h-100"
                            style={{backgroundColor: "#f2f3f7"}}
                        />
                        <div
                            className="d-none d-dark-mode-block position-absolute top-0 start-0 w-100 h-100"
                            style={{backgroundColor: "rgba(255,255,255, .04)"}}
                        />
                    </div>
                    <div className="position-relative zindex-2" style={{maxWidth: 948}}>
                        <img src="/img/404/layer01.png" alt="Lady" id="lady"/>
                        <img
                            className="position-absolute top-0 start-0 w-100 h-100"
                            src="/img/404/layer02-light.png"
                            alt="Bubble"
                            id="bubble"
                        />
                        <div
                            className="position-absolute top-0 start-0 w-100 h-100"
                            id="question"
                        >
                            <img
                                className="d-dark-mode-none"
                                src="/img/404/layer03-light.png"
                                alt="Question mark"
                            />
                            <img
                                className="d-none d-dark-mode-block"
                                src="/img/404/layer03-dark.png"
                                alt="Question mark"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default React.memo(NetworkError);