import React from 'react';
import {Link, useParams} from "react-router-dom";
import {useAuth} from "../../../hook/useAuth";
import {USER_ID_OFFSET} from "../../../config";
import IsAdmin from "../../../hoc/IsAdmin";
import ThemeSwitcher from "../../Elements/ThemeSwitcher";
import CabinetSwitcher from "./CabinetSwitcher";
import useShowCoin from '../../../hook/useShowCoin';
import useIsAdmin from '../../../hook/useIsAdmin';

const SidebarRightBody = () => {

    const {user, logOut} = useAuth()

    const userId = USER_ID_OFFSET + +user?.id
    const params = useParams()

    const {showToAdmins, showToUsers} = useShowCoin()
    const isAdmin = useIsAdmin()

    const cabinetUrl = params?.cabinetId ? ('/' + params?.cabinetId) : ''
    const virtualCabinetId = params?.cabinetId ? ` (${params?.cabinetId})` : ''


    return <>

        <h5 className="fw-bolder text-muted text-uppercase pb-1 mb-4">ID: {userId}{virtualCabinetId}</h5>

        <IsAdmin>
            <nav className="nav flex-column pb-2 pb-lg-4 mb-1">
                <h4 className="fs-xs fw-medium text-muted text-uppercase pb-1 mb-2">Админ панель</h4>

                <CabinetSwitcher/>

                <Link to={`/cabinet${cabinetUrl}/transactions`} className="nav-link fw-semibold py-2 px-0">
                    <span className="w-100" data-bs-dismiss="offcanvas"
                          data-bs-target="#sidebarRight">
                        <i className="ai-wallet fs-5 opacity-60 me-2"/>Админ
                    </span>
                </Link>

                <Link to={`/cabinet${cabinetUrl}/users`} className="nav-link fw-semibold py-2 px-0">
                    <span className="w-100" data-bs-dismiss="offcanvas"
                          data-bs-target="#sidebarRight">
                        <i className="ai-user-group fs-5 opacity-60 me-2"/>Юзеры
                    </span>
                </Link>

                {/*<Link to={`/cabinet/interest-payment`} className="nav-link fw-semibold py-2 px-0">*/}
                {/*    <span className="w-100" data-bs-dismiss="offcanvas"*/}
                {/*          data-bs-target="#sidebarRight">*/}
                {/*        <i className="ai-droplet fs-5 opacity-60 me-2"/>Начисление процентов*/}
                {/*    </span>*/}
                {/*</Link>*/}

            </nav>
        </IsAdmin>

        <nav className="nav flex-column pb-2 pb-lg-4 mb-3">
            <h4 className="fs-xs fw-medium text-muted text-uppercase pb-1 mb-2">КАБИНЕТ</h4>
            <Link to={`/cabinet${cabinetUrl}/profile`} className="nav-link fw-semibold py-2 px-0">
                                <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">
                                    <i className="ai-user-check fs-5 opacity-60 me-2"/>Профиль
                                </span>
            </Link>
            <Link to={`/cabinet${cabinetUrl}/investments`} className="nav-link fw-semibold py-2 px-0">
                                <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">
                                    <i className="ai-chart fs-5 opacity-60 me-2"/>Инвестиции
                                </span>
            </Link>
            {/*<Link to={`/cabinet${cabinetUrl}/my-investments`} className="nav-link fw-semibold py-2 px-0">*/}
            {/*                    <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">*/}
            {/*                        <i className="ai-chart fs-5 opacity-60 me-2"/>Мои инвестиции*/}
            {/*                    </span>*/}
            {/*</Link>*/}
            <Link to={`/cabinet${cabinetUrl}/containers`} className="nav-link fw-semibold py-2 px-0">
                                <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">
                                    <i className="ai-grid fs-5 opacity-60 me-2"/>Контейнеры
                                </span>
            </Link>
            <Link to={`/cabinet${cabinetUrl}/my-containers`} className="nav-link fw-semibold py-2 px-0">
                                <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">
                                    <i className="ai-table-row fs-5 opacity-60 me-2"/>Мои контейнеры
                                </span>
            </Link>
            <Link to={`/cabinet${cabinetUrl}/balance`} className="nav-link fw-semibold py-2 px-0">
                                <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">
                                    <i className="ai-wallet fs-5 opacity-60 me-2"/>Баланс
                                </span>
            </Link>
            <Link to={`/cabinet${cabinetUrl}/partners`} className="nav-link fw-semibold py-2 px-0">
                                <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">
                                    <i className="ai-briefcase fs-5 opacity-60 me-2"/>Рефералы
                                </span>
            </Link>
            {
                (isAdmin ? showToAdmins : showToUsers) &&
                <Link to={`/cabinet${cabinetUrl}/coins`} className="nav-link fw-semibold py-2 px-0">
                                <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">
                                    <i className="ai-award fs-5 opacity-60 me-2"/>CoscoCoin
                                </span>
                </Link>
            }
        </nav>

        <nav className="nav flex-column pb-2 pb-lg-4 mb-1">
            <h4 className="fs-xs fw-medium text-muted text-uppercase pb-1 mb-2">Прочее</h4>
            <Link to="/contacts" className="nav-link fw-semibold py-2 px-0 mb-2">
                                <span className="w-100" data-bs-dismiss="offcanvas" data-bs-target="#sidebarRight">
                                    <i className="ai-messages fs-5 opacity-60 me-2"/>Тех. поддержка
                                </span>
            </Link>
            <ThemeSwitcher/>
        </nav>

        <nav className="nav flex-column">
                            <span className="nav-link fw-semibold py-2 px-0 a" onClick={logOut}>
                                <i className="ai-logout fs-5 opacity-60 me-2"/>Выход
                            </span>
        </nav>
    </>
}

export default React.memo(SidebarRightBody);