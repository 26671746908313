import React, {useEffect} from 'react';
import {useGraphQL} from "../../../hook/useGraphQL";
import {useQuery} from "@tanstack/react-query";
import {BACKEND_URL} from "../../../config";
import {GET_MY_CONTAINERS} from "../../../graphql/MyContainer/GET_MY_CONTAINERS";
import useUserId from "../../../hook/useUserId";
import {Link, useParams} from "react-router-dom";
import IsAdmin from "../../../hoc/IsAdmin";
import ModalEditContainer from "./ModalEditContainer";
import MapModal from "./MapModal";
import {Pagination, Swiper} from "swiper";
import CopyBtn from "./CopyBtn";



const SELECTOR = 'my-containers-swiper'


const MyContainers = () => {

    const userId = useUserId(true)
    const {cabinetId} = useParams()
    const graphQLClient = useGraphQL()


    const query = {
        user: userId
    }

    const {data: myContainers, isLoading} = useQuery({
        queryKey: ['myContainers', query],
        queryFn: () => graphQLClient.request(GET_MY_CONTAINERS, query).then(res => res?.myContainers)
    })

    const containersNumber = myContainers?.data?.length


    useEffect(() => {

        if (!containersNumber) return

        Swiper.use([Pagination])

        new Swiper(`#${SELECTOR}`, {
            "spaceBetween": 24,
            "pagination": {
                "el": ".swiper-pagination",
                "clickable": true,
                // "dynamicBullets": true
            },
            "breakpoints": {
                "576": {"slidesPerView": 2},
                "992": {"slidesPerView": 3}
            }
        })

    }, [containersNumber])


    const cabinetUrl = cabinetId ? ('/' + cabinetId) : ''


    if (!containersNumber && !isLoading) {
        return <>
            <h5 className="pt-xxl-1 pb-4 mb-2 mb-lg-3">У Вас еще нет купленых контейнеров</h5>
            <Link to={`/cabinet${cabinetUrl}/containers`} className="btn btn-success">Купить</Link>
        </>
    }


    return <>

        <h1 className="pt-xxl-1 pb-4 mb-2 mb-lg-3">Мои контейнеры</h1>

        <div id={SELECTOR} className="swiper">

            <div className="swiper-wrapper">
                {
                    myContainers?.data?.map(myContainer => {

                        const uuid = myContainer?.attributes?.uuid
                        const container = myContainer?.attributes?.container?.data
                        const status = myContainer?.attributes?.status?.data
                        const statusName = status?.attributes?.name
                        const {latitude, longitude} = myContainer?.attributes?.coordinate?.data?.attributes || {}


                        const {name, params} = container?.attributes || {}
                        const imgUrl = BACKEND_URL + container?.attributes?.img?.data?.attributes?.url

                        return (
                            <div className="swiper-slide h-auto" key={myContainer?.id}>
                                <div className="card h-100 border-0 rounded-4 pb-3">
                                    <div className="position-relative ignore-dark-mode">
                                        <div className="item-responsive item-4by3">
                                            <img
                                                className="rounded-4 rounded-bottom-0 content w-100 h-100"
                                                src={imgUrl} alt=""
                                            />
                                        </div>

                                        <div className="card-body position-absolute start-0 bottom-0 py-4">
                                            <div className="d-table badge bg-danger rounded-pill mb-2">
                                                {statusName}
                                                <IsAdmin>
                                                    <ModalEditContainer
                                                        myContainer={myContainer}
                                                    />
                                                </IsAdmin>
                                            </div>
                                            <div className="d-table badge bg-light text-nav rounded-pill fs-sm">
                                                {uuid}
                                                <IsAdmin>
                                                    <CopyBtn text={uuid} className="ms-2 a"/>
                                                </IsAdmin>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="card-body pb-4">
                                        <h3 className="h4">{name}</h3>
                                        <ul className="fs-sm ps-4 mb-0">
                                            <li className="mb-2">
                                                Длина: {params?.length}
                                            </li>
                                            <li className="mb-2">
                                                Ширина: {params?.width}
                                            </li>
                                            <li className="mb-2">
                                                Высота: {params?.height}
                                            </li>
                                            <li className="mb-2">
                                                Вместимость: {params?.capacity}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-footer border-0 pt-0">
                                        <MapModal
                                            name={uuid}
                                            latitude={latitude}
                                            longitude={longitude}
                                            maxZoom={7}
                                            button={
                                                <button className="btn btn-success w-100" type="button">
                                                    Подробнее
                                                </button>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="swiper-pagination position-relative bottom-0 mt-2 pt-4"/>

        </div>

    </>
}

export default React.memo(MyContainers);