import React from 'react';
import ReactPlayer from "react-player";


const Video = () => {


    return (
        <>
            <section className="container py-5 my-lg-3 my-xl-4 my-xxl-5" data-aos="fade-up" data-aos-duration="600"
                     data-aos-offset="280" data-disable-parallax-down="md">

                <h2 className="h1 text-center">Морские контейнерные перевозки</h2>

                <p className="text-center pb-4 mb-2 mb-lg-3">
                    Опыт работы более 60-ти лет
                </p>

                <div className="row py-2 py-sm-3 my-md-3 mb-lg-4 mb-xl-5">

                    <div className="col-md-6 pb-4 pb-md-0 mb-3 mb-md-0">

                        <div className="position-relative mt-sm-2 mt-lg-4 mb-4 mb-sm-5 mx-auto"
                             style={{maxWidth: "700px"}}>
                            <div className="item-responsive item-16by9 rounded-4 overflow-hidden">
                                <div className="content">
                                    <ReactPlayer
                                        className='react-player'
                                        url='/video.mp4'
                                        light='/video-preview.webp'
                                        width='100%'
                                        height='100%'
                                        controls={true}
                                        playing={true}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 col-xl-5 offset-xl-1" data-aos="fade-up" data-aos-duration="850"
                         data-aos-offset="180" data-disable-parallax-down="md">
                        <div className="ps-md-4 ps-xl-0">
                            <h2 className="h1 pb-3 mb-2 mb-md-3 mb-xl-4 text-center text-md-start">О компании</h2>
                            <p className="text-justify">
                                COSCOLINE была образована с интегрированным контейнерным бизнесом COSCO Container Lines
                                Co., Ltd. и China SHIPPING Container Lines CO., Ltd.. Компания начала свою деятельность
                                1 марта 2018 года на территории России. В основном компания занимается внутренними и
                                международными контейнерными перевозками и сопутствующими видами деятельности. Компания
                                является одним из основных бизнес-сегментов COSCO Shipping Group.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            {/*<section className="container pt-5">*/}

            {/*    <h1 className="text-center">Морские контейнерные перевозки</h1>*/}

            {/*    <div className="position-relative mt-sm-2 mt-lg-4 mb-4 mb-sm-5 mx-auto" style={{maxWidth: "700px"}}>*/}
            {/*        <div className="item-responsive item-16by9 rounded-5 overflow-hidden">*/}
            {/*            <div className="content">*/}
            {/*                <ReactPlayer*/}
            {/*                    className='react-player'*/}
            {/*                    url='/video.mp4'*/}
            {/*                    light='/video-preview.webp'*/}
            {/*                    width='100%'*/}
            {/*                    height='100%'*/}
            {/*                    controls={true}*/}
            {/*                    playing={true}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </>
    )
}

export default React.memo(Video);