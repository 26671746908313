import React from 'react';
import Hero from "./Hero";
import Stats from "./Stats";
import Industries from "./Industries";
import Clients from "./Clients";
import Ceo from "./Ceo";
import Video from "./Video";
import FreeDelivery from "./FreeDelivery";
import InvestmentCalc from "../Cabinet/Investments/InvestmentCalc";
import Containers from "./Containers";
import GraphLine from "../Statistics/GraphLine";

const Main = () => {


    return (
        <>

            <Hero/>

            <Stats/>

            <Video/>

            <Industries/>

            <Clients/>

            <Ceo/>

            <div className="container mb-5 mt-n5 d-flex justify-content-center">
                <InvestmentCalc/>
            </div>

            <div className="container mb-5">
                <h2 className="text-center">Контейнеры</h2>
                <Containers/>
            </div>

            <div className="container mb-5">
                <h2 className="text-center">Статистика покупок</h2>
                <GraphLine/>
            </div>

            <FreeDelivery/>

        </>
    )
}

export default React.memo(Main);