import React from 'react';
import {Link} from "react-router-dom";
import InitTooltip from "../../utilities/InitTooltip";

const HomeButton = () => {
    return <>
        <Link className="text-nav btn btn-icon bg-light border rounded-circle position-absolute top-0 end-0 p-0 mt-3 me-3 mt-sm-4 me-sm-4"
           to="/" data-bs-toggle="tooltip" data-bs-placement="left" title="На главную">
            <i className="ai-home"></i>
        </Link>
        <InitTooltip/>
    </>
}

export default React.memo(HomeButton);