import React from 'react';
import Spinner from '../../../Elements/Loaders/Spinner';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {math} from '../../../../utilities/math';


function UserCoins({userId}) {

    const balanceFilter = {user: userId}
    const {data: balance, isLoading} = useQuery({
        queryKey: ['coinTransactionSum', balanceFilter],
        queryFn: () => axios
        .post('/coin-transaction/sum', balanceFilter)
        .then(res => math.round(res?.data, 2))
    })

    if (isLoading) return <Spinner/>

    return balance
}

export default UserCoins;