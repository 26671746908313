import {useQuery} from '@tanstack/react-query';
import {useGraphQL} from './useGraphQL';
import {GET_SHOW_COIN} from '../graphql/ShowCoin/GET_SHOW_COIN';

const useShowCoin = () => {

    const graphQLClient = useGraphQL()

    const {data} = useQuery({
        queryKey: ['showCoin'],
        queryFn: () => graphQLClient.request(GET_SHOW_COIN)
    })

    const {showToAdmins, showToUsers} = data?.showCoin?.data?.attributes || {}

    return {showToAdmins, showToUsers}
}

export default useShowCoin