import React, {useEffect} from 'react';
import {Pagination, Swiper} from "swiper";



const SELECTOR = 'industry-swiper'

const Industries = () => {


    useEffect(() => {

        Swiper.use([Pagination])

        new Swiper(`#${SELECTOR}`, {
            "spaceBetween": 24,
            "pagination": {
                "el": ".swiper-pagination",
                "clickable": true
            },
            "breakpoints": {
                "576": {"slidesPerView": 2},
                "992": {"slidesPerView": 3}
            }
        })

    }, [])

    return (
        // Industries (Carousel on screens < 992px)
        <section className="container pb-5 pt-3 pt-sm-4 pt-lg-5 my-xl-3 my-xxl-5">
            <h2 className="h1 text-center">Рабочие процессы COSCOLINE</h2>
            <p className="text-center pb-4 mb-2 mb-lg-3">
                Сотрудничаем с предприятиями в следующих отраслях
            </p>
            {/* Swiper slider*/}
            <div id={SELECTOR} className="swiper">
                <div className="swiper-wrapper">
                    {/* Item*/}
                    <div className="swiper-slide h-auto">
                        <div className="card border-0 h-100">
                            <div className="card-img-top overflow-hidden">
                                <img
                                    className="rounded-5 rounded-end-0"
                                    src="/img/main/industry3.jpg"
                                    alt="Морские перевозки<"
                                />
                            </div>
                            <div className="card-body">
                                <h3 className="h4 card-title">Морские перевозки</h3>
                                <ul className="ps-4 mb-n2">
                                    <li className="mb-2">Вместимость судна до 20 тысяч тонн</li>
                                    <li className="mb-2">Максимально до 18 тысяч контейнеров</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Item*/}
                    <div className="swiper-slide h-auto">
                        <div className="card border-0 h-100">
                            <div className="card-img-top overflow-hidden">
                                <img
                                    className="rounded-5 rounded-end-0"
                                    src="/img/main/industry2.jpg"
                                    alt="Погрузка"
                                />
                            </div>
                            <div className="card-body">
                                <h3 className="h4 card-title">Погрузка</h3>
                                <ul className="ps-4 mb-n2">
                                    <li className="mb-2">Контейрерные погрузчики KALMAR DRF-450</li>
                                    <li className="mb-2">Скорость погрузки 30-35 шт/час</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Item*/}
                    <div className="swiper-slide h-auto">
                        <div className="card border-0 h-100">
                            <div className="card-img-top overflow-hidden">
                                <img
                                    className="rounded-5 rounded-end-0"
                                    src="/img/main/industry1.jpg"
                                    alt="Image"
                                />
                            </div>
                            <div className="card-body">
                                <h3 className="h4 card-title">Производство</h3>
                                <ul className="ps-4 mb-n2">
                                    <li className="mb-2">Изготовление из легированной стали</li>
                                    <li className="mb-2">Марка стали - "COR-TEN steel"</li>
                                    <li className="mb-2">Металл толщиной 2.0 мм</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Pagination (bullets)*/}
                <div className="swiper-pagination position-relative bottom-0 mt-2 pt-4 d-lg-none"/>
            </div>
        </section>
    )
}

export default React.memo(Industries);