import React, {useState} from 'react';
import ButtonGroupInvestments from "../../Input/ButtonGroupInvestments";
import {useGraphQL} from "../../../hook/useGraphQL";
import {useQuery} from "@tanstack/react-query";
import {GET_INVESTMENTS} from "../../../graphql/Investment/GET_INVESTMENTS";
import FormRange from "react-bootstrap/FormRange";
import {math} from "../../../utilities/math";
import useBootstrapBreakpoint from "../../../hook/useBootstrapBreakpoint";

const InvestmentCalc = () => {

    const graphQLClient = useGraphQL()
    const breakPoint = useBootstrapBreakpoint()

    const {data: investments} = useQuery({
        queryKey: ['investments'],
        queryFn: () => graphQLClient.request(GET_INVESTMENTS).then(res => res?.investments)
    })

    const [investment, setInvestment] = useState()
    const [days, setDays] = useState(30)


    const {
        price,
        dailyProfit,
    } = investment?.attributes || {}


    const periodProfit = math.round(days * price * dailyProfit / 100, 2)

    const buttonGroupClass = ['xs', 'sm'].includes(breakPoint) ? "btn-group-vertical" : ""

    return (

        <div className="p-4 p-sm-5 card shadow-lg" style={{maxWidth: "800px"}}>

            <h3 className="text-center">Калькулятор доходности инвестиций</h3>

            <ButtonGroupInvestments
                value={investment}
                setValue={setInvestment}
                options={investments?.data}
                className={buttonGroupClass}
            />


            <h5 className="mt-4 ms-3">Период: {days} дн.</h5>

            <FormRange
                value={days}
                onChange={e => setDays(e.target.value)}
                className="mt-1 range-ship"
                min={0}
                max={180}
                step={10}
            />




            {
                investment &&
                <h4 className="mt-3 ms-3">Прибыль: ${periodProfit}</h4>
            }

        </div>
    )
}

export default React.memo(InvestmentCalc);