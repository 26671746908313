import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import QRCode from "react-qr-code";
import Spinner from "../../Elements/Loaders/Spinner";
import SelectDepositType from "../../Input/Select/SelectDepositType";
import {math} from "../../../utilities/math";
import useUserId from "../../../hook/useUserId";
import {BACKEND_URL} from "../../../config";
import {Link} from "react-router-dom";


const ModalDeposit = () => {

    const queryClient = useQueryClient()
    const userId = useUserId(true)

    const [copied, setCopied] = useState(false)
    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [amount, setAmount] = useState('')
    // const [depositAmount, setDepositAmount] = useState()
    const [depositType, setDepositType] = useState(null)


    useEffect(() => {
        // const depositAmount = math.ceil(amount / depositType?.rate, depositType?.precision)
        // setDepositAmount(depositAmount)
        setCopied(false)
    }, [depositType])


    useEffect(() => {
        setError('')
    }, [amount, depositType])


    // const onChangeAmount = e => {
    //     const value = e.target.value
    //     setAmount(value)
    // const depositAmount = math.ceil(value / depositType?.rate, depositType?.precision)
    // setDepositAmount(depositAmount)
    // }

    // const onChangeDepositAmount = e => {
    //     const value = e.target.value
    //     const amount = math.floor(value * depositType?.rate, 2)
    //     setDepositAmount(value)
    //     setAmount(amount)
    // }


    const {mutate, isLoading, isSuccess, reset} = useMutation({
        mutationFn: variables => axios.post('transactions', variables),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['transactions']})
        }
    })


    const addTransaction = () => {

        if (!depositType) {
            setError('Выберите платежный метод')
            return
        }
        if (!amount) {
            setError('Укажите сумму пополнения')
            return
        }

        const newAmount = parseFloat(amount)

        if (isNaN(newAmount)) {
            setError('Укажите корректную сумму')
            return
        }

        if (newAmount <= 0) {
            setError('Сумма должна быть больше нуля')
            return
        }

        mutate({
            data: {
                amount: math.floor(newAmount, 2), // Float
                type: 1, // ID
                status: 3, // ID
                user: userId, // ID
                depositType: depositType?.value, // ID
                depositName: depositType?.label, // String
                depositCurrency: depositType?.currency, // String
                comment: "Пополнение баланса"
            }
        })
    }


    const handleClose = () => {
        reset()
        setAmount('')
        setError('')
        setShowModal(false)
        setCopied(false)
        setDepositType(null)
    }

    const handleShow = () => setShowModal(true)


    const requisite = depositType?.requisite

    return (

        <>

            <button
                className="btn btn-sm btn-success flex-fill fs-6"
                onClick={handleShow}
            >
                Пополнить
            </button>


            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Пополнение</Modal.Title>
                </Modal.Header>


                <Modal.Body>

                    {
                        isSuccess &&
                        <>
                            <h3 className="text-center">
                                Платеж отправлен в обработку!
                            </h3>
                            <h5 className="text-center mb-0">
                                Предоставьте чек перевода в техническую поддержку
                            </h5>
                        </>
                    }

                    {
                        !isSuccess && <>

                            <SelectDepositType
                                value={depositType}
                                onChange={setDepositType}
                            />

                            <input
                                type="tel"
                                className="form-control mt-3"
                                placeholder="Сумма, $"
                                value={amount}
                                onChange={e => setAmount(e.target.value)}
                            />


                            {
                                depositType && <>

                                    {/*<div className="d-flex align-items-center mb-2 gap-2">*/}
                                    {/*    <span className="fs-sm text-nowrap">Кол-во, {depositType?.currency}</span>*/}
                                    {/*    <input*/}
                                    {/*        type="tel"*/}
                                    {/*        className="form-control mb-2"*/}
                                    {/*        placeholder="Нужно перевести"*/}
                                    {/*        value={depositAmount || ''}*/}
                                    {/*        onChange={onChangeDepositAmount}*/}
                                    {/*    />*/}
                                    {/*</div>*/}


                                    {/*<div className="d-flex justify-content-between mb-3" title="Курс обмена может измениться">*/}
                                    {/*    <span>Курс конвертации {depositType?.currency}/$</span>*/}
                                    {/*    <span>{depositType?.rate}</span>*/}
                                    {/*</div>*/}


                                    {/*<h6 className="mt-4">*/}
                                    {/*    Переведите на адрес для пополнения {depositAmount} {depositType?.currency}*/}
                                    {/*</h6>*/}


                                    <div className="card h-100 rounded-3 p-3 p-sm-4 mt-3">
                                        <div className="d-flex align-items-center pb-2 mb-1">

                                            <h3 className="h6 text-nowrap text-truncate mb-0 me-3">Реквизиты</h3>

                                            <img src={`${BACKEND_URL}${depositType?.logoLight}`}
                                                 alt=""
                                                 className="d-dark-mode-none"
                                                 style={{height: '20px'}}
                                            />

                                            <img src={`${BACKEND_URL}${depositType?.logoDark}`}
                                                 alt=""
                                                 className="d-none d-dark-mode-inline"
                                                 style={{height: '20px'}}
                                            />

                                            <span className="badge bg-faded-primary text-primary fs-xs ms-3">
                                                {depositType?.currency}
                                            </span>

                                            {/*<div className="d-flex ms-auto">*/}
                                            {/*    <CopyToClipboard text={requisite} onCopy={() => setCopied(true)}>*/}
                                            {/*        <button*/}
                                            {/*            className="nav-link fs-xl fw-normal py-1 pe-0 ps-1 ms-2"*/}
                                            {/*            type="button"*/}
                                            {/*        >*/}
                                            {/*            {*/}
                                            {/*                copied ?*/}
                                            {/*                    <i className="ai-checks"/> :*/}
                                            {/*                    <i className="ai-copy"/>*/}
                                            {/*            }*/}
                                            {/*        </button>*/}
                                            {/*    </CopyToClipboard>*/}
                                            {/*</div>*/}
                                        </div>


                                        <CopyToClipboard text={requisite} onCopy={() => setCopied(true)}>
                                            <div className="d-flex align-items-center">


                                                <button
                                                    className="nav-link fs-3 fw-normal py-1 pe-0 ps-1"
                                                    type="button"
                                                >
                                                    {
                                                        copied ?
                                                            <i className="ai-checks"/> :
                                                            <i className="ai-copy"/>
                                                    }
                                                </button>

                                                <div className="ps-3 fs-sm a">
                                                    <span className="text-dark fs-6 fw-bold" style={{overflowWrap: 'anywhere'}}>
                                                        {requisite}
                                                    </span>
                                                    {/*<div className="text-muted">{depositType?.label}</div>*/}
                                                </div>





                                            </div>

                                        </CopyToClipboard>
                                    </div>

                                    {
                                        depositType?.qrcode &&
                                        <div className="mt-3 text-center">
                                            <h6>или сканируйте QR код</h6>
                                            <QRCode value={requisite} size={150}/>
                                        </div>
                                    }

                                </>
                            }

                            {
                                error &&
                                <div className="alert d-flex alert-warning mt-3 mb-0" role="alert">
                                    <i className="ai-triangle-alert fs-xl pe-1 me-2"/>
                                    <div>{error}</div>
                                </div>
                            }
                        </>

                    }


                </Modal.Body>


                <Modal.Footer>
                    {
                        !isSuccess ?
                            <Button variant="primary" className="w-100" onClick={addTransaction}>
                                {isLoading && <Spinner/>}
                                Пополнить
                            </Button> :
                            <Link to="/contacts" className="btn btn-primary w-100">Тех. поддержка</Link>
                            // <Button variant="primary" className="w-100" onClick={handleClose}>
                            //     Тех. поддержка
                            // </Button>
                    }
                </Modal.Footer>

            </Modal>
        </>


    )
}


export default React.memo(ModalDeposit)
