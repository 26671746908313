import React, {useState} from 'react';
import {useAuth} from "../../../hook/useAuth";
import ProfileEdit from "./ProfileEdit";
import ProfileView from "./ProfileView";
import {useParams} from "react-router-dom";
import useVirtualUser from "../../../hook/useVirtualUser";
import IsAdmin from "../../../hoc/IsAdmin";



const Profile = () => {

    const {user: userAuth} = useAuth()
    const {user: userVirtual} = useVirtualUser()
    const {cabinetId} = useParams()

    const [editMode, setEditMode] = useState(false)

    const user = cabinetId ? userVirtual : userAuth



    return <>

        {
            editMode ?
                <ProfileEdit
                    setEditMode={setEditMode}
                /> :
                <ProfileView
                    setEditMode={setEditMode}
                />
        }

        {
            user?.blocked &&
            <IsAdmin>
                <div className="alert alert-danger d-flex mb-0 mt-4" role="alert">
                    <i className="ai-circle-info fs-xl"/>
                    <div className="ps-2">
                        Клиент заблокирован администратором
                    </div>
                </div>
            </IsAdmin>
        }

        {
            user?.adminMessage &&
            <div className="alert alert-info d-flex mb-0 mt-4" role="alert">
                <i className="ai-circle-info fs-xl"/>
                <div className="ps-2">
                    {user?.adminMessage}
                </div>
            </div>
        }

    </>

}

export default React.memo(Profile);