import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import Spinner from "../../../Elements/Loaders/Spinner";
import SelectDepositType from "../../../Input/Select/SelectDepositType";
import SelectTransactionType from "../../../Input/Select/SelectTransactionType";
import SelectTransactionStatus from "../../../Input/Select/SelectTransactionStatus";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru"
import {math} from "../../../../utilities/math";


const ModalEditTransaction = ({transaction}) => {

    const queryClient = useQueryClient()

    const [showModal, setShowModal] = useState(false)
    const [error, setError] = useState('')

    const [type, setType] = useState(transaction?.attributes?.type?.data?.id)
    const [depositType, setDepositType] = useState(transaction?.attributes?.depositType?.data?.id)
    // const [withdrawalType, setWithdrawalType] = useState(transaction?.attributes?.withdrawalType?.data?.id)
    const [status, setStatus] = useState(transaction?.attributes?.status?.data?.id)
    const [amount, setAmount] = useState(Math.abs(transaction?.attributes?.amount))
    const [datetime, setDatetime] = useState(transaction?.attributes?.datetime)
    const [comment, setComment] = useState(transaction?.attributes?.comment)
    const [withdrawalRequisite, setWithdrawalRequisite] = useState(transaction?.attributes?.withdrawalRequisite)


    useEffect(() => {
        setError('')
    }, [type, depositType/*, withdrawalType*/, status, amount, datetime, comment])




    const {mutate, isLoading} = useMutation({
        mutationFn: variables => axios.put(`transactions/${transaction?.id}`, variables),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['transactions']})
            queryClient.invalidateQueries({queryKey: ['transactionSum']})
        }
    })


    const {mutateAsync: remove} = useMutation({
        mutationFn: () => axios.delete(`transactions/${transaction?.id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['transactions']})
            queryClient.invalidateQueries({queryKey: ['transactionSum']})
        }
    })


    const delTransaction = () => {
        if (window.confirm('Подтвердите удаление транзакции')) {
            remove().then(handleClose)
        }
    }


    const editTransaction = () => {

        if (!type) {
            setError('Выберите тип транзакции')
            return
        }

        if (['1'].includes(type?.value) && !depositType) {
            setError('Выберите платежный метод')
            return
        }

        // if (['2'].includes(type?.value) && !withdrawalType) {
        //     setError('Выберите платежный метод')
        //     return
        // }

        if (!status) {
            setError('Выберите статус транзакции')
            return
        }

        if (!amount) {
            setError('Укажите сумму')
            return
        }

        const parsedAmount = parseFloat(amount)


        if (isNaN(parsedAmount)) {
            setError('Укажите корректную сумму')
            return
        }

        if (parsedAmount <= 0) {
            setError('Сумма должна быть больше нуля')
            return
        }


        const amountSign = type?.sign || 1

        const data = {
            type: type?.value,
            status: status?.value,
            // withdrawalType: withdrawalType?.value,
            comment,
            amount: math.floor(parsedAmount * amountSign, 2),
            datetime,
            depositType: depositType?.value,
            depositName: depositType?.label,
            depositCurrency: depositType?.currency,
            withdrawalRequisite,
            // withdrawalName: withdrawalType?.label,
            // withdrawalCurrency: withdrawalType?.currency
        }


        mutate({data}, {
            onSuccess: (data) => {
                handleClose()
            }
        })

    }


    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true)


    return (

        <>
            <i className="ai-edit a fs-4" onClick={handleShow}/>

            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Править транзакцию</Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex flex-column gap-3">


                    <SelectTransactionType
                        // className="mb-3"
                        value={type}
                        onChange={setType}
                    />

                    {
                        ['1'].includes(type?.value) &&
                        <SelectDepositType
                            // className="mb-3"
                            value={depositType}
                            onChange={setDepositType}
                        />
                    }


                    {/*{*/}
                    {/*    ['2'].includes(type?.value) &&*/}
                    {/*    <SelectWithdrawalType*/}
                    {/*        className="mb-3"*/}
                    {/*        value={withdrawalType}*/}
                    {/*        onChange={setWithdrawalType}*/}
                    {/*    />*/}
                    {/*}*/}


                    {
                        ['2'].includes(type?.value) &&
                        <textarea
                            className="form-control"
                            placeholder="Реквизиты"
                            rows={3}
                            value={withdrawalRequisite || ''}
                            onChange={e => setWithdrawalRequisite(e.target.value)}
                        />
                    }



                    <SelectTransactionStatus
                        // className="mb-3"
                        value={status}
                        onChange={setStatus}
                    />


                    <input
                        type="tel"
                        className="form-control"
                        placeholder="Сумма, $"
                        value={amount || ''}
                        onChange={e => setAmount(e.target.value)}
                    />


                    <Flatpickr
                        options={{
                            locale: Russian,
                            disableMobile: true,
                            enableTime: true,
                        }}
                        placeholder="Дата и время транзакции"
                        className="form-control date-picker"
                        value={datetime}
                        onChange={(dObj) => {
                            setDatetime(dObj[0])
                        }}
                    />




                    <textarea
                        className="form-control"
                        placeholder="Комментарий"
                        rows={3}
                        value={comment || ''}
                        onChange={e => setComment(e.target.value)}
                    />



                    {
                        error &&
                        <div className="alert d-flex alert-warning mb-0" role="alert">
                            <i className="ai-triangle-alert fs-xl pe-1 me-2"/>
                            <div>{error}</div>
                        </div>
                    }


                </Modal.Body>


                <Modal.Footer>

                    <Button variant="outline-dark" className="me-auto" onClick={delTransaction}>
                        <i className="ai-trash"/>
                    </Button>

                    <Button variant="primary" onClick={editTransaction}>
                        {isLoading && <Spinner/>}
                        Сохранить
                    </Button>

                </Modal.Footer>

            </Modal>
        </>


    )
}


export default React.memo(ModalEditTransaction)
