import React, {useEffect} from 'react';
import {Tooltip} from "bootstrap";

const InitTooltip = () => {
    useEffect(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        let tooltipList = tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl, {trigger: 'hover'}))
        return () => tooltipList.forEach(tooltip => tooltip.dispose())
    }, [])
}

export default React.memo(InitTooltip);