import React, {useCallback} from "react";


function Pagination({page, pageCount, setPage}) {

    // useQuery (graphql) | useAxios (axios)
    // page === currentPage
    // pageCount === lastPage
    // pageSize === perPage
    // total === total

    const setPageManually = useCallback(() => {
        const answer = prompt('На какую страницу вы хотите перейти')

        if (!answer) return;

        const neededPage = +answer

        if (isNaN(neededPage) || neededPage > pageCount || neededPage === 0) {
            alert('Невозможно')
            return
        }
        setPage(neededPage)

    }, [pageCount, setPage])


    if (pageCount > 1) {} else return null


    const displayed = n => n === 1 || n === pageCount || n === page || n === page - 1 || n === page + 1


    let items = []

    for (let n = 1; n <= pageCount; n++) {

        if (displayed(n)) {
            items.push(
                <li key={n}
                    className={`a page-item${n === page ? ' active' : ''}`}
                    onClick={() => setPage(n)}
                    aria-current="page"
                >
                    <span className="page-link">
                        {n}
                    </span>
                </li>
            )
        } else if (displayed(n - 1)) {
            items.push(
                <li key={n} className="a page-item" onClick={setPageManually}>
                    <span className="page-link">
                        ...
                    </span>
                </li>
            )
        }
    }


    return <>
        <div className="d-sm-flex align-items-center pt-4 pt-sm-5">
            <nav className="order-sm-2 ms-sm-auto mb-4 mb-sm-0" aria-label="Orders pagination">
                <ul className="pagination pagination-sm justify-content-center">
                    {items}
                </ul>
            </nav>
        </div>
    </>


}

export default React.memo(Pagination)
